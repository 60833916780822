import { RouteComponentProps } from '@reach/router';
import { TFunction } from 'i18next';
import { forEach, keyBy, some } from 'lodash';
import * as React from 'react';
import { useContext, useMemo, useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps, Row } from 'react-table';
import { ButtonGroup } from '../../shared/buttons/Button';
import { DownloadExcelFromCsvResponseButton } from '../../shared/buttons/DownloadExcelFromCsvResponseButton';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { DataLoader, DataLoaderWithParams } from '../../shared/DataLoader';
import { MultiSelectDropdown } from '../../shared/dropdowns/MultiSelectDropdown';
import { ExternalLink } from '../../shared/navigation/Link';
import { IfUserHasOneOfPermissions } from '../../shared/Permissions';
import { DataFetchTable } from '../../shared/tables/Table';
import { CustomColumn } from '../../shared/tables/Table.types';
import { TupleKeyDictionary } from '../../utils/TupleKeyDictionary';
import { LoginUserResponse } from '../authentication/loginData/user';
import { UserContext } from '../authentication/loginData/userContext';
import { permissions } from '../authentication/permissions';
import { getItemFieldsFromPms } from '../items/itemsApi';
import { SpaceBetweenButtonGroup } from '../items/ItemsTables/ItemsBase';
import { viewItemUrl } from '../items/itemUrls';
import { PageHeading } from '../layout/PageHeading';
import { PracticeGroupResponse } from '../sites/practiceGroups/practiceGroup';
import { getAllPracticeGroups } from '../sites/practiceGroups/practiceGroupsApi';
import { editCreateDeviationBulkEditPermissions } from './bulkEdit/deviationBulkEdit';
import {
  deviationExpandedColumn,
  GroupedDeviationResponseRow,
  groupedPracticeGroupOrSiteNameColumn,
} from './bulkEdit/SharedDeviationBulkEditColumns';
import {
  DeviationColumnTypes,
  DeviationResponse,
  DeviationsResponse,
  validDeviationColumnTypeOrUndefined,
} from './deviation';
import {
  centralItemDispenseFeeColumn,
  centralItemPriceColumn,
  deviatedDispenseFeeColumn,
  deviatedPriceColumn,
  deviationWarningsColumn,
  dispenseFeeDeviationPercentValueColumn,
  dispenseFeeDeviationsAbsoluteValueGrossColumn,
  dispenseFeeDeviationsAbsoluteValueNetColumn,
  dispenseFeeDeviationsCodeSpecificColumn,
  isHiddenColumn,
  itemCodeColumn,
  itemNameColumn,
  priceDeviationPercentValueColumn,
  priceDeviationsAbsoluteValueGrossColumn,
  priceDeviationsAbsoluteValueNetColumn,
  priceDeviationsCodeSpecificColumn,
  supplierNameColumn,
  supplierProductCodeColumn,
} from './DeviationColumns';
import { getDeviationFilterDropdownOptions, getDeviationsDownload } from './deviationsApi';
import {
  HelpText,
  DeviationColumnDropdownType,
  DeviationsBaseProps,
  DeviationsScheduledForUpdateInfoBar,
  getSubRows,
  initialDeviationFilterOptions,
  renderDeviationToggleButton,
  useDeviationTableBaseState,
  deviationPageSizeStorage,
  deviationsPageSize,
} from './DeviationsBase';
import { DeviationsFilterForm } from './DeviationsFilterForm';
import { createDeviationBulkEditUrl, uploadDeviationBulkEditUrl } from './deviationsUrls';
import { SortResponse } from '../../shared/tables/sorting/sorting';
import { getUniqueValueOrDefault } from '../../utils/arrayUtils';
import { MetadataContext } from '../authentication/loginData/metadataContext';
import { CountryResponse } from '../authentication/loginData/metadata';
import { PageSize } from '../../shared/tables/pagination/pageSize';
import { appendDateTimeToString } from '../../utils/stringUtils';
import { mapDeviationsResponseToTableRowGroups } from './deviationUtils';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const Deviations = (props: Props) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoader apiRequest={getDeviationFilterDropdownOptions}>
      {deviationFilterDropdownOptions => (
        <DataLoader apiRequest={getItemFieldsFromPms}>
          {itemFields => (
            <DataLoaderWithParams
              apiRequest={getAllPracticeGroups}
              getParams={() => user.organisationGroupId}
            >
              {practiceGroupsRequest => (
                <DeviationsTableComponent
                  {...props}
                  deviationFilterDropdownOptions={deviationFilterDropdownOptions.response}
                  itemFieldsFromPms={itemFields.response}
                  practiceGroups={practiceGroupsRequest.response.practiceGroups}
                />
              )}
            </DataLoaderWithParams>
          )}
        </DataLoader>
      )}
    </DataLoader>
  );
};

type DeviationsTableProps = DeviationsBaseProps & RouteComponentProps;

const DeviationsTableComponent = (props: DeviationsTableProps) => {
  const { t } = useTranslation('deviations');
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const [
    scheduledBulkEditIdBySiteIdThenItemId,
    setScheduledBulkEditIdBySiteIdThenItemId,
  ] = useState<TupleKeyDictionary<number, number, number> | null>(null);
  const [sortResponse, setSortResponse] = useState<SortResponse<DeviationColumnTypes>>({
    sortBy: 'itemName',
    direction: 'asc',
  });
  const storedPageSize = localStorage.getItem(deviationPageSizeStorage);
  const initalPageSize = storedPageSize ? +storedPageSize : deviationsPageSize;
  const [pageValue, setPageValue] = useState<number>(initalPageSize);
  const {
    columnDropdownOptions,
    onOptionSelect,
    selectedOptions,
    getFilteredData,
    onFilteredResponse,
    filterOptions,
    setFilterOptions,
    deselectedValues,
  } = useDeviationTableBaseState(props);

  const handlePageSizeChange = (selectedPageSize: number) => {
    setPageValue(selectedPageSize);
    localStorage.setItem(deviationPageSizeStorage, selectedPageSize.toString());
  };

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedDeviationResponseRow,
    DeviationColumnTypes
  >> = useMemo(
    () => getColumns(t, user, scheduledBulkEditIdBySiteIdThenItemId, practiceGroupsById, countries),
    [user, t, scheduledBulkEditIdBySiteIdThenItemId, practiceGroupsById],
  );

  const hiddenColumns: Array<DeviationColumnTypes> = [
    ...deselectedValues,
    user.useGrossPrices ? 'priceDeviationAbsoluteValueNet' : 'priceDeviationAbsoluteValueGross',
    user.useGrossPrices
      ? 'dispenseFeeDeviationAbsoluteValueNet'
      : 'dispenseFeeDeviationAbsoluteValueGross',
    'priceDeviationAbsoluteValueNet',
    'priceDeviationAbsoluteValueGross',
    'dispenseFeeDeviationAbsoluteValueNet',
    'dispenseFeeDeviationAbsoluteValueGross',
  ];

  const onDataFetchSuccess = (response: DeviationsResponse) => {
    onFilteredResponse(response);

    const { deviationResponsesAndScheduledBulkEditId, sortBy, direction } = response;
    setSortResponse({ sortBy, direction });

    const scheduledBulkEditIds = new TupleKeyDictionary<number, number, number>();
    forEach(
      deviationResponsesAndScheduledBulkEditId.scheduledBulkEditIdWithSiteAndItemIds,
      schedule =>
        scheduledBulkEditIds.add(
          schedule.siteId,
          schedule.itemId,
          schedule.scheduledDeviationBulkEditId,
        ),
    );

    setScheduledBulkEditIdBySiteIdThenItemId(scheduledBulkEditIds);
  };

  return (
    <>
      <Title>{t('deviationsList.title')}</Title>
      <PageHeading>
        <h1>{t('deviationsList.heading')}</h1>
        <ButtonGroup>
          <DownloadExcelFromCsvResponseButton
            request={getDeviationsDownload(
              filterOptions ? filterOptions : initialDeviationFilterOptions,
            )}
            requestParams={sortResponse}
            labelText={t('deviationsList.downloadButton')}
            fileName={appendDateTimeToString(t('deviationsList.downloadFileName'))}
            sheetName={t('deviationsList.downloadSheetName')}
          />
          <IfUserHasOneOfPermissions
            permissions={[permissions.SuperAdmin, permissions.DeviationBulkUploadAdmin]}
          >
            <HollowLinkButton to={uploadDeviationBulkEditUrl()}>
              {t('deviationsList.uploadButton')}
            </HollowLinkButton>
          </IfUserHasOneOfPermissions>
        </ButtonGroup>
      </PageHeading>
      {scheduledBulkEditIdBySiteIdThenItemId &&
        some(scheduledBulkEditIdBySiteIdThenItemId.dictionary) && (
          <DeviationsScheduledForUpdateInfoBar message={t('deviationsList.scheduledBulkEdit')} />
        )}
      <DeviationsFilterForm
        existingFilters={filterOptions}
        onApply={setFilterOptions}
        deviationFilterDropdownOptions={props.deviationFilterDropdownOptions}
        itemFieldsFromPms={props.itemFieldsFromPms}
      />
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<DeviationColumnDropdownType>
          label={t('deviationsList.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderDeviationToggleButton}
        />
        <IfUserHasOneOfPermissions permissions={editCreateDeviationBulkEditPermissions.page}>
          <HollowLinkButton to={createDeviationBulkEditUrl(window.location.search)}>
            {t('deviationsList.createBulkEdit')}
          </HollowLinkButton>
        </IfUserHasOneOfPermissions>
      </SpaceBetweenButtonGroup>
      <HelpText text={t('deviationsList.calculatedValuesHelpText')} />
      <PageSize onChange={handlePageSizeChange} initalValue={pageValue} />
      <DataFetchTable
        columns={columns}
        pageSize={pageValue}
        dynamicPageSize={true}
        getApiData={getFilteredData}
        validColumnTypeOrUndefined={validDeviationColumnTypeOrUndefined}
        mapResponseToTableData={mapDeviationsResponseToTableRowGroups}
        getSubRows={getSubRows}
        onDataFetchSuccess={onDataFetchSuccess}
        emptyTableMessage={t('deviationsList.noDeviations')}
        hiddenColumnNames={hiddenColumns}
      />
    </>
  );
};

const getColumns = (
  t: TFunction,
  user: LoginUserResponse,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<number, number, number> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
  countries: Array<CountryResponse>,
): Array<CustomColumn<GroupedDeviationResponseRow, DeviationColumnTypes>> => [
  deviationExpandedColumn(),
  {
    ...groupedPracticeGroupOrSiteNameColumn(
      t,
      scheduledBulkEditIdBySiteIdThenItemId,
      practiceGroupsById,
    ),
    disableSortBy: false,
  },
  {
    ...itemNameColumn(t),
    Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => (
      <span>
        <ExternalLink to={viewItemUrl(row.original.itemId)}>
          {row.canExpand ? getGroupedCellValue(row, 'itemName', undefined) : cell.value}
        </ExternalLink>
      </span>
    ),
    disableSortBy: false,
  },
  {
    ...itemCodeColumn(t),
    disableSortBy: false,
  },
  isHiddenColumn(t),
  centralItemPriceColumn(t, user, countries),
  deviatedPriceColumn(t, user, countries),
  priceDeviationPercentValueColumn(t, user),
  priceDeviationsAbsoluteValueNetColumn(t, user, countries),
  priceDeviationsAbsoluteValueGrossColumn(t, user, countries),
  { ...priceDeviationsCodeSpecificColumn(t, user), disableSortBy: false },
  { ...centralItemDispenseFeeColumn(t, user, countries), disableSortBy: false },
  deviatedDispenseFeeColumn(t, user, countries),
  dispenseFeeDeviationPercentValueColumn(t, user),
  dispenseFeeDeviationsAbsoluteValueNetColumn(t, user, countries),
  dispenseFeeDeviationsAbsoluteValueGrossColumn(t, user, countries),
  { ...dispenseFeeDeviationsCodeSpecificColumn(t, user), disableSortBy: false },

  supplierProductCodeColumn(t),
  supplierNameColumn(t),
  deviationWarningsColumn(t, user),
];

const getGroupedCellValue = <T extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  fieldName: keyof DeviationResponse,
  defaultValue: T | undefined,
): T | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(deviations, deviation => deviation[fieldName] as T, defaultValue) ??
    defaultValue
  );
};
