import { RouteComponentProps } from '@reach/router';
import { flatMap, forEach, keyBy, map, mapValues, some } from 'lodash';
import { default as React, useCallback, useContext, useEffect, useMemo } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { AlertButton } from '../../../shared/buttons/Button';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoaderWithParams } from '../../../shared/DataLoader';
import { MultiSelectDropdown } from '../../../shared/dropdowns/MultiSelectDropdown';
import { useMultiSelectDropdown } from '../../../shared/dropdowns/useMultiSelectDropdown';
import { ApiErrorBox } from '../../../shared/errors/ApiErrorBox';
import { FieldAndValue } from '../../../shared/FieldAndValue';
import { DropdownOptions } from '../../../shared/forms/DropdownField';
import { InputField } from '../../../shared/forms/InputField';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { useNavigateAwayWarning } from '../../../shared/hooks/useNavigateAwayWarning';
import { LoadingIndicator } from '../../../shared/LoadingIndicator';
import { IfUserHasOneOfPermissions, RequiresOneOfPermissions } from '../../../shared/Permissions';
import { FormikBasicTable } from '../../../shared/tables/FormikTable';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { dashboardUrl } from '../../../urls';
import { assertIsDefined } from '../../../utils/assertIsDefined';
import { formatNumberForFields, parseNumber } from '../../../utils/numberUtils';
import { navigate } from '../../../utils/routing';
import { UserContext } from '../../authentication/loginData/userContext';
import { SpaceBetweenButtonGroup } from '../../items/ItemsTables/ItemsBase';
import { PageHeading } from '../../layout/PageHeading';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { getAllPracticeGroups } from '../../sites/practiceGroups/practiceGroupsApi';
import { DeviationColumnTypes } from '../deviation';
import {
  cancelDeviationBulkEdit,
  editDeviationBulkEdit,
  getDeviationBulkEdit,
} from '../deviationsApi';
import {
  DeviationColumnDropdownType,
  deviationsPageSize,
  renderDeviationToggleButton,
} from '../DeviationsBase';
import { viewDeviationBulkEditUrl } from '../deviationsUrls';
import {
  DeviationBulkEditCommandLine,
  DeviationBulkEditLineValidator,
  DeviationBulkEditResponse,
  editCreateDeviationBulkEditPermissions,
  EditDeviationBulkEditCommand,
  EditDeviationBulkEditFormModel,
  EditDeviationBulkEditValidator,
} from './deviationBulkEdit';
import { getEditDeviationBulkEditColumns } from './EditDeviationBulkEditColumns';
import {
  getSubRows,
  GroupedDeviationBulkEditRow,
  mapDeviationBulkEditResponseToGroupedTableData,
} from './SharedDeviationBulkEditColumns';
import {
  getGrossDeviatedDispenseFeeTo,
  getGrossDeviatedPriceTo,
  getNetDeviatedDispenseFeeTo,
  getNetDeviatedPriceTo,
} from './ViewEditDeviationBulkEditShared';
import { MetadataContext } from '../../authentication/loginData/metadataContext';

type OwnProps = RouteComponentProps<{ deviationBulkEditId: string }> & {};
type Props = OwnProps;

export const EditDeviationBulkEdit = (props: Props) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoaderWithParams
      apiRequest={getAllPracticeGroups}
      getParams={() => user.organisationGroupId}
    >
      {practiceGroupsRequest => (
        <EditDeviationBulkEditComponent
          {...props}
          practiceGroups={practiceGroupsRequest.response.practiceGroups}
        />
      )}
    </DataLoaderWithParams>
  );
};

type EditDeviationBulkEditProps = OwnProps & { practiceGroups: Array<PracticeGroupResponse> };

const EditDeviationBulkEditComponent = (props: EditDeviationBulkEditProps) => {
  const { t } = useTranslation(['deviations', 'navigateAwayMessages']);
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);

  const {
    makeRequest: makeCancelRequest,
    apiError: cancelApiError,
    inProgress: cancelInProgress,
    clearError: nullCancelError,
  } = useApiRequest(cancelDeviationBulkEdit);

  const {
    makeRequest,
    inProgress,
    response: deviationBulkEdit,
    apiError,
    clearError,
  } = useApiRequest(getDeviationBulkEdit(Number(props.deviationBulkEditId)));

  useEffect(() => {
    makeRequest({ pageNumber: 1, pageSize: deviationsPageSize }).then();
  }, []);

  useNavigateAwayWarning({
    shouldConfirmNavigation: true,
    message: t('navigateAwayMessages:bulkEditForm'),
  });

  const columnDropdownOptions: DropdownOptions<DeviationColumnTypes> = useMemo(
    () => [{ displayText: t('columnHeaders.itemCode'), value: 'itemCode' }],
    [user],
  );

  const { onOptionSelect, selectedOptions, deselectedValues } = useMultiSelectDropdown<
    DeviationColumnDropdownType,
    DeviationColumnTypes
  >({
    validOptions: columnDropdownOptions,
    initiallySelected: new Array<DeviationColumnDropdownType>(),
    storeInUrl: true,
  });

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedDeviationBulkEditRow,
    DeviationColumnTypes
  >> = useMemo(() => getEditDeviationBulkEditColumns(t, user, practiceGroupsById, countries), [
    t,
    user,
    practiceGroupsById,
  ]);

  const tableData = useMemo(
    () =>
      deviationBulkEdit ? mapDeviationBulkEditResponseToGroupedTableData(deviationBulkEdit) : null,
    [deviationBulkEdit],
  );

  const initialFormModel = useCallback(
    (
      deviationBulkEditResponse: DeviationBulkEditResponse | null,
    ): EditDeviationBulkEditFormModel => {
      if (deviationBulkEditResponse === null) {
        return {
          name: null,
          deviations: {},
        };
      }

      const formModel: EditDeviationBulkEditFormModel = {
        name: deviationBulkEditResponse.name,
        deviations: {},
      };

      forEach(deviationBulkEditResponse.deviationBulkEditLines, deviation => {
        if (formModel.deviations[deviation.siteId] === undefined) {
          formModel.deviations[deviation.siteId] = {};
        }

        formModel.deviations[deviation.siteId][deviation.itemId] = {
          treatmentTypeDeviationId: deviation.treatmentTypeDeviationId,
          itemType: deviation.itemType,
          deviationId: deviation.deviationId,
          isHidden: deviation.isHiddenTo,
          absolutePriceDeviation: deviation.priceDeviationIsSetByPercentageTo
            ? null
            : formatNumberForFields(
                user.useGrossPrices
                  ? deviation.absolutePriceDeviationGrossTo
                  : deviation.absolutePriceDeviationNetTo,
                user.locale,
              ),
          percentagePriceDeviation:
            deviation.priceDeviationIsSetByPercentageTo &&
            deviation.percentagePriceDeviationTo !== null
              ? formatNumberForFields(deviation.percentagePriceDeviationTo, user.locale)
              : null,
          deviatedPrice: formatNumberForFields(
            user.useGrossPrices
              ? getGrossDeviatedPriceTo(deviation)
              : getNetDeviatedPriceTo(deviation),
            user.locale,
          ),
          absoluteDispenseFeeDeviation: deviation.dispenseFeeDeviationIsSetByPercentageTo
            ? null
            : formatNumberForFields(
                user.useGrossPrices
                  ? deviation.absoluteDispenseFeeDeviationGrossTo
                  : deviation.absoluteDispenseFeeDeviationNetTo,
                user.locale,
              ),
          percentageDispenseFeeDeviation:
            deviation.dispenseFeeDeviationIsSetByPercentageTo &&
            deviation.percentageDispenseFeeDeviationTo !== null
              ? formatNumberForFields(deviation.percentageDispenseFeeDeviationTo, user.locale)
              : null,
          deviatedDispenseFee: formatNumberForFields(
            user.useGrossPrices
              ? getGrossDeviatedDispenseFeeTo(deviation)
              : getNetDeviatedDispenseFeeTo(deviation),
            user.locale,
          ),
          itemDeviationPriceOverride: deviation.itemDeviationPriceOverride,
          itemDeviationDispenseFeeOverride: deviation.itemDeviationDispenseFeeOverride,
          overwrite: deviation.overwrite,
        };
      });

      return formModel;
    },
    [user],
  );

  const mapDeviationFormModelToBulkEditRequest = (
    values: EditDeviationBulkEditFormModel,
  ): EditDeviationBulkEditCommand => {
    const bulkEdit = assertIsDefined(deviationBulkEdit);

    return {
      deviationBulkEditId: bulkEdit.deviationBulkEditId,
      name: values.name || null,
      deviations: flatMap(values.deviations, (siteItems, siteId) =>
        map(
          siteItems,
          (value, itemId): DeviationBulkEditCommandLine => ({
            treatmentTypeDeviationId: value.treatmentTypeDeviationId,
            itemType: value.itemType,
            deviationId: value.deviationId,
            itemId: Number(itemId),
            siteId: Number(siteId),
            isHidden: value.isHidden,
            absolutePriceDeviation:
              value.absolutePriceDeviation !== null
                ? parseNumber(value.absolutePriceDeviation, user.locale)
                : null,
            percentagePriceDeviation:
              value.percentagePriceDeviation !== null
                ? parseNumber(value.percentagePriceDeviation, user.locale)
                : null,
            priceDeviationIsSetByPercentage: value.percentagePriceDeviation !== null,

            absoluteDispenseFeeDeviation:
              value.absoluteDispenseFeeDeviation !== null
                ? parseNumber(value.absoluteDispenseFeeDeviation, user.locale)
                : null,
            percentageDispenseFeeDeviation:
              value.percentageDispenseFeeDeviation !== null
                ? parseNumber(value.percentageDispenseFeeDeviation, user.locale)
                : null,
            dispenseFeeDeviationIsSetByPercentage: value.percentageDispenseFeeDeviation !== null,
            itemDeviationPriceOverride: value.itemDeviationPriceOverride,
            itemDeviationDispenseFeeOverride: value.itemDeviationDispenseFeeOverride,
            overwrite: value.overwrite,
          }),
        ),
      ),
    };
  };

  const validateBulkEditTable = (values: EditDeviationBulkEditFormModel) => {
    const validator = new EditDeviationBulkEditValidator(t, user.locale);

    const topLevelErrors = validator.validate(values);
    const perDeviationValidator = new DeviationBulkEditLineValidator(t, user.locale);
    const deviationsErrors = mapValues(values.deviations, items =>
      mapValues(items, value => perDeviationValidator.validate(value)),
    );

    if (
      some(deviationsErrors, errorsBySite => some(errorsBySite, rowErrors => some(rowErrors))) ||
      some(topLevelErrors)
    ) {
      return {
        ...topLevelErrors,
        deviations: deviationsErrors,
      };
    }

    return {};
  };

  const onSubmitComplete = () => {
    nullCancelError();

    navigate(viewDeviationBulkEditUrl(Number(props.deviationBulkEditId)), {
      state: { success: true },
      suppressUserConfirmation: true,
    });
  };

  const onCancelButtonClick = () => {
    const bulkEdit = assertIsDefined(deviationBulkEdit);
    clearError();

    if (window.confirm(t('editDeviationBulkEdit.confirmCancelBulkEdit'))) {
      makeCancelRequest(bulkEdit.deviationBulkEditId).then(result => {
        if (result) {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { cancelledDeviationBulkEdit: true },
          });
        }
      });
    }
  };

  if (inProgress) {
    return <LoadingIndicator />;
  }

  return (
    <RequiresOneOfPermissions permissions={editCreateDeviationBulkEditPermissions.page}>
      <Title>{t('editDeviationBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('editDeviationBulkEdit.heading')}</h1>
        <IfUserHasOneOfPermissions
          permissions={editCreateDeviationBulkEditPermissions.deviatePrices}
        >
          <AlertButton onClick={onCancelButtonClick} loading={cancelInProgress}>
            {t('editDeviationBulkEdit.cancel')}
          </AlertButton>
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {deviationBulkEdit && (
        <FieldAndValue
          fieldName={t('editDeviationBulkEdit.scheduledDate')}
          value={deviationBulkEdit.scheduledDate}
          type={'date'}
        />
      )}
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<DeviationColumnDropdownType>
          label={t('editDeviationBulkEdit.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderDeviationToggleButton}
        />
        <HollowLinkButton to={viewDeviationBulkEditUrl(Number(props.deviationBulkEditId))}>
          {t('editDeviationBulkEdit.viewBulkEditButton')}
        </HollowLinkButton>
      </SpaceBetweenButtonGroup>
      {tableData && (
        <FormikBasicTable
          disabled={inProgress || cancelInProgress}
          data={tableData}
          validate={validateBulkEditTable}
          enableReinitialize={true}
          submitRequest={editDeviationBulkEdit}
          mapValuesToRequestParameters={mapDeviationFormModelToBulkEditRequest}
          columns={columns}
          hiddenColumnNames={[
            ...deselectedValues,
            'priceDeviationAbsoluteValueNet',
            'priceDeviationAbsoluteValueGross',
            'dispenseFeeDeviationAbsoluteValueNet',
            'dispenseFeeDeviationAbsoluteValueGross',
          ]}
          getSubRows={getSubRows}
          initialFormValues={initialFormModel(deviationBulkEdit)}
          additionalFormFields={() => (
            <InputField
              name="name"
              label={t('editDeviationBulkEdit.bulkEditName')}
              useDebouncing={true}
            />
          )}
          onSubmitComplete={onSubmitComplete}
        />
      )}
      {apiError && <ApiErrorBox error={apiError} />}
      {cancelApiError && !apiError && <ApiErrorBox error={cancelApiError} />}
    </RequiresOneOfPermissions>
  );
};
