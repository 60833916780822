import { TFunction } from 'i18next';
import { round } from 'lodash';
import * as React from 'react';
import { CellProps, Row } from 'react-table';
import { IconRow } from '../../shared/IconRow';
import { CustomColumn } from '../../shared/tables/Table.types';
import { styled } from '../../styling/theme';
import { formatCurrency } from '../../utils/currencyUtils';
import { LoginUserResponse } from '../authentication/loginData/user';
import { DeviationColumnTypes, DeviationResponse, DeviationTableRow } from './deviation';
import {
  getCurrencyForCountry,
  isItemSpecificDispenseFeeDeviation,
  isItemSpecificPriceDeviation,
  rowItemIsServiceType,
} from './DeviationsBase';
import {
  deviationWarningIconsMapping,
  getViewPagePracticeGroupWarnings,
  getWarningsForDeviationRowForViewScreens,
} from './DeviationsWarnings';
import {
  GenericGroupedDeviationRow,
  GroupedDeviationResponseRow,
} from './bulkEdit/SharedDeviationBulkEditColumns';
import { checkPropertyEquality, getUniqueValueOrDefault } from '../../utils/arrayUtils';
import { CountryResponse } from '../authentication/loginData/metadata';
import {
  getDispenseFeePercentageDeviationFromDeviationResponse,
  getDispenseFeeValueDeviation,
  getPricePercentageDeviationFromDeviationResponse,
  getPriceValueDeviation,
} from './deviationCalculations';

const CalculatedValue = styled.span<
  React.HTMLAttributes<HTMLSpanElement> & { highlightAsItemSpecificDeviation: boolean }
>`
  color: ${props => props.theme.colours.deviatedValue.calculated};
`;

const Value = styled.span<
  React.HTMLAttributes<HTMLSpanElement> & { highlightAsItemSpecificDeviation: boolean }
>`
  color: 'default';
`;

type AlignmentProps = React.HTMLAttributes<HTMLSpanElement> & {
  floatRight: boolean;
};

export const AlignedSpan = styled.span<AlignmentProps>`
  display: flex;
  justify-content: ${props => (props.floatRight ? 'flex-end' : 'flex-start')};
`;

export const displayCalculatedValueOrSpan = (
  value: string | number | null,
  isCalculated: boolean | undefined,
  floatRight: boolean,
  highlightAsItemSpecificDeviation: boolean,
  showAsDisabled: boolean = false,
) => {
  return (
    <AlignedSpan floatRight={floatRight}>
      {isCalculated || showAsDisabled ? (
        <CalculatedValue highlightAsItemSpecificDeviation={highlightAsItemSpecificDeviation}>
          {isCalculated && '*'}
          {value}
        </CalculatedValue>
      ) : (
        <Value highlightAsItemSpecificDeviation={highlightAsItemSpecificDeviation}>{value}</Value>
      )}
    </AlignedSpan>
  );
};

export const indeterminateGroupedCellValue = '-';
export const indeterminateGroupedCellValueService = 'N/A';

export type DeviationColumn = CustomColumn<DeviationResponse, DeviationColumnTypes>;

export const itemNameColumn = <T extends DeviationTableRow>(
  t: TFunction,
): CustomColumn<T, DeviationColumnTypes> => ({
  Header: t<string>('columnHeaders.itemName'),
  accessor: (row: T) => row.itemName,
  id: 'itemName',
  Cell: ({ cell, row }: CellProps<GenericGroupedDeviationRow<T>>) => (
    <span>
      {row.canExpand
        ? getGroupedDeviationTableRowCellValue(row, 'itemName', undefined)
        : cell.value}
    </span>
  ),
});

export const itemCodeColumn = <T extends DeviationTableRow>(
  t: TFunction,
): CustomColumn<T, DeviationColumnTypes> => ({
  Header: t<string>('columnHeaders.itemCode'),
  accessor: (row: T) => row.itemCode,
  id: 'itemCode',
  Cell: ({ cell, row }: CellProps<GenericGroupedDeviationRow<T>>) => (
    <span>
      {row.canExpand
        ? getGroupedDeviationTableRowCellValue(row, 'itemCode', undefined)
        : cell.value}
    </span>
  ),
});

export const centralItemPriceColumn = <T extends DeviationTableRow>(
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): CustomColumn<T, DeviationColumnTypes> => ({
  id: 'centralItemPrice',
  Header: t<string>('columnHeaders.centralItemPrice'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.priceGross')
    : t<string>('columnHeaders.groupHeaders.priceNet'),
  accessor: (row: T) => (user.useGrossPrices ? row.centralItemPriceGross : row.centralItemPriceNet),
  Cell: ({ cell, row }: CellProps<GenericGroupedDeviationRow<T>>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedDeviationTableRowCellValue(
        row,
        user.useGrossPrices ? 'centralItemPriceGross' : 'centralItemPriceNet',
        undefined,
      );

      return groupedCellValue == null ? null : (
        <AlignedSpan floatRight={true}>
          {formatCurrency(
            groupedCellValue,
            user.locale,
            getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
          )}
        </AlignedSpan>
      );
    } else {
      return (
        <AlignedSpan floatRight={true}>
          {formatCurrency(
            cell.value,
            user.locale,
            getCurrencyForCountry(row.original.siteCountry, user, countries),
          )}
        </AlignedSpan>
      );
    }
  },
  isRightAligned: true,
});

export const centralItemDispenseFeeColumn = <T extends DeviationTableRow>(
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): CustomColumn<T, DeviationColumnTypes> => ({
  id: 'centralItemDispenseFee',
  Header: t<string>('columnHeaders.centralItemDispenseFee'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
    : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
  accessor: (row: DeviationTableRow) =>
    user.useGrossPrices ? row.centralItemDispenseFeeGross : row.centralItemDispenseFeeNet,
  Cell: ({ cell, row }: CellProps<GenericGroupedDeviationRow<T>>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedDeviationTableRowCellValue(
        row,
        user.useGrossPrices ? 'centralItemDispenseFeeGross' : 'centralItemDispenseFeeNet',
        undefined,
      );

      return groupedCellValue == null ? null : (
        <AlignedSpan floatRight={true}>
          {formatCurrency(
            groupedCellValue,
            user.locale,
            getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
          )}
        </AlignedSpan>
      );
    } else {
      return (
        <AlignedSpan floatRight={true}>
          {formatCurrency(
            cell.value,
            user.locale,
            getCurrencyForCountry(row.original.siteCountry, user, countries),
          )}
        </AlignedSpan>
      );
    }
  },
  isRightAligned: true,
});

export const priceDeviationPercentValueColumn = (
  t: TFunction,
  user: LoginUserResponse,
): DeviationColumn => ({
  Header: t<string>('columnHeaders.priceDeviationPercentValue'),
  id: 'priceDeviationPercentValue',
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.priceGross')
    : t<string>('columnHeaders.groupHeaders.priceNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.priceDeviationPercentValue,
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = priceDeviationPercentageHeaderValue(row, undefined);

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : round(groupedCellValue, 4) + '%'}
        </AlignedSpan>
      );
    } else {
      return cell.value == null
        ? null
        : displayCalculatedValueOrSpan(
            round(getPricePercentageDeviationFromDeviationResponse(cell.row.original), 4) + '%',
            !row.original.priceDeviationIsSetByPercent,
            true,
            isItemSpecificPriceDeviation(row.original),
          );
    }
  },
});

export const priceDeviationsAbsoluteValueNetColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => ({
  id: 'priceDeviationAbsoluteValueNet',
  Header: t<string>('columnHeaders.priceDeviationAbsoluteValue'),
  headerGroupName: t<string>('columnHeaders.groupHeaders.priceNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.priceDeviationAbsoluteValueNet,
  Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
    priceDeviationsAbsoluteValueCell(cellProps, 'priceDeviationAbsoluteValueNet', user, countries),
});

/**
 * Jira pp-933 If this is a stock item do not display code specific
 * Jira pp-969 Display 'N/A' for service
 */
const priceDeviationsCodeSpecificCell = (
  cellProps: CellProps<GroupedDeviationResponseRow>,
  t: TFunction,
) => {
  let value: Boolean | null = null;

  if (cellProps.row.canExpand) {
    const stockItem = cellProps.row.original.deviations![0].stockItem;
    const defaultValue = stockItem
      ? indeterminateGroupedCellValue
      : indeterminateGroupedCellValueService;
    if (stockItem) {
      value = cellProps.row.original.deviations?.every(x => x.itemDeviationPriceOverride)
        ? true
        : cellProps.row.original.deviations?.every(x => !x.itemDeviationPriceOverride)
        ? false
        : null;
    }
    return displayCodeSpecificCell(value, t, defaultValue);
  }

  const stockItem = cellProps.row.original.stockItem;
  const defaultValue = stockItem
    ? indeterminateGroupedCellValue
    : indeterminateGroupedCellValueService;
  value = stockItem ? cellProps.row.original.itemDeviationPriceOverride : null;
  return displayCodeSpecificCell(value, t, defaultValue);
};

/**
 * Jira pp-933 If this is a stock item do not display code specific
 * Jira pp-969 Display 'N/A' for service
 */
const dispenseFeeDeviationsCodeSpecificCell = (
  cellProps: CellProps<GroupedDeviationResponseRow>,
  t: TFunction,
) => {
  let value: Boolean | null = null;

  if (cellProps.row.canExpand) {
    const stockItem = cellProps.row.original.deviations![0].stockItem;
    const defaultValue = stockItem
      ? indeterminateGroupedCellValue
      : indeterminateGroupedCellValueService;
    if (stockItem) {
      value = cellProps.row.original.deviations?.every(x => x.itemDeviationDispenseFeeOverride)
        ? true
        : cellProps.row.original.deviations?.every(x => !x.itemDeviationDispenseFeeOverride)
        ? false
        : null;
    }

    return displayCodeSpecificCell(value, t, defaultValue);
  }

  const stockItem = cellProps.row.original.stockItem;
  const defaultValue = stockItem
    ? indeterminateGroupedCellValue
    : indeterminateGroupedCellValueService;
  value = stockItem ? cellProps.row.original.itemDeviationDispenseFeeOverride : null;

  return displayCodeSpecificCell(value, t, defaultValue);
};

/**
 * Jira pp-969 add a default placeholder for custom values
 * @param value
 * @param t
 * @param defaultValue
 * @returns
 */
const displayCodeSpecificCell = (value: Boolean | null, t: TFunction, defaultValue?: string) => {
  const placeholder = defaultValue === null ? indeterminateGroupedCellValue : defaultValue;
  return (
    <AlignedSpan floatRight={true}>
      {value == null ? placeholder : value ? t('yes') : t('no')}
    </AlignedSpan>
  );
};

export const priceDeviationsCodeSpecificColumn = (
  t: TFunction,
  user: LoginUserResponse,
): DeviationColumn => ({
  id: 'itemDeviationPriceOverride',
  Header: t<string>('columnHeaders.itemDeviationPriceOverride'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.priceGross')
    : t<string>('columnHeaders.groupHeaders.priceNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.itemDeviationPriceOverride,
  Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
    priceDeviationsCodeSpecificCell(cellProps, t),
});

export const dispenseFeeDeviationsCodeSpecificColumn = (
  t: TFunction,
  user: LoginUserResponse,
): DeviationColumn => ({
  id: 'itemDeviationDispenseFeeOverride',
  Header: t<string>('columnHeaders.itemDeviationDispenseFeeOverride'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
    : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.itemDeviationDispenseFeeOverride,
  Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
    dispenseFeeDeviationsCodeSpecificCell(cellProps, t),
});

export const priceDeviationsAbsoluteValueGrossColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => ({
  id: 'priceDeviationAbsoluteValueGross',
  Header: t<string>('columnHeaders.priceDeviationAbsoluteValue'),
  headerGroupName: t<string>('columnHeaders.groupHeaders.priceGross'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.priceDeviationAbsoluteValueGross,
  Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
    priceDeviationsAbsoluteValueCell(
      cellProps,
      'priceDeviationAbsoluteValueGross',
      user,
      countries,
    ),
});

const priceDeviationsAbsoluteValueCell = (
  { cell, row }: CellProps<GroupedDeviationResponseRow>,
  fieldName: keyof DeviationResponse,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
) => {
  if (row.canExpand) {
    const groupedCellValue = getGroupedDeviationResponseCellValue(row, fieldName, undefined);

    return (
      <AlignedSpan floatRight={true}>
        {groupedCellValue == null
          ? indeterminateGroupedCellValue
          : formatCurrency(
              groupedCellValue,
              user.locale,
              getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
            )}
      </AlignedSpan>
    );
  } else {
    return displayCalculatedValueOrSpan(
      formatCurrency(
        cell.value,
        user.locale,
        getCurrencyForCountry(row.original.siteCountry, user, countries),
      ),
      cell.row.original.priceDeviationIsSetByPercent,
      true,
      isItemSpecificPriceDeviation(row.original),
    );
  }
};
export const deviatedPriceColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => ({
  id: 'deviatedPrice',
  Header: t<string>('columnHeaders.deviatedPrice'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.priceGross')
    : t<string>('columnHeaders.groupHeaders.priceNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) =>
    user.useGrossPrices ? row.localItemPriceGross : row.localItemPriceNet,
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = priceDeviationHeaderValue(row, undefined, user.useGrossPrices);

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : formatCurrency(
                groupedCellValue,
                user.locale,
                getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
              )}
        </AlignedSpan>
      );
    } else {
      return displayCalculatedValueOrSpan(
        formatCurrency(
          getPriceValueDeviation(cell.row.original, user.useGrossPrices),
          user.locale,
          getCurrencyForCountry(row.original.siteCountry, user, countries),
        ),
        true,
        true,
        isItemSpecificPriceDeviation(row.original),
      );
    }
  },
});

export const dispenseFeeDeviationPercentValueColumn = (
  t: TFunction,
  user: LoginUserResponse,
): DeviationColumn => ({
  id: 'dispenseFeeDeviationPercentValue',
  Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
    : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.dispenseFeeDeviationPercentValue,
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = dispenseFeeDeviationPercentageHeaderValue(row, undefined);

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : round(groupedCellValue, 4) + '%'}
        </AlignedSpan>
      );
    } else {
      return cell.value == null
        ? null
        : displayCalculatedValueOrSpan(
            round(getDispenseFeePercentageDeviationFromDeviationResponse(cell.row.original), 4) +
              '%',
            !row.original.dispenseFeeDeviationIsSetByPercent,
            true,
            isItemSpecificDispenseFeeDeviation(row.original),
            rowItemIsServiceType(row),
          );
    }
  },
});

export const dispenseFeeDeviationsAbsoluteValueNetColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => ({
  id: 'dispenseFeeDeviationAbsoluteValueNet',
  Header: t<string>('columnHeaders.dispenseFeeDeviationAbsoluteValue'),
  headerGroupName: t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) => row.dispenseFeeDeviationAbsoluteValueNet,
  Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
    dispenseFeeDeviationsAbsoluteValueCell(
      cellProps,
      'dispenseFeeDeviationAbsoluteValueNet',
      user,
      countries,
    ),
});

export const dispenseFeeDeviationsAbsoluteValueGrossColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => {
  return {
    id: 'dispenseFeeDeviationAbsoluteValueGross',
    Header: t<string>('columnHeaders.dispenseFeeDeviationAbsoluteValue'),
    headerGroupName: t<string>('columnHeaders.groupHeaders.dispenseFeeGross'),
    isRightAligned: true,
    accessor: (row: DeviationResponse) => row.dispenseFeeDeviationAbsoluteValueGross,
    Cell: (cellProps: CellProps<GroupedDeviationResponseRow>) =>
      dispenseFeeDeviationsAbsoluteValueCell(
        cellProps,
        'dispenseFeeDeviationAbsoluteValueGross',
        user,
        countries,
      ),
  };
};

const dispenseFeeDeviationsAbsoluteValueCell = (
  { cell, row }: CellProps<GroupedDeviationResponseRow>,
  fieldName: keyof DeviationResponse,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
) => {
  if (row.canExpand) {
    const groupedCellValue = getGroupedDeviationResponseCellValue(row, fieldName, undefined);

    return (
      <AlignedSpan floatRight={true}>
        {groupedCellValue == null
          ? indeterminateGroupedCellValue
          : formatCurrency(
              groupedCellValue,
              user.locale,
              getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
            )}
      </AlignedSpan>
    );
  } else {
    return displayCalculatedValueOrSpan(
      formatCurrency(
        cell.value,
        user.locale,
        getCurrencyForCountry(row.original.siteCountry, user, countries),
      ),
      cell.row.original.dispenseFeeDeviationIsSetByPercent,
      true,
      isItemSpecificDispenseFeeDeviation(row.original),
    );
  }
};

export const deviatedDispenseFeeColumn = (
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): DeviationColumn => ({
  id: 'deviatedDispenseFee',
  Header: t<string>('columnHeaders.deviatedDispenseFee'),
  headerGroupName: user.useGrossPrices
    ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
    : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
  isRightAligned: true,
  accessor: (row: DeviationResponse) =>
    user.useGrossPrices ? row.localItemDispenseFeeGross : row.localItemDispenseFeeNet,
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = dispenseFeeDeviationHeaderValue(row, undefined, user.useGrossPrices);

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : formatCurrency(
                groupedCellValue,
                user.locale,
                getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
              )}
        </AlignedSpan>
      );
    } else {
      return displayCalculatedValueOrSpan(
        formatCurrency(
          getDispenseFeeValueDeviation(cell.row.original, user.useGrossPrices),
          user.locale,
          getCurrencyForCountry(row.original.siteCountry, user, countries),
        ),
        true,
        true,
        isItemSpecificDispenseFeeDeviation(row.original),
      );
    }
  },
});

export const isHiddenColumn = (t: TFunction): DeviationColumn => ({
  id: 'isHidden',
  Header: t<string>('columnHeaders.isHidden'),
  accessor: (row: DeviationResponse) => (row.isHidden ? t('yes') : t('no')),
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue: boolean | undefined = getGroupedDeviationResponseCellValue<boolean>(
        row,
        'isHidden',
        undefined,
      );
      return (
        <span>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : groupedCellValue
            ? t('yes')
            : t('no')}
        </span>
      );
    } else {
      return <span>{cell.value}</span>;
    }
  },
});

export const supplierProductCodeColumn = (t: TFunction): DeviationColumn => ({
  Header: t<string>('columnHeaders.supplierProductCode'),
  accessor: (row: DeviationResponse) => row.supplierProductCode,
  id: 'supplierProductCode',
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => (
    <span>
      {row.canExpand
        ? getGroupedDeviationResponseCellValue(row, 'supplierProductCode', undefined)
        : cell.value}
    </span>
  ),
});

export const supplierNameColumn = (t: TFunction): DeviationColumn => ({
  Header: t<string>('columnHeaders.supplierName'),
  accessor: (row: DeviationResponse) => row.supplierName,
  id: 'supplierName',
  Cell: ({ cell, row }: CellProps<GroupedDeviationResponseRow>) => (
    <span>
      {row.canExpand
        ? getGroupedDeviationResponseCellValue(row, 'supplierName', undefined)
        : cell.value}
    </span>
  ),
});

export const deviationWarningsColumn = (
  t: TFunction,
  user: LoginUserResponse,
): DeviationColumn => ({
  id: 'deviationWarnings',
  fitContent: true,
  Cell: ({ row }: CellProps<DeviationResponse>) => {
    if (row.canExpand) {
      const deviationWarnings = getViewPagePracticeGroupWarnings(row, user);
      return (
        <IconRow
          iconsFromNamesMapping={deviationWarningIconsMapping}
          iconNames={deviationWarnings}
          testId={'deviationWarnings'}
          ariaLabel={'warning icons'}
          t={t}
        />
      );
    } else {
      const deviationWarnings = getWarningsForDeviationRowForViewScreens(row, user);
      return (
        <IconRow
          iconsFromNamesMapping={deviationWarningIconsMapping}
          iconNames={deviationWarnings}
          testId={'deviationWarnings'}
          ariaLabel={'warning icons'}
          t={t}
        />
      );
    }
  },
});

export const getGroupedDeviationTableRowCellValue = <
  TRow extends DeviationTableRow,
  TValue extends string | number | boolean | undefined
>(
  row: Row<GenericGroupedDeviationRow<TRow>>,
  fieldName: keyof DeviationTableRow,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(
      deviations,
      deviation => deviation[fieldName] as TValue,
      defaultValue,
    ) ?? defaultValue
  );
};

const getGroupedDeviationResponseCellValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  fieldName: keyof DeviationResponse,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(
      deviations,
      deviation => deviation[fieldName] as TValue,
      defaultValue,
    ) ?? defaultValue
  );
};

const dispenseFeeDeviationPercentageHeaderValue = <
  TValue extends string | number | boolean | undefined
>(
  row: Row<GroupedDeviationResponseRow>,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  return groupedDeviationHeaderValue(
    row,
    'dispenseFeeDeviationPercentValue',
    defaultValue,
    'defaultDispenseFeeDeviationPercentValue',
    false,
  );
};

const dispenseFeeDeviationHeaderValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  defaultValue: TValue | undefined,
  useGrossPrice: Boolean,
): TValue | undefined => {
  let itemDeviationFieldName: keyof DeviationResponse;
  let treatmentTypeDeviationFieldName: keyof DeviationResponse;
  itemDeviationFieldName = useGrossPrice ? 'localItemDispenseFeeGross' : 'localItemDispenseFeeNet';
  treatmentTypeDeviationFieldName = useGrossPrice
    ? 'defaultDispenseFeeGross'
    : 'defaultDispenseFeeNet';
  return groupedDeviationHeaderValue(
    row,
    itemDeviationFieldName,
    defaultValue,
    treatmentTypeDeviationFieldName,
    false,
  );
};

const priceDeviationPercentageHeaderValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  return groupedDeviationHeaderValue(
    row,
    'priceDeviationPercentValue',
    defaultValue,
    'defaultPriceDeviationPercentValue',
    true,
  );
};

const priceDeviationHeaderValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  defaultValue: TValue | undefined,
  useGrossPrice: Boolean,
): TValue | undefined => {
  let itemDeviationFieldName: keyof DeviationResponse;
  let treatmentTypeDeviationFieldName: keyof DeviationResponse;
  itemDeviationFieldName = useGrossPrice ? 'localItemPriceGross' : 'localItemPriceNet';
  treatmentTypeDeviationFieldName = useGrossPrice ? 'defaultPriceGross' : 'defaultPriceNet';
  return groupedDeviationHeaderValue(
    row,
    itemDeviationFieldName,
    defaultValue,
    treatmentTypeDeviationFieldName,
    true,
  );
};

/**
 * Jira PP-639 | When displaying a TX Group, if we have a TX but it is 0, we need
 *               to display the price value not the default (default being the tx value).
 *               So if we have the TX ID and it happens to be 0 then return the row value.
 *
 *               A TX Deviation is Tx.Id && Value > 0
 *               The reverse is a Tx.Id && Value === 0
 * Jira PP-1047 | Tx Deviation rules have chanhed, if a TX is 0 then it is '0'. A TX of '0' used
 *              to mean 'We have no TX - show the Deviation, now a TX of 0 means, it is 0
 * @param row
 * @param itemDeviationFieldName
 * @param defaultValue
 * @param treatmentTypeValueField
 * @param priceOverride
 * @returns
 */
const groupedDeviationHeaderValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationResponseRow>,
  itemDeviationFieldName: keyof DeviationResponse,
  defaultValue: TValue | undefined,
  treatmentTypeValueField: keyof DeviationResponse,
  priceOverride: Boolean,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];

  // If none of the sub rows have a price override and there is a treatment type override
  const isPriceSameForAll = checkPropertyEquality(deviations, treatmentTypeValueField);
  const allHaveNoPriceOverride = deviations.every(x => !x.itemDeviationPriceOverride);
  const allHaveNoDeviationOverride = deviations.every(x => !x.itemDeviationDispenseFeeOverride);

  // Work out if the item collection have a Tx deviation - check the ID AND THE value
  const dispFeeItemsHaveTxDeviation = deviations.every(
    x => x.treatmentTypeDeviationId != null, // && x.defaultDispenseFeeDeviationPercentValue !== 0,
  );
  const priceItemsHaveTxDeviation = deviations.every(
    x => x.treatmentTypeDeviationId != null, // && x.defaultPriceDeviationPercentValue !== 0,
  );

  // Leaving for debuging - we come here a lot
  // console.log('isPriceSameForAll::', isPriceSameForAll);
  // console.log('allHaveNoPriceOverride::', allHaveNoPriceOverride);
  // console.log('allHaveNoDeviationOverride::', allHaveNoDeviationOverride);
  // console.log('dispFeeItemsHaveTxDeviation::', dispFeeItemsHaveTxDeviation);
  // console.log('priceItemsHaveTxDeviation::', priceItemsHaveTxDeviation);
  // console.log('itemDeviationFieldName::', itemDeviationFieldName);
  // console.log('treatmentTypeValueField::', treatmentTypeValueField);

  if (
    (priceOverride && allHaveNoPriceOverride && priceItemsHaveTxDeviation) ||
    (!priceOverride && allHaveNoDeviationOverride && dispFeeItemsHaveTxDeviation)
  ) {
    // Return the value if all items are the same, otherwise return 0 (translated to '-' in the view)
    let treatmetTypeValue: any = isPriceSameForAll ? deviations[0][treatmentTypeValueField] : 0;

    /**
     * This is now not true; if a TX is 0 then its 0. A TX of 0 used
     * to be 'We have no TX - show the Deviation, now we have a TX of 0.
     *
     */
    // TX value unless its 0 - then return the row value.
    if (treatmetTypeValue === 0) {
      treatmetTypeValue = getGroupedDeviationResponseCellValue(
        row,
        treatmentTypeValueField,
        defaultValue,
      );
    }
    return treatmetTypeValue;
  } else {
    return getGroupedDeviationResponseCellValue(row, itemDeviationFieldName, defaultValue);
  }
};
