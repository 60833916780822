import { TFunction } from 'i18next';
import * as React from 'react';
import { CellProps } from 'react-table';
import { toShortDateTimeWithSeconds } from '../../models/dates/dateTimeStamp';
import { Link } from '../../shared/navigation/Link';
import { CustomColumn } from '../../shared/tables/Table.types';
import { LoginUserResponse, userHasOneOfPermissions } from '../authentication/loginData/user';
import { permissions } from '../authentication/permissions';
import { GetPmsRequestErrorsResponse, PmsRequestErrorOverview } from './pmsRequestError';
import { viewPmsRequestErrorUrl } from './pmsRequestErrorUrls';

export const mapPmsErrorsResponseToTableData = (response: GetPmsRequestErrorsResponse) =>
  response.pmsRequestErrors;

export const pmsRequestErrorsColumns = (
  t: TFunction,
  user: LoginUserResponse,
): Array<CustomColumn<PmsRequestErrorOverview>> => [
  {
    Header: t<string>('viewErrors.tableHeadings.time'),
    accessor: (row: PmsRequestErrorOverview) => row.time,
    id: 'time',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) =>
      userHasOneOfPermissions(user, [permissions.SuperAdmin, permissions.CentralAdmin]) ? (
        <Link to={viewPmsRequestErrorUrl(cell.row.original.pmsRequestErrorId)}>
          {toShortDateTimeWithSeconds(cell.value, user.locale)}
        </Link>
      ) : (
        <span>{toShortDateTimeWithSeconds(cell.value, user.locale)}</span>
      ),
  },
  {
    Header: t<string>('viewErrors.tableHeadings.requestSummary'),
    accessor: (row: PmsRequestErrorOverview) => row.requestSummary,
    id: 'requestJobType',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) => <>{cell.value}</>,
  },
  {
    Header: t<string>('viewErrors.tableHeadings.userEmail'),
    accessor: (row: PmsRequestErrorOverview) => row.userEmail,
    id: 'userEmail',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) => <>{cell.value}</>,
  },
  {
    Header: t<string>('viewErrors.tableHeadings.pmsType'),
    accessor: (row: PmsRequestErrorOverview) => row.pmsType,
    id: 'pmsType',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) => <>{cell.value}</>,
  },
  {
    Header: t<string>('viewErrors.tableHeadings.pmsName'),
    accessor: (row: PmsRequestErrorOverview) => row.pmsName,
    id: 'pmsName',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) => <>{cell.value}</>,
  },
  {
    Header: t<string>('viewErrors.tableHeadings.url'),
    accessor: (row: PmsRequestErrorOverview) => row.url,
    id: 'url',
    Cell: ({ cell }: CellProps<PmsRequestErrorOverview>) => <>{cell.value}</>,
  },
];
