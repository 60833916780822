import { CellProps, HeaderProps, Row } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faUndo } from '@fortawesome/free-solid-svg-icons';
import React, { ChangeEvent } from 'react';
import { CustomColumn } from '../../../shared/tables/Table.types';
import {
  GenericGroupedTreatmentTypeDeviationRow,
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationColumnTypes,
  TreatmentTypeDeviationResponse,
  TreatmentTypeDeviationTableRow,
} from './treatmentTypeDeviations';
import { TFunction } from 'i18next';
import { TupleKeyDictionary } from '../../../utils/TupleKeyDictionary';
import { Id } from '../../../models/id';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { MarginLeftIcon } from '../../../shared/StyledIcons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FormikProps } from 'formik';
import {
  CreateTreatmentTypeDeviationBulkEditFormModel,
  EditTreatmentTypeDeviationBulkEditFormModel,
  getTreatmentTypeDeviationFieldPrefix,
  getTreatmentTypeDeviationFieldPrefixBySiteAndTreatmentType,
  TreatmentTypeDeviationBulkEditLineFormModel,
} from './bulkEdit/treatmentTypeDeviationBulkEdit';
import { isEmpty, round } from 'lodash';
import { getUniqueValueOrDefault } from '../../../utils/arrayUtils';
import { IconButton } from '../../../shared/buttons/IconButton';
import { AlignedSpan, indeterminateGroupedCellValue } from '../DeviationColumns';
import {
  getGroupedRowTreatmentTypeDeviationsWarningsForEditablePages,
  getGroupedRowTreatmentTypeDeviationsWarningsForViewPages,
  getRowTreatmentTypeDeviationsWarningsForEditablePages,
  getRowTreatmentTypeDeviationsWarningsForViewPages,
  treatmentTypeDeviationWarningIconsMapping,
} from './TreatmentTypeDeviationsWarnings';
import { IconRow } from '../../../shared/IconRow';
import { styled } from '../../../styling/theme';

const groupedRowTestIdPrefix = 'treatment-type-deviation-grouped-row';
const rowTestIdPrefix = 'treatment-type-deviation-row';

export const getGroupedTreatmentTypeDeviationRowExpandToggleTestId = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
) => `${groupedRowTestIdPrefix}-expand-toggle-${practiceGroupId}-${treatmentTypeId}`;

export const getGroupedTreatmentTypeDeviationRowPricePercentageTestId = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
) => `${groupedRowTestIdPrefix}-price-percentage-${practiceGroupId}-${treatmentTypeId}`;

export const getTreatmentTypeDeviationRowPricePercentageTestId = (
  treatmentTypeId: Id | undefined,
  siteId: Id | undefined,
) => `${rowTestIdPrefix}-price-percentage-${treatmentTypeId}-${siteId}`;

export const getGroupedTreatmentTypeDeviationRowDispenseFeePercentageTestId = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
) => `${groupedRowTestIdPrefix}-dispense-fee-percentage-${practiceGroupId}-${treatmentTypeId}`;

export const getTreatmentTypeDeviationRowDispenseFeePercentageTestId = (
  treatmentTypeId: Id | undefined,
  siteId: Id | undefined,
) => `${rowTestIdPrefix}-dispense-fee-percentage-${treatmentTypeId}-${siteId}`;

export const treatmentTypeDeviationExpandedColumn = <
  T extends TreatmentTypeDeviationTableRow
>(): CustomColumn<
  GenericGroupedTreatmentTypeDeviationRow<T>,
  TreatmentTypeDeviationColumnTypes
> => ({
  id: 'expanded',
  fitContent: true,
  Header: ({
    getToggleAllRowsExpandedProps,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  }: HeaderProps<GenericGroupedTreatmentTypeDeviationRow<T>>) => (
    <span
      {...getToggleAllRowsExpandedProps()}
      // There seems to be a bug with the way that getToggleAllRowsExpandedProps works,
      // so for now we have to manually add the onClick event
      onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
    >
      <FontAwesomeIcon icon={isAllRowsExpanded ? faChevronUp : faChevronDown} />
    </span>
  ),
  Cell: ({ row }: CellProps<GenericGroupedTreatmentTypeDeviationRow<T>>) =>
    row.canExpand ? (
      <span
        data-testid={getGroupedTreatmentTypeDeviationRowExpandToggleTestId(
          row.original.practiceGroupId,
          row.original.treatmentTypeId,
        )}
        {...row.getToggleRowExpandedProps()}
      >
        <FontAwesomeIcon icon={row.isExpanded ? faChevronUp : faChevronDown} />
      </span>
    ) : null,
});

export const treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn = <
  T extends TreatmentTypeDeviationTableRow
>(
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<Id, Id, Id> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): CustomColumn<GenericGroupedTreatmentTypeDeviationRow<T>, TreatmentTypeDeviationColumnTypes> => ({
  id: 'siteName',
  Header: t<string>('columnHeaders.practiceGroupOrSiteName'),
  accessor: (row: TreatmentTypeDeviationTableRow) => row.siteName,
  Cell: ({ cell, row }: CellProps<GenericGroupedTreatmentTypeDeviationRow<T>>) =>
    row.canExpand ? (
      <span>
        {row.original.practiceGroupId
          ? practiceGroupsById[row.original.practiceGroupId]?.name ?? ''
          : t('noPracticeGroup')}
      </span>
    ) : (
      <span>
        {cell.value}
        {scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(
            row.original.siteId,
            row.original.treatmentTypeId,
          ) && <MarginLeftIcon icon={faClock} />}
      </span>
    ),
});

export const treatmentTypeDeviationTreatmentTypeNameColumn = <
  T extends TreatmentTypeDeviationTableRow
>(
  t: TFunction,
): CustomColumn<GenericGroupedTreatmentTypeDeviationRow<T>, TreatmentTypeDeviationColumnTypes> => ({
  id: 'treatmentTypeName',
  Header: t<string>('columnHeaders.treatmentType'),
  accessor: (row: TreatmentTypeDeviationTableRow) => row.treatmentTypeName,
  Cell: ({ cell, row }: CellProps<GenericGroupedTreatmentTypeDeviationRow<T>>) => {
    if (row.canExpand) {
      const groupCellValue =
        getUniqueValueOrDefault(
          row.original.deviations ?? [],
          deviation => deviation.treatmentTypeName,
          undefined,
        ) ?? undefined;

      return <span>{groupCellValue ?? indeterminateGroupedCellValue}</span>;
    } else {
      return <span>{cell.value}</span>;
    }
  },
});

export const treatmentTypePriceDeviationPercentColumn = (
  t: TFunction,
): CustomColumn<GroupedTreatmentTypeDeviationResponseRow, TreatmentTypeDeviationColumnTypes> => ({
  id: 'priceDeviationPercentValue',
  Header: t<string>('columnHeaders.priceDeviationPercentValue'),
  isRightAligned: true,
  accessor: (row: GroupedTreatmentTypeDeviationResponseRow) => row.priceDeviationPercentValue,
  Cell: ({ cell, row }: CellProps<GroupedTreatmentTypeDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedTreatmentTypeDeviationResponseCellValue(
        row,
        'priceDeviationPercentValue',
        undefined,
      );

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : round(groupedCellValue, 4) + '%'}
        </AlignedSpan>
      );
    } else {
      return <AlignedSpan floatRight={true}>{round(cell.value, 4)}%</AlignedSpan>;
    }
  },
});

export const treatmentTypeDispenseFeeDeviationPercentColumn = (
  t: TFunction,
): CustomColumn<GroupedTreatmentTypeDeviationResponseRow, TreatmentTypeDeviationColumnTypes> => ({
  id: 'dispenseFeeDeviationPercentValue',
  Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
  isRightAligned: true,
  accessor: (row: GroupedTreatmentTypeDeviationResponseRow) => row.dispenseFeeDeviationPercentValue,
  Cell: ({ cell, row }: CellProps<GroupedTreatmentTypeDeviationResponseRow>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedTreatmentTypeDeviationResponseCellValue(
        row,
        'dispenseFeeDeviationPercentValue',
        undefined,
      );

      return (
        <AlignedSpan floatRight={true}>
          {groupedCellValue == null
            ? indeterminateGroupedCellValue
            : round(groupedCellValue, 4) + '%'}
        </AlignedSpan>
      );
    } else {
      return <AlignedSpan floatRight={true}>{round(cell.value, 4)}%</AlignedSpan>;
    }
  },
});

export const treatmentTypeDeviationsWarningIconsRowTestId = 'warning-icons';

export const getTreatmentTypeDeviationsWarningIconsRowTestIdForGroupedRow = (
  practiceGroupId: Id | undefined,
  treatmentTypeId: Id | undefined,
) =>
  `${treatmentTypeDeviationsWarningIconsRowTestId}-groupedRow-${practiceGroupId}-${treatmentTypeId}`;

export const getTreatmentTypeDeviationsWarningIconsRowTestIdForRow = (
  treatmentTypeId: Id | undefined,
  siteId: Id | undefined,
) => `${treatmentTypeDeviationsWarningIconsRowTestId}-row-${treatmentTypeId}-${siteId}`;

export const groupedWarningsColumnForViewPages = <T extends TreatmentTypeDeviationTableRow>(
  t: TFunction,
): CustomColumn<GenericGroupedTreatmentTypeDeviationRow<T>, TreatmentTypeDeviationColumnTypes> => ({
  id: 'warnings',
  fitContent: true,
  Cell: ({ row }: CellProps<GenericGroupedTreatmentTypeDeviationRow<T>>) => {
    const warnings = row.canExpand
      ? getGroupedRowTreatmentTypeDeviationsWarningsForViewPages(row)
      : getRowTreatmentTypeDeviationsWarningsForViewPages(row);

    const testId = row.canExpand
      ? getTreatmentTypeDeviationsWarningIconsRowTestIdForGroupedRow(
          row.original.practiceGroupId,
          row.original.treatmentTypeId,
        )
      : getTreatmentTypeDeviationsWarningIconsRowTestIdForRow(
          row.original.treatmentTypeId,
          row.original.siteId,
        );

    return (
      <IconRow
        testId={testId}
        ariaLabel="warning icons"
        iconsFromNamesMapping={treatmentTypeDeviationWarningIconsMapping}
        iconNames={warnings}
        t={t}
      />
    );
  },
});

export const groupedWarningsColumnForEditablePages = <T extends TreatmentTypeDeviationTableRow>(
  t: TFunction,
): CustomColumn<GenericGroupedTreatmentTypeDeviationRow<T>, TreatmentTypeDeviationColumnTypes> => ({
  id: 'warnings',
  fitContent: true,
  Cell: ({ formikProps, row }: CellProps<GenericGroupedTreatmentTypeDeviationRow<T>>) => {
    const formikValues = formikProps?.values as EditTreatmentTypeDeviationBulkEditFormModel;

    const warnings = row.canExpand
      ? getGroupedRowTreatmentTypeDeviationsWarningsForEditablePages(formikValues, row)
      : getRowTreatmentTypeDeviationsWarningsForEditablePages(formikValues, row);

    const testId = row.canExpand
      ? getTreatmentTypeDeviationsWarningIconsRowTestIdForGroupedRow(
          row.original.practiceGroupId,
          row.original.treatmentTypeId,
        )
      : getTreatmentTypeDeviationsWarningIconsRowTestIdForRow(
          row.original.treatmentTypeId,
          row.original.siteId,
        );

    return (
      <WarningIconRowContainer>
        <IconRow
          testId={testId}
          ariaLabel="warning icons"
          iconsFromNamesMapping={treatmentTypeDeviationWarningIconsMapping}
          iconNames={warnings}
          t={t}
        />
      </WarningIconRowContainer>
    );
  },
});

const WarningIconRowContainer = styled.div`
  min-width: 100px;
`;

export const groupedActionsColumn = <T extends TreatmentTypeDeviationTableRow>(
  t: TFunction,
): CustomColumn<T, TreatmentTypeDeviationColumnTypes> => ({
  id: 'actions',
  fitContent: true,
  Cell({ row, formikProps }: CellProps<T>) {
    if (row.canExpand) {
      return (
        <IconButton
          faIcon={faUndo}
          type="button"
          title={t('undoButton')}
          onClick={() =>
            setTreatmentTypeDeviationBulkEditSubRowsInGroupedRowToInitialValues(formikProps, row)
          }
        />
      );
    } else {
      return (
        <IconButton
          faIcon={faUndo}
          type="button"
          title={t('undoButton')}
          onClick={() => setTreatmentTypeDeviationBulkEditRowToInitialValues(formikProps, row)}
        />
      );
    }
  },
});

export const setTreatmentTypeDeviationBulkEditSubRowsInGroupedRowToInitialValues = <
  TRow extends TreatmentTypeDeviationTableRow,
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
) => {
  if (
    !formikProps?.initialValues ||
    !(formikProps?.initialValues as TFormModel)?.deviations ||
    !row.original.deviations
  ) {
    return;
  }

  const initialValues = (formikProps?.initialValues as EditTreatmentTypeDeviationBulkEditFormModel)
    .deviations;

  row.original.deviations.forEach(deviation => {
    formikProps?.setFieldValue(
      getTreatmentTypeDeviationFieldPrefixBySiteAndTreatmentType(
        deviation.siteId,
        deviation.treatmentTypeId,
      ),
      initialValues[deviation.siteId][deviation.treatmentTypeId],
    );
  });
};

export const setTreatmentTypeDeviationBulkEditRowToInitialValues = <
  TRow extends TreatmentTypeDeviationTableRow,
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
) => {
  if (
    !formikProps?.initialValues ||
    isEmpty((formikProps?.initialValues as TFormModel)?.deviations)
  ) {
    return;
  }

  const rowInitialValues = (formikProps?.initialValues as TFormModel).deviations[
    row.original.siteId
  ][row.original.treatmentTypeId];

  formikProps?.setFieldValue(getTreatmentTypeDeviationFieldPrefix(row), rowInitialValues);
};

export const treatmentTypeDeviationBulkEditValueHasChanged = <
  TRow extends TreatmentTypeDeviationTableRow,
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
  field: keyof TreatmentTypeDeviationBulkEditLineFormModel,
): boolean => {
  if (!formikProps?.initialValues || !formikProps?.values) {
    return false;
  }

  const initialValue = getTreatmentTypeDeviationBulkEditFieldValue(
    formikProps?.initialValues as TFormModel,
    row,
    field,
  );

  const currentValue = getTreatmentTypeDeviationBulkEditFieldValue(
    formikProps?.values as TFormModel,
    row,
    field,
  );

  return !initialValue && !currentValue ? false : initialValue !== currentValue;
};

const getTreatmentTypeDeviationBulkEditFieldValue = <
  TRow extends TreatmentTypeDeviationTableRow,
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel
>(
  formModel: TFormModel,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
  field: keyof TreatmentTypeDeviationBulkEditLineFormModel,
): string | undefined => {
  if (!formModel || isEmpty(formModel.deviations)) {
    return undefined;
  }

  return formModel.deviations[row.original.siteId][row.original.treatmentTypeId][field] as string;
};

export const treatmentTypeDeviationHeaderValueHasChanged = <
  TRow extends TreatmentTypeDeviationTableRow
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
  field: keyof TreatmentTypeDeviationBulkEditLineFormModel,
  currentValue: string | undefined,
) => {
  const initialValue = getTreatmentTypeDeviationHeaderInitialValue(formikProps, row, field);
  return !initialValue && !currentValue ? false : initialValue !== currentValue;
};

export const getTreatmentTypeDeviationHeaderInitialValue = <
  TRow extends TreatmentTypeDeviationTableRow,
  TFormModel extends EditTreatmentTypeDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
  field: keyof TreatmentTypeDeviationBulkEditLineFormModel,
): string | undefined => {
  if (row.original.deviations && formikProps?.initialValues) {
    const deviations = getFormModelTreatmentTypeDeviations(
      row.original.deviations,
      formikProps?.initialValues as TFormModel,
    );
    return (
      getUniqueValueOrDefault(deviations, deviation => deviation[field] as string, undefined) ??
      undefined
    );
  } else {
    return undefined;
  }
};

export const getTreatmentTypeDeviationHeaderFieldProps = <
  TRow extends TreatmentTypeDeviationTableRow
>(
  row: Row<GenericGroupedTreatmentTypeDeviationRow<TRow>>,
  formikProps: FormikProps<unknown> | undefined,
  field: keyof TreatmentTypeDeviationBulkEditLineFormModel,
): {
  fieldValue: string | undefined;
  updateSubRowFields: (event: ChangeEvent<HTMLInputElement>) => void;
} => {
  if (row.original.deviations && formikProps?.values) {
    const deviations = getFormModelTreatmentTypeDeviations(
      row.original.deviations,
      formikProps?.values as CreateTreatmentTypeDeviationBulkEditFormModel,
    );

    const fieldValue =
      getUniqueValueOrDefault(deviations, deviation => deviation[field] as string, undefined) ??
      undefined;

    const updateSubRowFields = (event: ChangeEvent<HTMLInputElement>) => {
      row.original.deviations?.forEach(deviationResponse => {
        const newValue = event.target.value;
        const fieldPrefix = getTreatmentTypeDeviationFieldPrefixBySiteAndTreatmentType(
          deviationResponse.siteId,
          deviationResponse.treatmentTypeId,
        );
        formikProps.setFieldValue(`${fieldPrefix}.${field}`, newValue);
      });
    };
    return { fieldValue, updateSubRowFields };
  } else {
    return { fieldValue: undefined, updateSubRowFields: () => null };
  }
};

export const getFormModelTreatmentTypeDeviations = (
  subRowDeviations: Array<TreatmentTypeDeviationTableRow>,
  formValues: EditTreatmentTypeDeviationBulkEditFormModel,
) =>
  isEmpty(formValues.deviations)
    ? []
    : subRowDeviations.map(
        deviation => formValues.deviations[deviation.siteId][deviation.treatmentTypeId],
      );

const getGroupedTreatmentTypeDeviationResponseCellValue = <
  TValue extends string | number | boolean | undefined
>(
  row: Row<GroupedTreatmentTypeDeviationResponseRow>,
  fieldName: keyof TreatmentTypeDeviationResponse,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(
      deviations,
      deviation => deviation[fieldName] as TValue,
      defaultValue,
    ) ?? defaultValue
  );
};
