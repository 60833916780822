import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import { filter, flatMap, forEach, keyBy, map, mapValues, some } from 'lodash';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { getLocalNow } from '../../../models/dates/now';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoader, DataLoaderWithParams } from '../../../shared/DataLoader';
import { MultiSelectDropdown } from '../../../shared/dropdowns/MultiSelectDropdown';
import { useMultiSelectDropdown } from '../../../shared/dropdowns/useMultiSelectDropdown';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { DateField } from '../../../shared/forms/DateField';
import { DropdownOptions } from '../../../shared/forms/DropdownField';
import { InputField } from '../../../shared/forms/InputField';
import { useNavigateAwayWarning } from '../../../shared/hooks/useNavigateAwayWarning';
import { RequiresOneOfPermissions } from '../../../shared/Permissions';
import { useFiltering } from '../../../shared/tables/filtering/useFiltering';
import { FormikTable } from '../../../shared/tables/FormikTable';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { dashboardUrl, deviationsUrl } from '../../../urls';
import { formatNumber, formatNumberForFields, parseNumber } from '../../../utils/numberUtils';
import { navigate } from '../../../utils/routing';
import { TupleKeyDictionary } from '../../../utils/TupleKeyDictionary';
import { UserContext } from '../../authentication/loginData/userContext';
import { getItemFieldsFromPms } from '../../items/itemsApi';
import { SpaceBetweenButtonGroup } from '../../items/ItemsTables/ItemsBase';
import { getAllPracticeGroups } from '../../sites/practiceGroups/practiceGroupsApi';
import {
  DeviationColumnTypes,
  DeviationResponse,
  DeviationsResponse,
  validDeviationColumnTypeOrUndefined,
} from '../deviation';
import {
  createDeviationBulkEdit,
  getDeviationFilterDropdownOptions,
  getDeviations,
} from '../deviationsApi';
import {
  DeviationColumnDropdownType,
  deviationPageSizeStorage,
  DeviationsBaseProps,
  deviationsPageSize,
  DeviationsScheduledForUpdateInfoBar,
  filterToUrl,
  getSubRows,
  initialDeviationFilterOptions,
  renderDeviationToggleButton,
  urlToFilter,
} from '../DeviationsBase';
import { getCreateDeviationBulkEditColumns } from './CreateDeviationBulkEditColumns';
import {
  CreateDeviationBulkEditCommand,
  CreateDeviationBulkEditFormModel,
  CreateDeviationBulkEditValidator,
  DeviationBulkEditCommandLine,
  DeviationBulkEditLineValidator,
  editCreateDeviationBulkEditPermissions,
} from './deviationBulkEdit';
import { GroupedDeviationResponseRow } from './SharedDeviationBulkEditColumns';
import {
  getPaginationDetailsFromPagedResponse,
  PaginationDetails,
} from '../../../shared/tables/pagination/pagination';
import { MarginRightIcon } from '../../../shared/StyledIcons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InfoBox } from '../../../shared/info/InfoBox';
import { Id } from '../../../models/id';
import { MetadataContext } from '../../authentication/loginData/metadataContext';
import {
  getDispenseFeePercentageDeviationFromDeviationResponse,
  getDispenseFeeValueDeviation,
  getPricePercentageDeviationFromDeviationResponse,
  getPriceValueDeviation,
} from '../deviationCalculations';
import { mapDeviationsResponseToTableRowGroups } from '../deviationUtils';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const CreateDeviationBulkEdit = (props: Props) => {
  const { user } = useContext(UserContext);

  return (
    <RequiresOneOfPermissions permissions={editCreateDeviationBulkEditPermissions.page}>
      <DataLoader apiRequest={getDeviationFilterDropdownOptions}>
        {deviationFilterDropdownOptions => (
          <DataLoader apiRequest={getItemFieldsFromPms}>
            {itemFields => (
              <DataLoaderWithParams
                apiRequest={getAllPracticeGroups}
                getParams={() => user.organisationGroupId}
              >
                {practiceGroupsRequest => (
                  <CreateDeviationBulkEditComponent
                    deviationFilterDropdownOptions={deviationFilterDropdownOptions.response}
                    itemFieldsFromPms={itemFields.response}
                    practiceGroups={practiceGroupsRequest.response.practiceGroups}
                  />
                )}
              </DataLoaderWithParams>
            )}
          </DataLoader>
        )}
      </DataLoader>
    </RequiresOneOfPermissions>
  );
};

export const multiplePagesInfoBoxTestId = 'multiple-pages-info-box-test-id';

type CreateDeviationBulkEditProps = DeviationsBaseProps;

const CreateDeviationBulkEditComponent = (props: CreateDeviationBulkEditProps) => {
  const storedPageSize = localStorage.getItem(deviationPageSizeStorage);
  const pageSize = storedPageSize ? +storedPageSize : deviationsPageSize;
  const { t } = useTranslation(['deviations', 'navigateAwayMessages']);
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);
  const [
    scheduledBulkEditIdBySiteIdThenItemId,
    setScheduledBulkEditIdBySiteIdThenItemId,
  ] = useState<TupleKeyDictionary<Id, Id, Id> | null>(null);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetails | null>(null);

  useNavigateAwayWarning({
    shouldConfirmNavigation: true,
    message: t('navigateAwayMessages:bulkEditForm'),
  });

  const columnDropdownOptions: DropdownOptions<DeviationColumnTypes> = useMemo(
    () => [{ displayText: t('columnHeaders.itemCode'), value: 'itemCode' }],
    [user],
  );

  const { getFilteredData, onFilteredResponse } = useFiltering({
    getData: getDeviations,
    initialUrlState: filterToUrl(initialDeviationFilterOptions),
    urlStateToFilterOptions: urlToFilter(props.itemFieldsFromPms),
    filterOptionsToUrlState: filterToUrl,
    pageName: 'deviations',
  });

  const { onOptionSelect, selectedOptions, deselectedValues } = useMultiSelectDropdown<
    DeviationColumnDropdownType,
    DeviationColumnTypes
  >({
    validOptions: columnDropdownOptions,
    initiallySelected: new Array<DeviationColumnDropdownType>(),
    storeInUrl: true,
  });

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const discountCategoryId = filter(
    props.itemFieldsFromPms.category1Options,
    m => m.name == 'Discount',
  )[0].id;

  const columns: Array<CustomColumn<
    GroupedDeviationResponseRow,
    DeviationColumnTypes
  >> = useMemo(
    () =>
      getCreateDeviationBulkEditColumns(
        t,
        user,
        scheduledBulkEditIdBySiteIdThenItemId,
        practiceGroupsById,
        countries,
        discountCategoryId,
      ),
    [t, user, scheduledBulkEditIdBySiteIdThenItemId, practiceGroupsById],
  );

  const onDataFetchSuccess = (response: DeviationsResponse) => {
    onFilteredResponse(response);

    const { pageNumber, pageSize, totalItems } = response;
    setPaginationDetails(
      getPaginationDetailsFromPagedResponse({ pageNumber, pageSize, totalItems }),
    );

    const scheduledBulkEditIds = new TupleKeyDictionary<Id, Id, Id>();

    forEach(
      response.deviationResponsesAndScheduledBulkEditId.scheduledBulkEditIdWithSiteAndItemIds,
      schedule =>
        scheduledBulkEditIds.add(
          schedule.siteId,
          schedule.itemId,
          schedule.scheduledDeviationBulkEditId,
        ),
    );

    setScheduledBulkEditIdBySiteIdThenItemId(scheduledBulkEditIds);
  };

  const getAbsoluteDispenseFeeDeviationValue = (deviation: DeviationResponse) => {
    let result: string | null = null;
    if (!deviation.dispenseFeeDeviationIsSetByPercent) {
      if (deviation.itemDeviationDispenseFeeOverride) {
        result = formatNumberForFields(
          user.useGrossPrices
            ? deviation.dispenseFeeDeviationAbsoluteValueGross
            : deviation.dispenseFeeDeviationAbsoluteValueNet,
          user.locale,
        );
      } else {
        if (
          !deviation.defaultDispenseFeeDeviationIsSetByPercent &&
          deviation.defaultDispenseFeeDeviationAbsoluteValueGross != 0
        ) {
          result = formatNumberForFields(
            user.useGrossPrices
              ? deviation.defaultDispenseFeeDeviationAbsoluteValueGross
              : deviation.defaultDispenseFeeDeviationAbsoluteValueNet,
            user.locale,
          );
        } else {
          result = formatNumberForFields(
            user.useGrossPrices
              ? deviation.dispenseFeeDeviationAbsoluteValueGross
              : deviation.dispenseFeeDeviationAbsoluteValueNet,
            user.locale,
          );
        }
      }
    }
    return result;
  };

  const getAbsolutePriceDeviationValue = (deviation: DeviationResponse) => {
    let result: string | null = null;
    if (!deviation.priceDeviationIsSetByPercent) {
      if (deviation.itemDeviationPriceOverride) {
        result = formatNumberForFields(
          user.useGrossPrices
            ? deviation.priceDeviationAbsoluteValueGross
            : deviation.priceDeviationAbsoluteValueNet,
          user.locale,
        );
      } else {
        if (
          !deviation.defaultPriceDeviationIsSetByPercent &&
          deviation.defaultPriceDeviationAbsoluteValueGross != 0
        ) {
          result = formatNumberForFields(
            user.useGrossPrices
              ? deviation.defaultPriceDeviationAbsoluteValueGross
              : deviation.defaultPriceDeviationAbsoluteValueNet,
            user.locale,
          );
        } else {
          result = formatNumberForFields(
            user.useGrossPrices
              ? deviation.priceDeviationAbsoluteValueGross
              : deviation.priceDeviationAbsoluteValueNet,
            user.locale,
          );
        }
      }
    }
    return result;
  };

  const apiDataToFormModel = useCallback(
    (response: DeviationsResponse | null): CreateDeviationBulkEditFormModel => {
      if (response === null) {
        return {
          name: null,
          runImmediately: true,
          scheduledDate: getLocalNow(user.locale).toISODate(),
          deviations: {},
        };
      }

      const formModel: CreateDeviationBulkEditFormModel = {
        name: null,
        runImmediately: true,
        scheduledDate: getLocalNow(user.locale).toISODate(),
        deviations: {},
      };

      forEach(response.deviationResponsesAndScheduledBulkEditId.deviationResponses, deviation => {
        if (
          scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(deviation.siteId, deviation.itemId)
        ) {
          return;
        }

        if (formModel.deviations[deviation.siteId] === undefined) {
          formModel.deviations[deviation.siteId] = {};
        }

        formModel.deviations[deviation.siteId][deviation.itemId] = {
          itemType: deviation.itemType,
          treatmentTypeDeviationId: deviation.treatmentTypeDeviationId,
          deviationId: deviation.deviationId,
          isHidden: deviation.isHidden,
          absolutePriceDeviation: getAbsolutePriceDeviationValue(deviation),
          percentagePriceDeviation: getPricePercentageDeviationFromDeviationResponse(
            deviation,
          ).toString(),
          deviatedPrice: getPriceValueDeviation(deviation, user.useGrossPrices).toString(),
          absoluteDispenseFeeDeviation: getAbsoluteDispenseFeeDeviationValue(deviation),
          percentageDispenseFeeDeviation: getDispenseFeePercentageDeviationFromDeviationResponse(
            deviation,
          ).toString(),
          deviatedDispenseFee: getDispenseFeeValueDeviation(
            deviation,
            user.useGrossPrices,
          ).toString(),
          itemDeviationPriceOverride: deviation.itemDeviationPriceOverride,
          itemDeviationDispenseFeeOverride: deviation.itemDeviationDispenseFeeOverride,
          overwrite: deviation.overwrite,
        };
      });

      return formModel;
    },
    [user],
  );

  const mapDeviationFormModelToBulkEditRequest = (
    values: CreateDeviationBulkEditFormModel,
  ): CreateDeviationBulkEditCommand => {
    return {
      name: values.name ?? '',
      runImmediately: values.runImmediately,
      scheduledDate: values.scheduledDate,
      deviations: flatMap(values.deviations, (siteItems, siteId) =>
        map(
          siteItems,
          (value, itemId): DeviationBulkEditCommandLine => ({
            deviationId: value.deviationId,
            treatmentTypeDeviationId: value.treatmentTypeDeviationId,
            itemType: value.itemType,
            itemId: Number(itemId),
            siteId: Number(siteId),
            isHidden: value.isHidden,
            absolutePriceDeviation:
              value.absolutePriceDeviation !== null
                ? parseNumber(value.absolutePriceDeviation, user.locale)
                : null,
            percentagePriceDeviation:
              value.percentagePriceDeviation !== null
                ? parseNumber(value.percentagePriceDeviation, user.locale)
                : null,
            priceDeviationIsSetByPercentage: value.percentagePriceDeviation !== null,

            absoluteDispenseFeeDeviation:
              value.absoluteDispenseFeeDeviation !== null
                ? parseNumber(value.absoluteDispenseFeeDeviation, user.locale)
                : null,
            percentageDispenseFeeDeviation:
              value.percentageDispenseFeeDeviation !== null
                ? parseNumber(value.percentageDispenseFeeDeviation, user.locale)
                : null,
            dispenseFeeDeviationIsSetByPercentage: value.percentageDispenseFeeDeviation !== null,
            itemDeviationPriceOverride: value.itemDeviationPriceOverride,
            itemDeviationDispenseFeeOverride: value.itemDeviationDispenseFeeOverride,
            overwrite: value.overwrite,
          }),
        ),
      ),
    };
  };

  const validateBulkEditTable = (values: CreateDeviationBulkEditFormModel) => {
    const validator = new CreateDeviationBulkEditValidator(t, user.locale);
    const deviationsErrorsIds: Array<number> = [];

    const topLevelErrors = validator.validate(values);
    const perDeviationValidator = new DeviationBulkEditLineValidator(t, user.locale);
    const deviationsErrors = mapValues(values.deviations, items =>
      mapValues(items, value => {
        const validationResponse = perDeviationValidator.validate(value);

        if (Object.keys(validationResponse).length > 0 && value.deviationId) {
          deviationsErrorsIds.push(value.deviationId);
        }
        return validationResponse;
      }),
    );

    if (
      some(deviationsErrors, errorsBySite => some(errorsBySite, rowErrors => some(rowErrors))) ||
      some(topLevelErrors)
    ) {
      return {
        ...topLevelErrors,
        deviations: deviationsErrors,
        deviationErrorIds: deviationsErrorsIds,
      };
    }

    return {};
  };

  return (
    <>
      <Title>{t('createDeviationBulkEdit.title')}</Title>
      <h1>{t('createDeviationBulkEdit.heading')}</h1>
      {scheduledBulkEditIdBySiteIdThenItemId &&
        some(scheduledBulkEditIdBySiteIdThenItemId.dictionary) && (
          <DeviationsScheduledForUpdateInfoBar
            message={t('createDeviationBulkEdit.scheduledBulkEdit')}
          />
        )}
      {!!paginationDetails && paginationDetails.totalPages > 1 && (
        <InfoBox
          testId={multiplePagesInfoBoxTestId}
          message={
            <>
              <MarginRightIcon icon={faInfoCircle} />
              {t('createDeviationBulkEdit.multiplePages', {
                page: formatNumber(paginationDetails.pageNumber, user.locale),
                totalPages: formatNumber(paginationDetails.totalPages, user.locale),
              })}
            </>
          }
        />
      )}
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<DeviationColumnDropdownType>
          label={t('createDeviationBulkEdit.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderDeviationToggleButton}
        />
        <HollowLinkButton to={deviationsUrl(window.location.search)}>
          {t('createDeviationBulkEdit.returnToList')}
        </HollowLinkButton>
      </SpaceBetweenButtonGroup>
      <FormikTable
        additionalWarningText={t('createDeviationBulkEdit.hideUnhideWarning')}
        validate={validateBulkEditTable}
        enableReinitialize={true}
        allowPagination={false}
        disableFormOnSuccess={true}
        apiDataToFormModel={apiDataToFormModel}
        submitRequest={createDeviationBulkEdit}
        mapValuesToRequestParameters={mapDeviationFormModelToBulkEditRequest}
        columns={columns}
        hiddenColumnNames={deselectedValues}
        pageSize={pageSize}
        getApiData={getFilteredData}
        mapResponseToTableData={mapDeviationsResponseToTableRowGroups}
        getSubRows={getSubRows}
        emptyTableMessage={t('createDeviationBulkEdit.emptyTableMessage')}
        validColumnTypeOrUndefined={validDeviationColumnTypeOrUndefined}
        onDataFetchSuccess={onDataFetchSuccess}
        onSubmitComplete={() => {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { successfulDeviationBulkEdit: true },
          });
        }}
        additionalFormFields={formikBag => (
          <>
            <CheckboxField
              name="runImmediately"
              label={t('createDeviationBulkEdit.runImmediately')}
            />

            <InputField
              name="name"
              placeholder={t('createDeviationBulkEdit.optionalDesciption')}
              label={t('createDeviationBulkEdit.bulkEditName')}
              useDebouncing={true}
            />

            {!formikBag.values?.runImmediately && (
              <DateField
                name="scheduledDate"
                label={t('createDeviationBulkEdit.scheduledDate.label')}
                infoText={t('createDeviationBulkEdit.scheduledDate.infoText')}
                useDebouncing={true}
              />
            )}
          </>
        )}
      />
    </>
  );
};
