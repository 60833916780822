import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderGroup, Row, useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import { ThemeContext } from 'styled-components';
import { Theme } from '../../styling/theme';
import { LoadingSpinner } from '../buttons/Button';
import { InfoBox } from '../info/InfoBox';
import { PaginationControls } from './pagination/PaginationControls';
import { PaginationJumpToPage } from './pagination/PaginationJumpToPage';
import {
  GroupedHeaderTh,
  LoadingOverlay,
  StyledErrorBox,
  StyledHeader,
  StyledRow,
  StyledSortIndicator,
  StyledTable,
  StyledTd,
  StyledTh,
  TableContainer,
} from './Table.styles';
import {
  DataFetchTableProps,
  FormikErrorsDeviationIds,
  RowWithDeviations,
  TableDataFetchResponse,
  TableProps,
} from './Table.types';
import { useManualTableHandling } from './useManualTableHandling';

const getHeaderGroupsRow = <TRowData extends object>(
  headerGroups: Array<HeaderGroup<TRowData>>,
): React.ReactNode => {
  const headers = headerGroups[0].headers;

  if (!headers.some(h => !!h.headerGroupName)) {
    return null;
  }

  let currentGroupIndex = 0;
  const groups: Array<{ name: string | undefined; count: number }> = [];

  headers.forEach(header => {
    if (!groups.length) {
      groups[currentGroupIndex] = { name: header.headerGroupName, count: 1 };
    } else if (groups[currentGroupIndex].name === header.headerGroupName) {
      groups[currentGroupIndex].count++;
    } else {
      currentGroupIndex++;
      groups[currentGroupIndex] = { name: header.headerGroupName, count: 1 };
    }
  });

  return (
    <tr>
      {groups.map((group, index) => (
        <GroupedHeaderTh key={index} colSpan={group.count}>
          {group.name}
        </GroupedHeaderTh>
      ))}
    </tr>
  );
};

export const DataFetchTable = <
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TColumnNames extends string = string,
  TFormModel = never
>(
  props: DataFetchTableProps<TApiData, TRowData, TColumnNames, TFormModel>,
) => {
  const { t } = useTranslation('component');
  const {
    pagedApiData,
    pageCount,
    apiPageSize,
    urlSortDirection,
    urlSortByColumn,
    loading,
    canNextPage,
    canPrevPage,
    goToPage,
    totalItems,
    toggleSortBy,
    apiError,
    apiPageNumber,
  } = useManualTableHandling({
    pageSize: props.pageSize,
    dynamicPageSize: props.dynamicPageSize ? props.dynamicPageSize : false,
    getApiData: props.getApiData,
    onDataFetchSuccess: props.onDataFetchSuccess,
    validColumnTypeOrUndefined: props.validColumnTypeOrUndefined,
    shouldRestrictFetching: props.shouldRestrictFetching,
  });

  const tableData = useMemo(
    () => (pagedApiData ? props.mapResponseToTableData(pagedApiData) : []),
    [props.mapResponseToTableData, pagedApiData],
  );

  const { getTableProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<TRowData>(
    {
      columns: props.columns,
      data: tableData,
      defaultColumn: { disableSortBy: true },
      manualPagination: true,
      pageCount,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: props.hiddenColumnNames || [],
      },
      getSubRows: props.getSubRows,
      formikProps: props.formikProps,
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  useEffect(() => {
    setHiddenColumns(props.hiddenColumnNames || []);
  }, [props.hiddenColumnNames]);

  const showPaginationControls = props.allowPagination === undefined || props.allowPagination;
  const themeContext: Theme = useContext(ThemeContext);

  const headerGroupsRow = useMemo(() => getHeaderGroupsRow(headerGroups), [headerGroups]);

  if (pagedApiData && totalItems === 0) {
    return <InfoBox message={props.emptyTableMessage} />;
  }

  function doesRowHaveChildRowWithError(row: Row<TRowData>) {
    const rowDeviations = ((row as unknown) as RowWithDeviations<TRowData>).original.deviations;
    const deviationIdsWithError =
      ((props.formikProps?.errors as unknown) as FormikErrorsDeviationIds).deviationErrorIds || [];
    let doesChildHaveError = false;

    if (rowDeviations && rowDeviations.length > 0) {
      doesChildHaveError = rowDeviations.some(deviation => {
        return deviation.deviationId && deviationIdsWithError.indexOf(deviation.deviationId) > -1;
      });
    }

    if (doesChildHaveError && !row.isExpanded) {
      row.toggleRowExpanded(doesChildHaveError);
    }
    return doesChildHaveError;
  }

  return (
    <TableContainer className={props.className}>
      <LoadingOverlay showLoadingOverlay={loading}>
        <LoadingSpinner colour={themeContext.colours.primary} />
      </LoadingOverlay>
      {showPaginationControls && (
        <PaginationControls
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atTop={true}
        />
      )}
      <StyledTable {...getTableProps()}>
        <StyledHeader disableStickyHeader={props.disableStickyHeader}>
          {headerGroups.map((headerGroup, index) => (
            <React.Fragment key={index}>
              {headerGroupsRow}
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // tslint:disable-next-line:jsx-key (getHeaderProps returns key)
                  <StyledTh
                    isRightAligned={column.isRightAligned}
                    fitContent={column.fitContent}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.canSort ? t('table.toggleSort') : undefined}
                    onClick={column.canSort ? toggleSortBy(column.id) : undefined}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <StyledSortIndicator
                        isSorted={urlSortByColumn === column.id}
                        isDescending={urlSortDirection === 'desc'}
                      />
                    )}
                  </StyledTh>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </StyledHeader>
        <tbody>
          {rows.map((row: Row<TRowData>) => {
            prepareRow(row);

            if (props.formikProps) {
              const doesChildHaveError = doesRowHaveChildRowWithError(row);

              return (
                <StyledRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    // tslint:disable-next-line:jsx-key (getCellProps returns key)
                    <StyledTd
                      {...cell.getCellProps()}
                      canExpand={row.canExpand}
                      hasChildWithError={doesChildHaveError}
                    >
                      {cell.render('Cell')}
                    </StyledTd>
                  ))}
                </StyledRow>
              );
            } else {
              return (
                <StyledRow {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    // tslint:disable-next-line:jsx-key (getCellProps returns key)
                    <StyledTd {...cell.getCellProps()} canExpand={row.canExpand}>
                      {cell.render('Cell')}
                    </StyledTd>
                  ))}
                </StyledRow>
              );
            }
          })}
        </tbody>
      </StyledTable>
      {showPaginationControls && (
        <PaginationControls
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atBottom={true}
        />
      )}
      {showPaginationControls && (
        <PaginationJumpToPage
          pageSize={apiPageSize}
          pageNumber={apiPageNumber}
          totalItems={totalItems}
          totalPages={pageCount}
          loading={loading}
          reactTablePagination={{
            canNextPage,
            canPreviousPage: canPrevPage,
            gotoPage: goToPage,
          }}
          atBottom={true}
        />
      )}
      {apiError && <StyledErrorBox error={apiError} />}
    </TableContainer>
  );
};

export const Table = <TRowData extends object, TColumnNames extends string, TFormModel = never>(
  props: TableProps<TRowData, TColumnNames, TFormModel>,
) => {
  const { t } = useTranslation('component');

  const { getTableProps, headerGroups, rows, prepareRow, setHiddenColumns } = useTable<TRowData>(
    {
      columns: props.columns,
      data: props.data,
      defaultColumn: { disableSortBy: true },
      autoResetPage: false,
      disableMultiSort: true,
      initialState: {
        hiddenColumns: props.hiddenColumnNames || [],
      },
      getSubRows: props.getSubRows,
      formikProps: props.formikProps,
    },
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    setHiddenColumns(props.hiddenColumnNames || []);
  }, [props.hiddenColumnNames]);

  const headerGroupsRow = useMemo(() => getHeaderGroupsRow(headerGroups), [headerGroups]);

  if (props.data.length === 0 && props.emptyTableMessage) {
    return <InfoBox message={props.emptyTableMessage} />;
  }

  return (
    <TableContainer className={props.className}>
      <StyledTable {...getTableProps()}>
        <StyledHeader>
          {headerGroups.map((headerGroup: HeaderGroup<TRowData>, index) => (
            <React.Fragment key={index}>
              {headerGroupsRow}
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // tslint:disable-next-line:jsx-key (getHeaderProps returns key)
                  <StyledTh
                    isRightAligned={column.isRightAligned}
                    fitContent={column.fitContent}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title={column.canSort ? t('table.toggleSort') : undefined}
                  >
                    {column.render('Header')}
                    {column.canSort && (
                      <StyledSortIndicator
                        isSorted={column.isSorted}
                        isDescending={column.isSortedDesc}
                      />
                    )}
                  </StyledTh>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </StyledHeader>
        <tbody>
          {rows.map((row: Row<TRowData>) => {
            prepareRow(row);
            return (
              <StyledRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  // tslint:disable-next-line:jsx-key (getCellProps returns key)
                  <StyledTd {...cell.getCellProps()} canExpand={row.canExpand}>
                    {cell.render('Cell')}
                  </StyledTd>
                ))}
              </StyledRow>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};
