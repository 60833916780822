import { secureGet, securePost } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { FileResponse } from '../../models/fileResponse';
import { Id } from '../../models/id';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { TableDataFetchRequest } from '../../shared/tables/Table.types';
import {
  CreateDeviationBulkEditCommand,
  DeviationBulkEditResponse,
  DeviationBulkEditsResponse,
  EditDeviationBulkEditCommand,
  RecentDeviationBulkEditsResponse,
  UploadDeviationResponse,
} from './bulkEdit/deviationBulkEdit';
import {
  DeviationColumnTypes,
  DeviationFilterDropdownOptionsResponse,
  DeviationFilterOptions,
  DeviationsResponse,
  GetDeviationsCommand,
  GetDeviationsDownloadCommand,
} from './deviation';
import {
  GetTreatmentTypeDeviationsCommand,
  GetTreatmentTypeDeviationsDownloadCommand,
  TreatmentTypeDeviationColumnTypes,
  TreatmentTypeDeviationFilterDropdownOptionsResponse,
  TreatmentTypeDeviationFilterOptions,
  TreatmentTypeDeviationsResponse,
} from './treatmentTypeDeviations/treatmentTypeDeviations';
import {
  CreateTreatmentTypeDeviationBulkEditCommand,
  EditTreatmentTypeDeviationBulkEditCommand,
  RecentTreatmentTypeDeviationBulkEditsResponse,
  TreatmentTypeDeviationBulkEditResponse,
  TreatmentTypeDeviationBulkEditsResponse,
  UploadTreatmentTypeDeviationResponse,
} from './treatmentTypeDeviations/bulkEdit/treatmentTypeDeviationBulkEdit';
import { SortResponse } from '../../shared/tables/sorting/sorting';
import { DashboardFilterSelection } from '../dashboard/models/DashboardFilterSelection';

export const getDeviations = (
  filterOptions?: DeviationFilterOptions,
): AuthenticatedApiRequest<
  DeviationsResponse,
  TableDataFetchRequest<DeviationColumnTypes>
> => fetchRequest =>
  securePost<DeviationsResponse, GetDeviationsCommand>('deviations', {
    ...fetchRequest,
    filterOptions,
  });

export const getDeviationsDownload = (
  filterOptions?: DeviationFilterOptions,
): AuthenticatedApiRequest<FileResponse, SortResponse<DeviationColumnTypes>> => sortResponse =>
  securePost<FileResponse, GetDeviationsDownloadCommand>('deviations/download', {
    filterOptions,
    ...sortResponse,
  });

export const getDeviationFilterDropdownOptions: AuthenticatedApiRequest<
  DeviationFilterDropdownOptionsResponse,
  void
> = () => secureGet('deviations/deviationFilterDropdownOptions');

export const createDeviationBulkEdit: AuthenticatedApiRequest<
  void,
  CreateDeviationBulkEditCommand
> = deviations => {
  return securePost('deviations/bulk-edit/create', deviations);
};

export const editDeviationBulkEdit: AuthenticatedApiRequest<
  void,
  EditDeviationBulkEditCommand
> = deviations => securePost('deviations/bulk-edit/edit', deviations);

export const getDeviationBulkEdits: AuthenticatedApiRequest<
  DeviationBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`deviations/upcoming-bulk-edits`, filter);
};

export const getDeviationBulkEdit = (
  deviationBulkEditId: Id,
): AuthenticatedApiRequest<DeviationBulkEditResponse, PagedRequest> => pagedCommand =>
  securePost(`deviations/bulk-edit`, { ...pagedCommand, deviationBulkEditId });

export const cancelDeviationBulkEdit: AuthenticatedApiRequest<void, Id> = deviationBulkEditId =>
  securePost(`deviations/bulk-edit/${deviationBulkEditId}/cancel`, {});

export const getRecentDeviationBulkEdits: AuthenticatedApiRequest<
  RecentDeviationBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`deviations/bulk-edit/recent`, filter);
};

export const uploadDeviationBulkEdit: AuthenticatedApiRequest<UploadDeviationResponse, FormData> = (
  data: FormData,
) => securePost('deviations/bulk-edit/upload', data);

export const getTreatmentTypeDeviations = (
  filterOptions?: TreatmentTypeDeviationFilterOptions,
): AuthenticatedApiRequest<
  TreatmentTypeDeviationsResponse,
  TableDataFetchRequest<TreatmentTypeDeviationColumnTypes>
> => fetchRequest =>
  securePost<TreatmentTypeDeviationsResponse, GetTreatmentTypeDeviationsCommand>(
    'deviations/treatment-types',
    {
      ...fetchRequest,
      filterOptions,
    },
  );

export const getTreatmentTypeDeviationFilterDropdownOptions: AuthenticatedApiRequest<
  TreatmentTypeDeviationFilterDropdownOptionsResponse,
  void
> = () => secureGet('deviations/treatment-types/filter-dropdown-options');

export const getTreatmentTypeDeviationsDownload = (
  filterOptions: TreatmentTypeDeviationFilterOptions,
): AuthenticatedApiRequest<
  FileResponse,
  SortResponse<TreatmentTypeDeviationColumnTypes>
> => sortResponse =>
  securePost<FileResponse, GetTreatmentTypeDeviationsDownloadCommand>(
    'deviations/treatment-types/download',
    {
      filterOptions,
      ...sortResponse,
    },
  );

export const createTreatmentTypeDeviationBulkEdit: AuthenticatedApiRequest<
  void,
  CreateTreatmentTypeDeviationBulkEditCommand
> = deviations => securePost('deviations/treatment-types/bulk-edit/create', deviations);

export const uploadTreatmentTypeDeviationBulkEdit: AuthenticatedApiRequest<
  UploadTreatmentTypeDeviationResponse,
  FormData
> = (data: FormData) => securePost('deviations/treatment-types/bulk-edit/upload', data);

export const getTreatmentTypeDeviationBulkEdits: AuthenticatedApiRequest<
  TreatmentTypeDeviationBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`deviations/treatment-types/upcoming-bulk-edits`, filter);
};

export const getTreatmentTypeDeviationBulkEdit = (
  treatmentTypeDeviationBulkEditId: Id,
): AuthenticatedApiRequest<TreatmentTypeDeviationBulkEditResponse, PagedRequest> => pagedCommand =>
  securePost(`deviations/treatment-types/bulk-edit`, {
    ...pagedCommand,
    treatmentTypeDeviationBulkEditId,
  });

export const getRecentTreatmentTypeDeviationBulkEdits: AuthenticatedApiRequest<
  RecentTreatmentTypeDeviationBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`deviations/treatment-types/bulk-edit/recent`, filter);
};

export const editTreatmentTypeDeviationBulkEdit: AuthenticatedApiRequest<
  void,
  EditTreatmentTypeDeviationBulkEditCommand
> = command => securePost('deviations/treatment-types/bulk-edit/edit', command);

export const cancelTreatmentTypeDeviationBulkEdit: AuthenticatedApiRequest<
  void,
  Id
> = treatmentTypeDeviationBulkEditId =>
  securePost(`deviations/treatment-types/bulk-edit/${treatmentTypeDeviationBulkEditId}/cancel`, {});
