import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CustomColumn } from '../../../../shared/tables/Table.types';
import { UserContext } from '../../../authentication/loginData/userContext';
import { DataLoaderWithParams } from '../../../../shared/DataLoader';
import { getAllPracticeGroups } from '../../../sites/practiceGroups/practiceGroupsApi';
import { RouteComponentProps } from '@reach/router';
import { PracticeGroupResponse } from '../../../sites/practiceGroups/practiceGroup';
import { useTranslation } from 'react-i18next';
import {
  cancelTreatmentTypeDeviationBulkEdit,
  getTreatmentTypeDeviationBulkEdit,
} from '../../deviationsApi';
import { useApiRequest } from '../../../../shared/hooks/useApiRequest';
import { navigate } from '../../../../utils/routing';
import { dashboardUrl } from '../../../../urls';
import {
  createEditTreatmentTypeDeviationsPermissions,
  TreatmentTypeDeviationBulkEditLineResponse,
  TreatmentTypeDeviationBulkEditResponse,
} from './treatmentTypeDeviationBulkEdit';
import { keyBy, round } from 'lodash';
import { DataFetchTable } from '../../../../shared/tables/Table';
import {
  GroupedTreatmentTypeDeviationBulkEditRow,
  TreatmentTypeDeviationColumnTypes,
  treatmentTypeDeviationsPageSize,
} from '../treatmentTypeDeviations';
import { TFunction } from 'i18next';
import { LoginUserResponse } from '../../../authentication/loginData/user';
import { Title } from 'react-head';
import { PageHeading } from '../../../layout/PageHeading';
import { AlertButton } from '../../../../shared/buttons/Button';
import { IfUserHasOneOfPermissions } from '../../../../shared/Permissions';
import { ApiErrorBox } from '../../../../shared/errors/ApiErrorBox';
import { HollowLinkButton } from '../../../../shared/buttons/LinkButton';
import { editTreatmentTypeDeviationBulkEditUrl } from '../treatmentTypeDeviationsUrls';
import { styled } from '../../../../styling/theme';
import { FieldAndValue } from '../../../../shared/FieldAndValue';
import {
  groupedWarningsColumnForViewPages,
  treatmentTypeDeviationExpandedColumn,
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn,
  treatmentTypeDeviationTreatmentTypeNameColumn,
} from '../SharedTreatmentTypeDeviationBulkEditColumns';
import { CellProps, Row } from 'react-table';
import { AlignedSpan, indeterminateGroupedCellValue } from '../../DeviationColumns';
import { ChangeCell } from '../../../../shared/tables/ChangeTableComponents';
import { getUniqueValueOrDefault } from '../../../../utils/arrayUtils';
import {
  getGroupedTreatmentTypeDeviationSubRows,
  mapTreatmentTypeDeviationsBulkEditResponseToGroupedTableData,
} from '../TreatmentTypeDeviationsBase';

type OwnProps = {} & RouteComponentProps<{
  treatmentTypeDeviationBulkEditId: string;
}>;

type ViewTreatmentTypeDeviationBulkEditProps = OwnProps & {
  practiceGroups: Array<PracticeGroupResponse>;
};

const ViewTreatmentTypeDeviationBulkEditComponent = (
  props: ViewTreatmentTypeDeviationBulkEditProps,
) => {
  const { t } = useTranslation('treatmentTypeDeviations');
  const { user } = useContext(UserContext);

  const getTreatmentTypeDeviationBulkEditCallback = useCallback(
    getTreatmentTypeDeviationBulkEdit(Number(props.treatmentTypeDeviationBulkEditId)),
    [props.treatmentTypeDeviationBulkEditId],
  );

  const {
    makeRequest: makeCancellationRequest,
    apiError: cancellationRequestApiError,
    inProgress: cancellationRequestInProgress,
  } = useApiRequest(cancelTreatmentTypeDeviationBulkEdit);
  const onCancelButtonClick = () => {
    if (window.confirm(t('viewBulkEdit.confirmCancelBulkEdit'))) {
      makeCancellationRequest(Number(props.treatmentTypeDeviationBulkEditId)).then(result => {
        if (result) {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { cancelledTreatmentTypeDeviationBulkEdit: true },
          });
        }
      });
    }
  };

  const [
    treatmentTypeDeviationBulkEditResponse,
    setTreatmentTypeDeviationBulkEditResponse,
  ] = useState<TreatmentTypeDeviationBulkEditResponse | null>(null);

  const onDataFetchSuccess = (response: TreatmentTypeDeviationBulkEditResponse) => {
    setTreatmentTypeDeviationBulkEditResponse(response);
  };

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedTreatmentTypeDeviationBulkEditRow,
    TreatmentTypeDeviationColumnTypes
  >> = useMemo(() => getColumns(t, user, practiceGroupsById), [t, user, practiceGroupsById]);

  return (
    <>
      <Title>{t('viewBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('viewBulkEdit.heading')}</h1>
        {treatmentTypeDeviationBulkEditResponse &&
          treatmentTypeDeviationBulkEditResponse.isCsvUpload &&
          treatmentTypeDeviationBulkEditResponse.canAccessAllSitesOnBulkEdit && (
            <IfUserHasOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
              <AlertButton onClick={onCancelButtonClick} loading={cancellationRequestInProgress}>
                {t('viewBulkEdit.cancel')}
              </AlertButton>
            </IfUserHasOneOfPermissions>
          )}
      </PageHeading>
      {treatmentTypeDeviationBulkEditResponse && (
        <>
          <FieldAndValue
            fieldName={t('bulkEdit.scheduledDate')}
            value={treatmentTypeDeviationBulkEditResponse.scheduledDate}
            type="date"
          />
          <FieldAndValue
            fieldName={t('bulkEdit.runImmediately')}
            value={treatmentTypeDeviationBulkEditResponse.runImmediately}
            type="boolean"
          />
          <FieldAndValue
            fieldName={t('bulkEdit.name')}
            value={treatmentTypeDeviationBulkEditResponse.name}
            type="text"
          />
          <FieldAndValue
            fieldName={t('bulkEdit.creatorsName')}
            value={treatmentTypeDeviationBulkEditResponse.creatorsName}
            type="text"
          />
          <FieldAndValue
            fieldName={t('viewBulkEdit.isCsvUpload')}
            value={treatmentTypeDeviationBulkEditResponse.isCsvUpload}
            type="boolean"
          />
        </>
      )}
      <EditButtonContainer>
        {treatmentTypeDeviationBulkEditResponse &&
          treatmentTypeDeviationBulkEditResponse.canAccessAllSitesOnBulkEdit &&
          !treatmentTypeDeviationBulkEditResponse.isCsvUpload && (
            <IfUserHasOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
              <HollowLinkButton
                to={editTreatmentTypeDeviationBulkEditUrl(
                  treatmentTypeDeviationBulkEditResponse.treatmentTypeDeviationBulkEditId,
                )}
              >
                {t('viewBulkEdit.editBulkEditButton')}
              </HollowLinkButton>
            </IfUserHasOneOfPermissions>
          )}
      </EditButtonContainer>
      {cancellationRequestApiError && <ApiErrorBox error={cancellationRequestApiError} />}
      <DataFetchTable
        columns={columns}
        pageSize={treatmentTypeDeviationsPageSize}
        getApiData={getTreatmentTypeDeviationBulkEditCallback}
        mapResponseToTableData={mapTreatmentTypeDeviationsBulkEditResponseToGroupedTableData}
        getSubRows={getGroupedTreatmentTypeDeviationSubRows}
        emptyTableMessage={t('viewBulkEdit.noDeviations')}
        onDataFetchSuccess={onDataFetchSuccess}
      />
    </>
  );
};

const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const getColumns = (
  t: TFunction,
  user: LoginUserResponse,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): Array<CustomColumn<
  GroupedTreatmentTypeDeviationBulkEditRow,
  TreatmentTypeDeviationColumnTypes
>> => [
  treatmentTypeDeviationExpandedColumn(),
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn(t, null, practiceGroupsById),
  treatmentTypeDeviationTreatmentTypeNameColumn(t),
  {
    id: 'priceDeviationPercentValue',
    Header: t<string>('columnHeaders.priceDeviationPercentValue'),
    accessor: row => row.percentagePriceDeviationTo,
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedTreatmentTypeDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue = getGroupedTreatmentTypeDeviationBulkEditCellValue<number>(
          row,
          'percentagePriceDeviationTo',
          undefined,
        );
        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : getRoundedPercentValue(groupedCellValue)}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangeCell
            oldValue={getRoundedPercentValue(cell.row.original.percentagePriceDeviationFrom)}
            newValue={getRoundedPercentValue(cell.row.original.percentagePriceDeviationTo)}
            floatRight={true}
          />
        );
      }
    },
  },
  {
    id: 'dispenseFeeDeviationPercentValue',
    Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
    accessor: row => row.percentageDispenseFeeDeviationTo,
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedTreatmentTypeDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue = getGroupedTreatmentTypeDeviationBulkEditCellValue<number>(
          row,
          'percentageDispenseFeeDeviationTo',
          undefined,
        );
        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : getRoundedPercentValue(groupedCellValue)}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangeCell
            oldValue={getRoundedPercentValue(cell.row.original.percentageDispenseFeeDeviationFrom)}
            newValue={getRoundedPercentValue(cell.row.original.percentageDispenseFeeDeviationTo)}
            floatRight={true}
          />
        );
      }
    },
  },
  groupedWarningsColumnForViewPages(t),
];

const getRoundedPercentValue = (percent: number) => round(percent, 4) + '%';

const getGroupedTreatmentTypeDeviationBulkEditCellValue = <
  TValue extends string | number | boolean | undefined
>(
  row: Row<GroupedTreatmentTypeDeviationBulkEditRow>,
  fieldName: keyof TreatmentTypeDeviationBulkEditLineResponse,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(
      deviations,
      deviation => deviation[fieldName] as TValue,
      defaultValue,
    ) ?? defaultValue
  );
};

export const ViewTreatmentTypeDeviationBulkEdit = (props: OwnProps) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoaderWithParams
      apiRequest={getAllPracticeGroups}
      getParams={() => user.organisationGroupId}
    >
      {practiceGroupsRequest => (
        <ViewTreatmentTypeDeviationBulkEditComponent
          {...props}
          practiceGroups={practiceGroupsRequest.response.practiceGroups}
        />
      )}
    </DataLoaderWithParams>
  );
};
