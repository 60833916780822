import { TFunction } from 'i18next';
import * as React from 'react';
import { Table } from '../../../shared/tables/Table';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { styled } from '../../../styling/theme';
import { DiscountCategory } from '../item';

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.extraTiny}px;
`;

type ItemDiscountCategoriesProps = {
  discountCategory: Array<DiscountCategory> | null | undefined;
  t: TFunction;
};

export const ItemDiscountCategories = (props: ItemDiscountCategoriesProps) => {
  const columns: Array<CustomColumn<DiscountCategory>> = [
    {
      Header: props.t<string>('itemList.tableHeadings.discountCategoryDescription'),
      accessor: (row: DiscountCategory) => row.optionName,
      id: 'description',
    },
    {
      Header: props.t<string>('itemList.tableHeadings.discountCategoryAmount'),
      accessor: (row: DiscountCategory) => row.amount,
      id: 'amount',
    },
  ];

  return (
    <Container>
      <Title>{props.t('itemList.tableHeadings.discountCategoryTableTitle')}</Title>
      {props.discountCategory && (
        <Table
          columns={columns}
          data={props.discountCategory.sort((x, y) => x.optionName.localeCompare(y.optionName))}
          emptyTableMessage={props.t('itemList.emptydiscountCategoryTableMessage')}
        />
      )}
    </Container>
  );
};
