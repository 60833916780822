import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';
import { ButtonGroup, HollowButton } from '../../shared/buttons/Button';
import { HollowLinkButton } from '../../shared/buttons/LinkButton';
import { DataLoader } from '../../shared/DataLoader';
import { ApiErrorBox } from '../../shared/errors/ApiErrorBox';
import { useApiRequest } from '../../shared/hooks/useApiRequest';
import { Link } from '../../shared/navigation/Link';
import {
  HasOneOfPermissions,
  IfUserHasOneOfPermissions,
  RequiresOneOfPermissions,
} from '../../shared/Permissions';
import { shouldShowSuccess, SuccessBox } from '../../shared/success/SuccessBox';
import { Table } from '../../shared/tables/Table';
import { CustomColumn } from '../../shared/tables/Table.types';
import { styled } from '../../styling/theme';
import { permissions } from '../authentication/permissions';
import { PageHeading } from '../layout/PageHeading';
import { SupplierResponse } from './supplier';
import {
  getSupplierListItemsDownloadUrl,
  uploadSupplierListUrl,
} from './supplier-list/supplierListUrls';
import { getSuppliers, syncSuppliersFromPms } from './supplierApi';
import { editSupplierUrl } from './supplierUrls';
import { DateTime } from 'luxon';

const FullWidthTableContainer = styled.div`
  width: 100%;
`;

const CellContainer = styled.div`
  min-width: 300px;
  overflow-wrap: break-word;
`;

const NameCell = ({ cell }: CellProps<SupplierResponse>) => (
  <CellContainer>
    <HasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
      {hasPermission => {
        if (hasPermission) {
          return <Link to={editSupplierUrl(cell.row.original.supplierId)}>{cell.value}</Link>;
        } else {
          return cell.value;
        }
      }}
    </HasOneOfPermissions>
  </CellContainer>
);

export const ViewSuppliersList = ({}: RouteComponentProps) => {
  const { t } = useTranslation('supplier');
  const { makeRequest, apiError, inProgress } = useApiRequest(syncSuppliersFromPms);
  const [tableKey, setTableKey] = useState(1);

  const columns: Array<CustomColumn<SupplierResponse>> = [
    {
      Header: t<string>('suppliersList.tableHeadings.name'),
      accessor: (row: SupplierResponse) => row.supplierName,
      id: 'supplierName',
      Cell: NameCell,
    },
    {
      Header: t<string>('suppliersList.tableHeadings.lastUpdated'),
      accessor: (row: SupplierResponse) => {
        // Handle the case where is null i.e. no matching record in look-up table
        if (!row.uploadDate) {
          return '';
        }

        // The uploadDate is received in SQL format, with timezone offset, 2024-01-01 12:00:00.0000000 +00:00
        // n.b. setZone uses the offset from the date string to set the timezone, otherwise it defaults to US
        return DateTime.fromSQL(row.uploadDate, { setZone: true }).toFormat(
          'dd/MM/yyyy - HH:mm:ss',
        );
      },
      id: 'lastUpdated',
      Cell: NameCell,
    },
  ];

  const onSyncButtonClick = () => {
    makeRequest().then(() => {
      if (apiError === null) {
        setTableKey(tableKey + 1); // Modifying the table key to handle a soft refresh after syncing
      }
    });
  };

  return (
    <RequiresOneOfPermissions
      permissions={[permissions.SuperAdmin, permissions.CentralAdmin, permissions.CentralView]}
    >
      <Title>{t('suppliersList.title')}</Title>
      <PageHeading>
        <h1>{t('suppliersList.heading')}</h1>
        <IfUserHasOneOfPermissions permissions={[permissions.SuperAdmin, permissions.CentralAdmin]}>
          <ButtonGroup>
            <HollowButton onClick={onSyncButtonClick} loading={inProgress}>
              {t('suppliersList.syncSuppliersButton')}
            </HollowButton>
            <HollowLinkButton to={uploadSupplierListUrl()}>
              {t('suppliersList.uploadSupplierListButton')}
            </HollowLinkButton>
            <HollowLinkButton to={getSupplierListItemsDownloadUrl()}>
              {t('suppliersList.getSupplierListItemsDownload')}
            </HollowLinkButton>
          </ButtonGroup>
        </IfUserHasOneOfPermissions>
      </PageHeading>
      {apiError && <ApiErrorBox error={apiError} />}
      {shouldShowSuccess() && (
        <SuccessBox noMinHeight={true} message={t('suppliersList.successfulArchival')} />
      )}
      <FullWidthTableContainer>
        <DataLoader apiRequest={getSuppliers}>
          {suppliers => (
            <Table
              columns={columns}
              data={suppliers.response.suppliers}
              emptyTableMessage={t('suppliersList.emptyTableMessage')}
            />
          )}
        </DataLoader>
      </FullWidthTableContainer>
    </RequiresOneOfPermissions>
  );
};
