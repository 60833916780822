import { RouteComponentProps } from '@reach/router';
import { ControllerStateAndHelpers } from 'downshift';
import { keyBy, round } from 'lodash';
import * as React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Title } from 'react-head';
import { useTranslation } from 'react-i18next';
import { CellProps, Row } from 'react-table';
import { AlertButton, ButtonLabel, HollowButton } from '../../../shared/buttons/Button';
import { HollowLinkButton } from '../../../shared/buttons/LinkButton';
import { DataLoaderWithParams } from '../../../shared/DataLoader';
import {
  MultiSelectDropdown,
  MultiSelectDropdownProps,
} from '../../../shared/dropdowns/MultiSelectDropdown';
import { useMultiSelectDropdown } from '../../../shared/dropdowns/useMultiSelectDropdown';
import { ApiErrorBox } from '../../../shared/errors/ApiErrorBox';
import { FieldAndValue } from '../../../shared/FieldAndValue';
import { DropdownOptions } from '../../../shared/forms/DropdownField';
import { IconRow } from '../../../shared/IconRow';
import { useApiRequest } from '../../../shared/hooks/useApiRequest';
import { IfUserHasOneOfPermissions } from '../../../shared/Permissions';
import {
  ChangeBooleanCell,
  ChangeCell,
  ChangePriceCell,
  fieldIsEmptyValue,
} from '../../../shared/tables/ChangeTableComponents';
import { DataFetchTable } from '../../../shared/tables/Table';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { dashboardUrl } from '../../../urls';
import { formatCurrency } from '../../../utils/currencyUtils';
import { navigate } from '../../../utils/routing';
import { UserContext } from '../../authentication/loginData/userContext';
import { permissions } from '../../authentication/permissions';
import { SpaceBetweenButtonGroup } from '../../items/ItemsTables/ItemsBase';
import { PageHeading } from '../../layout/PageHeading';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { getAllPracticeGroups } from '../../sites/practiceGroups/practiceGroupsApi';
import { DeviationColumnTypes, DeviationResponse } from '../deviation';
import {
  AlignedSpan,
  centralItemDispenseFeeColumn,
  centralItemPriceColumn,
  indeterminateGroupedCellValue,
  itemCodeColumn,
  itemNameColumn,
} from '../DeviationColumns';
import { cancelDeviationBulkEdit, getDeviationBulkEdit } from '../deviationsApi';
import {
  HelpText,
  DeviationColumnDropdownType,
  deviationsPageSize,
  getCurrencyForCountry,
} from '../DeviationsBase';
import {
  deviationWarningIconsMapping,
  getNegativeDeviationWarningsFromDeviationResponse,
  getViewPagePracticeGroupWarnings,
} from '../DeviationsWarnings';
import { editDeviationBulkEditUrl } from '../deviationsUrls';
import {
  DeviationBulkEditLineResponse,
  DeviationBulkEditResponse,
  editCreateDeviationBulkEditPermissions,
} from './deviationBulkEdit';
import {
  deviationExpandedColumn,
  getSubRows,
  GroupedDeviationBulkEditRow,
  groupedPracticeGroupOrSiteNameColumn,
  mapDeviationBulkEditResponseToGroupedTableData,
} from './SharedDeviationBulkEditColumns';
import {
  getGrossDeviatedDispenseFeeFrom,
  getGrossDeviatedDispenseFeeTo,
  getGrossDeviatedPriceFrom,
  getGrossDeviatedPriceTo,
  getNetDeviatedDispenseFeeFrom,
  getNetDeviatedDispenseFeeTo,
  getNetDeviatedPriceFrom,
  getNetDeviatedPriceTo,
} from './ViewEditDeviationBulkEditShared';
import { TFunction } from 'i18next';
import { LoginUserResponse } from '../../authentication/loginData/user';
import { getUniqueValueOrDefault } from '../../../utils/arrayUtils';
import { CountryResponse } from '../../authentication/loginData/metadata';
import { MetadataContext } from '../../authentication/loginData/metadataContext';

const renderToggleButton = (
  toggleProps: MultiSelectDropdownProps<DeviationColumnDropdownType>,
  downshift: ControllerStateAndHelpers<DeviationColumnDropdownType>,
) => (
  <HollowButton {...downshift.getToggleButtonProps()}>
    <ButtonLabel {...downshift.getLabelProps()}>{toggleProps.label}</ButtonLabel>
  </HollowButton>
);

const getRoundedDecimalWithPercentOrEmptyFieldValue = (percent: number | null) =>
  percent !== null ? `${round(percent, 4)}%` : fieldIsEmptyValue;

type OwnProps = {} & RouteComponentProps<{
  deviationBulkEditId: string;
}>;

type ViewDeviationBulkEditProps = OwnProps & { practiceGroups: Array<PracticeGroupResponse> };

const ViewDeviationBulkEditComponent = (props: ViewDeviationBulkEditProps) => {
  const { t } = useTranslation('deviations');
  const { user } = useContext(UserContext);
  const { countries } = useContext(MetadataContext);

  const columnDropdownOptions: DropdownOptions<DeviationColumnTypes> = useMemo(
    () => [{ displayText: t('columnHeaders.itemCode'), value: 'itemCode' }],
    [user],
  );

  const { onOptionSelect, selectedOptions, deselectedValues } = useMultiSelectDropdown<
    DeviationColumnDropdownType,
    DeviationColumnTypes
  >({
    validOptions: columnDropdownOptions,
    initiallySelected: new Array<DeviationColumnDropdownType>(),
    storeInUrl: true,
  });

  const getDeviationBulkEditCallback = useCallback(
    getDeviationBulkEdit(Number(props.deviationBulkEditId)),
    [props.deviationBulkEditId],
  );

  const { makeRequest, apiError, inProgress } = useApiRequest(cancelDeviationBulkEdit);
  const onCancelButtonClick = () => {
    if (window.confirm(t('viewDeviationBulkEdit.confirmCancelBulkEdit'))) {
      makeRequest(Number(props.deviationBulkEditId)).then(result => {
        if (result) {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { cancelledDeviationBulkEdit: true },
          });
        }
      });
    }
  };

  const [
    deviationBulkEditResponse,
    setDeviationBulkEditResponse,
  ] = useState<DeviationBulkEditResponse | null>(null);

  const onDataFetchSuccess = (response: DeviationBulkEditResponse) => {
    setDeviationBulkEditResponse(response);
  };

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedDeviationBulkEditRow,
    DeviationColumnTypes
  >> = useMemo(() => getColumns(t, user, practiceGroupsById, countries), [
    user,
    t,
    practiceGroupsById,
  ]);

  return (
    <>
      <Title>{t('viewDeviationBulkEdit.title')}</Title>
      <PageHeading>
        <h1>{t('viewDeviationBulkEdit.heading')}</h1>
        {deviationBulkEditResponse && deviationBulkEditResponse.canAccessAllSitesOnBulkEdit && (
          <IfUserHasOneOfPermissions
            permissions={[
              permissions.SuperAdmin,
              permissions.CentralAdmin,
              permissions.SitePriceAdmin,
            ]}
          >
            <AlertButton onClick={onCancelButtonClick} loading={inProgress}>
              {t('viewDeviationBulkEdit.cancel')}
            </AlertButton>
          </IfUserHasOneOfPermissions>
        )}
      </PageHeading>
      {deviationBulkEditResponse && (
        <>
          <FieldAndValue
            fieldName={t('deviationBulkEdit.scheduledDate')}
            value={deviationBulkEditResponse.scheduledDate}
            type={'date'}
          />
          <FieldAndValue
            fieldName={t('deviationBulkEdit.runImmediately')}
            value={deviationBulkEditResponse.runImmediately}
            type={'boolean'}
          />
          <FieldAndValue
            fieldName={t('deviationBulkEdit.name')}
            value={deviationBulkEditResponse.name}
            type={'text'}
          />
          <FieldAndValue
            fieldName={t('deviationBulkEdit.creatorsName')}
            value={deviationBulkEditResponse.creatorsName}
            type={'text'}
          />
          <FieldAndValue
            fieldName={t('viewDeviationBulkEdit.isCsvUpload')}
            value={deviationBulkEditResponse.isCsvUpload}
            type={'boolean'}
          />
        </>
      )}
      <SpaceBetweenButtonGroup>
        <MultiSelectDropdown<DeviationColumnDropdownType>
          label={t('viewDeviationBulkEdit.selectColumnsButtonLabel')}
          options={columnDropdownOptions}
          itemToString={item => (item ? item.displayText : '')}
          onOptionSelect={onOptionSelect}
          initiallySelectedOptions={selectedOptions}
          renderToggleButton={renderToggleButton}
        />
        {deviationBulkEditResponse &&
          deviationBulkEditResponse.canAccessAllSitesOnBulkEdit &&
          !deviationBulkEditResponse.isCsvUpload && (
            <IfUserHasOneOfPermissions permissions={editCreateDeviationBulkEditPermissions.page}>
              <HollowLinkButton
                to={editDeviationBulkEditUrl(deviationBulkEditResponse.deviationBulkEditId)}
              >
                {t('viewDeviationBulkEdit.editBulkEditButton')}
              </HollowLinkButton>
            </IfUserHasOneOfPermissions>
          )}
      </SpaceBetweenButtonGroup>
      {apiError && <ApiErrorBox error={apiError} />}
      <HelpText text={t('viewDeviationBulkEdit.helpText')} />
      <DataFetchTable
        columns={columns}
        pageSize={deviationsPageSize}
        getApiData={getDeviationBulkEditCallback}
        mapResponseToTableData={mapDeviationBulkEditResponseToGroupedTableData}
        getSubRows={getSubRows}
        emptyTableMessage={t('viewDeviationBulkEdit.emptyTableMessage')}
        onDataFetchSuccess={onDataFetchSuccess}
        hiddenColumnNames={deselectedValues}
      />
    </>
  );
};

const getColumns = (
  t: TFunction,
  user: LoginUserResponse,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
  countries: Array<CountryResponse>,
): Array<CustomColumn<GroupedDeviationBulkEditRow, DeviationColumnTypes>> => [
  deviationExpandedColumn(),
  groupedPracticeGroupOrSiteNameColumn(t, null, practiceGroupsById),
  itemNameColumn(t),
  itemCodeColumn(t),
  {
    Header: t<string>('columnHeaders.isHidden'),
    accessor: row => row.isHiddenTo,
    id: 'isHidden',
    Cell: ({ cell, row }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue: boolean | undefined = getGroupedDeviationBulkEditCellValue<boolean>(
          row,
          'isHiddenTo',
          undefined,
        );
        return (
          <span>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : groupedCellValue
              ? t('yes')
              : t('no')}
          </span>
        );
      } else {
        return (
          <ChangeBooleanCell
            oldValue={cell.row.original.isHiddenFrom}
            newValue={cell.row.original.isHiddenTo}
          />
        );
      }
    },
  },
  centralItemPriceColumn(t, user, countries),
  {
    Header: t<string>('columnHeaders.deviatedPrice'),
    accessor: row => (user.useGrossPrices ? row.centralItemPriceGross : row.centralItemPriceNet),
    id: 'deviatedPrice',
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.priceGross')
      : t<string>('columnHeaders.groupHeaders.priceNet'),
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue =
          getUniqueValueOrDefault(
            row.original.deviations ?? [],
            deviation =>
              user.useGrossPrices
                ? getGrossDeviatedPriceTo(deviation)
                : getNetDeviatedPriceTo(deviation),
            undefined,
          ) ?? undefined;

        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : formatCurrency(
                  groupedCellValue,
                  user.locale,
                  getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
                )}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangePriceCell
            oldValueNet={getNetDeviatedPriceFrom(cell.row.original)}
            oldValueGross={getGrossDeviatedPriceFrom(cell.row.original)}
            newValueNet={getNetDeviatedPriceTo(cell.row.original)}
            newValueGross={getGrossDeviatedPriceTo(cell.row.original)}
            calculatedOld={false}
            calculatedNew={true}
            floatRight={true}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  {
    Header: t<string>('columnHeaders.priceDeviationPercentValue'),
    accessor: row => row.percentagePriceDeviationTo,
    id: 'priceDeviationPercentValue',
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.priceGross')
      : t<string>('columnHeaders.groupHeaders.priceNet'),
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue = getGroupedDeviationBulkEditCellValue<number>(
          row,
          'percentagePriceDeviationTo',
          undefined,
        );
        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : getRoundedDecimalWithPercentOrEmptyFieldValue(groupedCellValue)}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangeCell
            oldValue={getRoundedDecimalWithPercentOrEmptyFieldValue(
              cell.row.original.percentagePriceDeviationFrom,
            )}
            newValue={getRoundedDecimalWithPercentOrEmptyFieldValue(
              cell.row.original.percentagePriceDeviationTo,
            )}
            calculatedOld={!cell.row.original.priceDeviationIsSetByPercentageFrom}
            calculatedNew={!cell.row.original.priceDeviationIsSetByPercentageTo}
            floatRight={true}
          />
        );
      }
    },
  },
  centralItemDispenseFeeColumn(t, user, countries),
  {
    Header: t<string>('columnHeaders.deviatedDispenseFee'),
    accessor: row =>
      user.useGrossPrices ? row.centralItemDispenseFeeGross : row.centralItemDispenseFeeNet,
    id: 'deviatedDispenseFee',
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
      : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue =
          getUniqueValueOrDefault(
            row.original.deviations ?? [],
            deviation =>
              user.useGrossPrices
                ? getGrossDeviatedDispenseFeeTo(deviation)
                : getNetDeviatedDispenseFeeTo(deviation),
            undefined,
          ) ?? undefined;

        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : formatCurrency(
                  groupedCellValue,
                  user.locale,
                  getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
                )}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangePriceCell
            oldValueNet={getNetDeviatedDispenseFeeFrom(cell.row.original)}
            oldValueGross={getGrossDeviatedDispenseFeeFrom(cell.row.original)}
            newValueNet={getNetDeviatedDispenseFeeTo(cell.row.original)}
            newValueGross={getGrossDeviatedDispenseFeeTo(cell.row.original)}
            calculatedOld={false}
            calculatedNew={true}
            floatRight={true}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  {
    Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
    accessor: row => row.percentageDispenseFeeDeviationTo,
    id: 'dispenseFeeDeviationPercentValue',
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
      : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
    isRightAligned: true,
    Cell: ({ cell, row }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const groupedCellValue = getGroupedDeviationBulkEditCellValue<number>(
          row,
          'percentageDispenseFeeDeviationTo',
          undefined,
        );
        return (
          <AlignedSpan floatRight={true}>
            {groupedCellValue == null
              ? indeterminateGroupedCellValue
              : getRoundedDecimalWithPercentOrEmptyFieldValue(groupedCellValue)}
          </AlignedSpan>
        );
      } else {
        return (
          <ChangeCell
            oldValue={getRoundedDecimalWithPercentOrEmptyFieldValue(
              cell.row.original.percentageDispenseFeeDeviationFrom,
            )}
            newValue={getRoundedDecimalWithPercentOrEmptyFieldValue(
              cell.row.original.percentageDispenseFeeDeviationTo,
            )}
            calculatedOld={!cell.row.original.dispenseFeeDeviationIsSetByPercentageFrom}
            calculatedNew={!cell.row.original.dispenseFeeDeviationIsSetByPercentageTo}
            floatRight={true}
          />
        );
      }
    },
  },
  {
    id: 'deviationWarnings',
    fitContent: true,
    Cell: ({ row }: CellProps<DeviationResponse>) => {
      if (row.canExpand) {
        const deviationWarnings = getViewPagePracticeGroupWarnings(row, user);

        return (
          <IconRow
            iconsFromNamesMapping={deviationWarningIconsMapping}
            iconNames={deviationWarnings}
            testId={'deviationWarnings'}
            ariaLabel={'warning icons'}
            t={t}
          />
        );
      } else {
        const deviationWarnings = getNegativeDeviationWarningsFromDeviationResponse(row.original);

        return (
          <IconRow
            iconsFromNamesMapping={deviationWarningIconsMapping}
            iconNames={deviationWarnings}
            testId={'deviationWarnings'}
            ariaLabel={'warning icons'}
            t={t}
          />
        );
      }
    },
  },
];

const getGroupedDeviationBulkEditCellValue = <TValue extends string | number | boolean | undefined>(
  row: Row<GroupedDeviationBulkEditRow>,
  fieldName: keyof DeviationBulkEditLineResponse,
  defaultValue: TValue | undefined,
): TValue | undefined => {
  const deviations = row.original.deviations ?? [];
  return (
    getUniqueValueOrDefault(
      deviations,
      deviation => deviation[fieldName] as TValue,
      defaultValue,
    ) ?? defaultValue
  );
};

export const ViewDeviationBulkEdit = (props: OwnProps) => {
  const { user } = useContext(UserContext);
  return (
    <DataLoaderWithParams
      apiRequest={getAllPracticeGroups}
      getParams={() => user.organisationGroupId}
    >
      {practiceGroupsRequest => (
        <ViewDeviationBulkEditComponent
          {...props}
          practiceGroups={practiceGroupsRequest.response.practiceGroups}
        />
      )}
    </DataLoaderWithParams>
  );
};
