import { TFunction } from 'i18next';
import * as React from 'react';
import { CellProps } from 'react-table';
import { ControlledInput } from '../../../shared/forms/ControlledInput';
import { CurrencyField } from '../../../shared/forms/CurrencyField';
import { CurrencyInput } from '../../../shared/forms/CurrencyInput';
import { PercentField } from '../../../shared/forms/PercentField';
import { PercentInput } from '../../../shared/forms/PercentInput';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { LoginUserResponse, userHasOneOfPermissions } from '../../authentication/loginData/user';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { DeviationColumnTypes } from '../deviation';
import { itemCodeColumn, itemNameColumn } from '../DeviationColumns';
import {
  getCurrencyForCountry,
  onAbsoluteDispenseFeeDeviationChange,
  onAbsolutePriceDeviationChange,
  onDeviatedDispenseFeeChange,
  onDeviatedPriceChange,
  onPercentageDispenseFeeDeviationChange,
  onPercentagePriceDeviationChange,
  practiceGroupHasSubRowWithOverwritePrice,
  rowHasOverwritePrice,
  practiceGroupHasServiceTypeSubRow,
  rowItemIsServiceType,
} from '../DeviationsBase';
import {
  editCreateDeviationBulkEditPermissions,
  getDeviationFieldPrefix,
} from './deviationBulkEdit';
import {
  deviationBulkEditValueHasChanged,
  deviationHeaderValueHasChanged,
  getDeviationHeaderFieldProps,
  getGroupedDeviationRowPriceAbsoluteTestId,
  groupedActionsColumn,
  groupedWarningsColumn,
} from './SharedDeviationBulkEditColumns';
import {
  deviationExpandedColumn,
  getGroupedDeviationRowDispenseFeeAbsoluteTestId,
  getGroupedDeviationRowDispenseFeePercentageTestId,
  getGroupedDeviationRowPricePercentageTestId,
  groupedCentralItemPriceColumn,
  GroupedDeviationBulkEditRow,
  groupedIsHiddenColumn,
  groupedPracticeGroupOrSiteNameColumn,
  groupedCentralItemDispenseFeeColumn,
} from './SharedDeviationBulkEditColumns';
import { CountryResponse } from '../../authentication/loginData/metadata';

export const getEditDeviationBulkEditColumns = (
  t: TFunction,
  user: LoginUserResponse,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
  countries: Array<CountryResponse>,
): Array<CustomColumn<GroupedDeviationBulkEditRow, DeviationColumnTypes>> => [
  deviationExpandedColumn(),
  groupedPracticeGroupOrSiteNameColumn(t, null, practiceGroupsById),
  itemNameColumn(t),
  itemCodeColumn(t),
  groupedIsHiddenColumn(t, null, user),
  groupedCentralItemPriceColumn(t, user, countries),
  {
    id: 'deviatedPrice',
    Header: t<string>('columnHeaders.deviatedPrice'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.priceGross')
      : t<string>('columnHeaders.groupHeaders.priceNet'),
    isRightAligned: true,
    Cell: ({ cell, row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'deviatedPrice',
          onDeviatedPriceChange,
          deviation =>
            user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) || practiceGroupHasSubRowWithOverwritePrice(row)
                }
                warning={deviationHeaderValueHasChanged(formikProps, row, 'deviatedPrice', value)}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <CurrencyField
            bulkVariant={true}
            fastVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.deviatedPrice`}
            label={t('columnHeaders.deviatedPrice')}
            useDebouncing={true}
            onChange={onDeviatedPriceChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemPriceGross
                : row.original.centralItemPriceNet,
              user,
              row.original.itemType,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) || rowHasOverwritePrice(row)
            }
            warning={deviationBulkEditValueHasChanged(formikProps, row, 'deviatedPrice')}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  {
    id: 'priceDeviationPercentValue',
    Header: t<string>('columnHeaders.priceDeviationPercentValue'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.priceGross')
      : t<string>('columnHeaders.groupHeaders.priceNet'),
    isRightAligned: true,
    Cell: ({ row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'percentagePriceDeviation',
          onPercentagePriceDeviationChange,
          deviation =>
            user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedDeviationRowPricePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) || practiceGroupHasSubRowWithOverwritePrice(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentagePriceDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <PercentField
            bulkVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.percentagePriceDeviation`}
            label={t('columnHeaders.priceDeviationPercentValue')}
            useDebouncing={true}
            onChange={onPercentagePriceDeviationChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemPriceGross
                : row.original.centralItemPriceNet,
              user,
              row.original.itemType,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) || rowHasOverwritePrice(row)
            }
            warning={deviationBulkEditValueHasChanged(formikProps, row, 'percentagePriceDeviation')}
          />
        );
      }
    },
  },
  {
    id: user.useGrossPrices ? 'priceDeviationAbsoluteValueGross' : 'priceDeviationAbsoluteValueNet',
    Header: t<string>('columnHeaders.priceDeviationAbsoluteValue'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.priceGross')
      : t<string>('columnHeaders.groupHeaders.priceNet'),
    isRightAligned: true,
    Cell({ row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'absolutePriceDeviation',
          onAbsolutePriceDeviationChange,
          deviation =>
            user.useGrossPrices ? deviation.centralItemPriceGross : deviation.centralItemPriceNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                data-testid={getGroupedDeviationRowPriceAbsoluteTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) || practiceGroupHasSubRowWithOverwritePrice(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'absolutePriceDeviation',
                  value,
                )}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <CurrencyField
            bulkVariant={true}
            fastVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.absolutePriceDeviation`}
            label={t('columnHeaders.priceDeviationAbsoluteValue')}
            useDebouncing={true}
            onChange={onAbsolutePriceDeviationChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemPriceGross
                : row.original.centralItemPriceNet,
              user,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) || rowHasOverwritePrice(row)
            }
            warning={deviationBulkEditValueHasChanged(formikProps, row, 'absolutePriceDeviation')}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  groupedCentralItemDispenseFeeColumn(t, user, countries),
  {
    id: 'deviatedDispenseFee',
    Header: t<string>('columnHeaders.deviatedDispenseFee'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
      : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
    isRightAligned: true,
    Cell: ({ row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'deviatedDispenseFee',
          onDeviatedDispenseFeeChange,
          deviation =>
            user.useGrossPrices
              ? deviation.centralItemDispenseFeeGross
              : deviation.centralItemDispenseFeeNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasServiceTypeSubRow(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'deviatedDispenseFee',
                  value,
                )}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <CurrencyField
            bulkVariant={true}
            fastVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.deviatedDispenseFee`}
            label={t('columnHeaders.deviatedDispenseFee')}
            useDebouncing={true}
            onChange={onDeviatedDispenseFeeChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemDispenseFeeGross
                : row.original.centralItemDispenseFeeNet,
              user,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              rowHasOverwritePrice(row) ||
              rowItemIsServiceType(row)
            }
            warning={deviationBulkEditValueHasChanged(formikProps, row, 'deviatedDispenseFee')}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  {
    id: 'dispenseFeeDeviationPercentValue',
    Header: t<string>('columnHeaders.dispenseFeeDeviationPercentValue'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
      : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
    isRightAligned: true,
    Cell: ({ row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'percentageDispenseFeeDeviation',
          onPercentageDispenseFeeDeviationChange,
          deviation =>
            user.useGrossPrices
              ? deviation.centralItemDispenseFeeGross
              : deviation.centralItemDispenseFeeNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedDeviationRowDispenseFeePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasServiceTypeSubRow(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentageDispenseFeeDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <PercentField
            bulkVariant={true}
            fastVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.percentageDispenseFeeDeviation`}
            label={t('columnHeaders.dispenseFeeDeviationPercentValue')}
            useDebouncing={true}
            onChange={onPercentageDispenseFeeDeviationChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemDispenseFeeGross
                : row.original.centralItemDispenseFeeNet,
              user,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              rowHasOverwritePrice(row) ||
              rowItemIsServiceType(row)
            }
            warning={deviationBulkEditValueHasChanged(
              formikProps,
              row,
              'percentageDispenseFeeDeviation',
            )}
          />
        );
      }
    },
  },
  {
    id: user.useGrossPrices
      ? 'dispenseFeeDeviationAbsoluteValueGross'
      : 'dispenseFeeDeviationAbsoluteValueNet',
    Header: t<string>('columnHeaders.dispenseFeeDeviationAbsoluteValue'),
    headerGroupName: user.useGrossPrices
      ? t<string>('columnHeaders.groupHeaders.dispenseFeeGross')
      : t<string>('columnHeaders.groupHeaders.dispenseFeeNet'),
    isRightAligned: true,
    Cell({ row, formikProps }: CellProps<GroupedDeviationBulkEditRow>) {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getDeviationHeaderFieldProps(
          row,
          formikProps,
          user,
          'absoluteDispenseFeeDeviation',
          onAbsoluteDispenseFeeDeviationChange,
          deviation =>
            user.useGrossPrices
              ? deviation.centralItemDispenseFeeGross
              : deviation.centralItemDispenseFeeNet,
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <CurrencyInput
                data-testid={getGroupedDeviationRowDispenseFeeAbsoluteTestId(
                  row.original.practiceGroupId,
                  row.original.itemId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(
                    user,
                    editCreateDeviationBulkEditPermissions.deviatePrices,
                  ) ||
                  practiceGroupHasSubRowWithOverwritePrice(row) ||
                  practiceGroupHasServiceTypeSubRow(row)
                }
                warning={deviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'absoluteDispenseFeeDeviation',
                  value,
                )}
                currency={getCurrencyForCountry(
                  row.subRows[0].original.siteCountry,
                  user,
                  countries,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return (
          <CurrencyField
            bulkVariant={true}
            fastVariant={true}
            hideLabel={true}
            name={`${getDeviationFieldPrefix(row)}.absoluteDispenseFeeDeviation`}
            label={t('columnHeaders.DispenseFeeDeviationAbsoluteValue')}
            useDebouncing={true}
            onChange={onAbsoluteDispenseFeeDeviationChange(
              row.original.siteId,
              row.original.itemId,
              user.useGrossPrices
                ? row.original.centralItemDispenseFeeGross
                : row.original.centralItemDispenseFeeNet,
              user,
            )}
            isRightAligned={true}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              rowHasOverwritePrice(row) ||
              rowItemIsServiceType(row)
            }
            warning={deviationBulkEditValueHasChanged(
              formikProps,
              row,
              'absoluteDispenseFeeDeviation',
            )}
            currency={getCurrencyForCountry(row.original.siteCountry, user, countries)}
          />
        );
      }
    },
  },
  groupedActionsColumn(t),
  groupedWarningsColumn(t),
];
