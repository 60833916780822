import { Form, Formik, FormikActions } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { FormValidationErrorBox } from '../errors/FormValidationErrorBox';
import { useApiRequest } from '../hooks/useApiRequest';
import {
  AdditionWarningText,
  StyledAdditionalChildrenContainer,
  StyledSubmitButton,
} from './FormikTable.styles';
import { FormikBasicTableProps, FormikTableProps } from './FormikTable.types';
import { DataFetchTable, Table } from './Table';
import { TableDataFetchResponse } from './Table.types';

export const FormikTable = <
  TFormModel,
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TRowData extends object,
  TSubmitParams,
  TSubmitResponse,
  TColumnNames extends string
>(
  props: FormikTableProps<
    TFormModel,
    TApiData,
    TRowData,
    TSubmitParams,
    TSubmitResponse,
    TColumnNames
  >,
) => {
  const { t } = useTranslation('component');

  const [tableData, setTableData] = useState<TApiData | null>(null);
  const [shouldDisableForm, setShouldDisableForm] = useState<boolean>(false);

  const { apiError, makeRequest } = useApiRequest(props.submitRequest);

  const onDataFetchSuccess = (apiResponse: TApiData) => {
    setTableData(apiResponse);
    if (props.onDataFetchSuccess) {
      props.onDataFetchSuccess(apiResponse);
    }
  };

  const handleSubmit = (
    values: TFormModel | null,
    formikActions: FormikActions<TFormModel | null>,
  ) => {
    if (values) {
      makeRequest(props.mapValuesToRequestParameters(values))
        .then(result => {
          if (result && props.disableFormOnSuccess) {
            setShouldDisableForm(true);
          }

          if (result && props.onSubmitComplete) {
            props.onSubmitComplete(result, formikActions);
          }
        })
        .finally(() => formikActions.setSubmitting(false));
    } else {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Formik<TFormModel | null>
      enableReinitialize={props.enableReinitialize}
      initialValues={props.apiDataToFormModel(tableData)}
      onSubmit={handleSubmit}
      validate={values => (values ? props.validate(values) : [])}
      render={formikProps => (
        <Form>
          <DataFetchTable
            {...props}
            formikProps={formikProps}
            onDataFetchSuccess={onDataFetchSuccess}
            shouldRestrictFetching={props.shouldRestrictFetching || shouldDisableForm}
          />

          {props.additionalFormFields && (
            <StyledAdditionalChildrenContainer>
              {props.additionalFormFields(formikProps)}
            </StyledAdditionalChildrenContainer>
          )}

          {formikProps.errors && formikProps.touched && (
            <FormValidationErrorBox
              errors={formikProps.errors}
              touched={formikProps.touched}
              errorMessage={props.formValidationErrorMessage}
            />
          )}
          {props.additionalWarningText && (
            <AdditionWarningText>{props.additionalWarningText}</AdditionWarningText>
          )}
          <StyledSubmitButton
            type="submit"
            loading={formikProps.isSubmitting}
            disabled={props.disabled || shouldDisableForm}
          >
            {t('formikTable.submit')}
          </StyledSubmitButton>

          {apiError && <ApiErrorBox error={apiError} />}
        </Form>
      )}
    />
  );
};

// Like a FormikTable, but doesn't need to fetch anything from the api
export const FormikBasicTable = <
  TFormModel,
  TRowData extends object,
  TColumnNames extends string,
  TSubmitParams,
  TSubmitResponse
>(
  props: FormikBasicTableProps<TFormModel, TRowData, TColumnNames, TSubmitParams, TSubmitResponse>,
) => {
  const { t } = useTranslation('component');
  const { apiError, makeRequest } = useApiRequest(props.submitRequest);

  const handleSubmit = (
    values: TFormModel | null,
    formikActions: FormikActions<TFormModel | null>,
  ) => {
    if (values) {
      makeRequest(props.mapValuesToRequestParameters(values))
        .then(result => {
          if (result && props.onSubmitComplete) {
            props.onSubmitComplete(result, formikActions);
          }
        })
        .finally(() => formikActions.setSubmitting(false));
    } else {
      formikActions.setSubmitting(false);
    }
  };

  return (
    <Formik<TFormModel | null>
      enableReinitialize={props.enableReinitialize}
      initialValues={props.initialFormValues}
      onSubmit={handleSubmit}
      validate={values => (values ? props.validate(values) : [])}
      render={formikProps => (
        <Form>
          <Table {...props} formikProps={formikProps} />

          {props.additionalFormFields && (
            <StyledAdditionalChildrenContainer>
              {props.additionalFormFields(formikProps)}
            </StyledAdditionalChildrenContainer>
          )}

          {formikProps.errors && formikProps.touched && (
            <FormValidationErrorBox
              errors={formikProps.errors}
              touched={formikProps.touched}
              errorMessage={props.formValidationErrorMessage}
            />
          )}

          <StyledSubmitButton
            type="submit"
            loading={formikProps.isSubmitting}
            disabled={props.disabled}
          >
            {t('formikTable.submit')}
          </StyledSubmitButton>

          {apiError && <ApiErrorBox error={apiError} />}
        </Form>
      )}
    />
  );
};
