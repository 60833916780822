import { styled } from '../../styling/theme';
import { PrimaryButton } from '../buttons/Button';

export const StyledSubmitButton = styled(PrimaryButton)`
  margin-top: ${props => props.theme.spacing.small}px;
  margin-bottom: ${props => props.theme.spacing.tiny}px;
`;

export const StyledAdditionalChildrenContainer = styled.div`
  margin-top: ${props => props.theme.spacing.small}px;
`;

export const AdditionWarningText = styled.div`
  color: ${props => props.theme.colours.alertText};
  font-size: ${props => props.theme.typography.content.fontSize}px;
  margin-top: ${props => props.theme.spacing.extraSmall}px;
  margin-bottom: ${props => props.theme.spacing.extraSmall}px;
`;
