import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown, faChevronUp, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { isEmpty, uniq, values } from 'lodash';
import React, { ChangeEvent } from 'react';
import { CellProps, HeaderProps, Row } from 'react-table';
import { IconButton } from '../../../shared/buttons/IconButton';
import { Checkbox } from '../../../shared/forms/Checkbox';
import { CheckboxField } from '../../../shared/forms/CheckboxField';
import { IconRow } from '../../../shared/IconRow';
import { MarginLeftIcon } from '../../../shared/StyledIcons';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { styled } from '../../../styling/theme';
import { formatCurrency } from '../../../utils/currencyUtils';
import { TupleKeyDictionary } from '../../../utils/TupleKeyDictionary';
import { LoginUserResponse, userHasOneOfPermissions } from '../../authentication/loginData/user';
import { PracticeGroupResponse } from '../../sites/practiceGroups/practiceGroup';
import { DeviationColumnTypes, DeviationResponse, DeviationTableRow } from '../deviation';
import {
  centralItemPriceColumn,
  centralItemDispenseFeeColumn,
  AlignedSpan,
  getGroupedDeviationTableRowCellValue,
} from '../DeviationColumns';
import {
  getFormikValuesForDeviationBulkEdit,
  rowHasOverwritePrice,
  practiceGroupHasSubRowWithOverwritePrice,
  getCurrencyForCountry,
} from '../DeviationsBase';
import {
  deviationWarningIconsMapping,
  getPracticeGroupWarningsForEditableScreens,
  getWarningsForDeviationRowForEditableScreens,
} from '../DeviationsWarnings';
import {
  CreateDeviationBulkEditFormModel,
  DeviationBulkEditLineFormModel,
  DeviationBulkEditLineResponse,
  DeviationBulkEditResponse,
  editCreateDeviationBulkEditPermissions,
  EditDeviationBulkEditFormModel,
  getDeviationFieldPrefix,
  getDeviationFieldPrefixBySiteAndItem,
} from './deviationBulkEdit';
import { Id } from '../../../models/id';
import { getUniqueValueOrDefault } from '../../../utils/arrayUtils';
import { CountryResponse } from '../../authentication/loginData/metadata';
import { setRowDispenseFeeFields, setRowPriceFields } from './CreateDeviationBulkEditColumns';

export const getGroupedDeviationRowExpandToggleTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-deviation-row-expand-toggle-${practiceGroupId}-${itemId}`;

export const getGroupedDeviationRowPricePercentageTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-deviation-row-price-percentage-${practiceGroupId}-${itemId}`;

export const getGroupedDeviationRowPriceAbsoluteTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-deviation-row-price-absolute-${practiceGroupId}-${itemId}`;

export const getGroupedDeviationRowDispenseFeePercentageTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-deviation-row-dispense-fee-percentage-${practiceGroupId}-${itemId}`;

export const getGroupedDeviationRowDispenseFeeAbsoluteTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-deviation-row-dispense-fee-absolute-${practiceGroupId}-${itemId}`;

export type GroupedDeviationTableRow = GenericGroupedDeviationRow<DeviationTableRow>;

export type GroupedDeviationResponseRow = GenericGroupedDeviationRow<DeviationResponse>;

export type GroupedDeviationBulkEditRow = GenericGroupedDeviationRow<DeviationBulkEditLineResponse>;

export type GenericGroupedDeviationRow<T extends DeviationTableRow> = T & {
  practiceGroupId?: Id;
  itemId?: Id;
  deviations?: Array<T>;
};

const NonEditableValue = styled.div`
  border: 1px solid ${props => props.theme.colours.secondaryInactive};
  border-radius: ${props => props.theme.spacing.tiny}px;
  padding: 3px ${props => props.theme.spacing.tiny}px;
  background-color: ${props => props.theme.colours.appBackground};
`;

const getGroupedDeviationBulkEditRow = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
  deviations: Array<DeviationBulkEditLineResponse>,
): GroupedDeviationBulkEditRow => {
  return {
    practiceGroupId,
    itemId,
    deviations,
  } as GroupedDeviationBulkEditRow;
};

const getDeviationsFromFormModel = (formValues: EditDeviationBulkEditFormModel | undefined) => {
  return formValues
    ? values(formValues.deviations).flatMap(deviationsByItemId => values(deviationsByItemId))
    : [];
};

export const getSubRows = (
  groupedDeviationBulkEditRow: GroupedDeviationBulkEditRow,
): Array<GroupedDeviationBulkEditRow> => groupedDeviationBulkEditRow.deviations ?? [];

export const mapDeviationBulkEditResponseToGroupedTableData = (
  response: DeviationBulkEditResponse,
): Array<GroupedDeviationBulkEditRow> => {
  const practiceGroupIds = uniq(
    response.deviationBulkEditLines.flatMap(
      deviationBulkEditLine => deviationBulkEditLine.practiceGroupIds,
    ),
  );

  const itemIds = uniq(
    response.deviationBulkEditLines.map(deviationBulkEditLine => deviationBulkEditLine.itemId),
  );

  const groupedDeviationsWithPracticeGroups = practiceGroupIds.flatMap(practiceGroupId =>
    itemIds.map(itemId =>
      getGroupedDeviationBulkEditRow(
        practiceGroupId,
        itemId,
        response.deviationBulkEditLines.filter(
          deviationBulkEditLine =>
            deviationBulkEditLine.practiceGroupIds.includes(practiceGroupId) &&
            deviationBulkEditLine.itemId === itemId,
        ),
      ),
    ),
  );

  const deviationsWithoutPracticeGroups = response.deviationBulkEditLines.filter(
    deviationBulkEditLine => deviationBulkEditLine.practiceGroupIds.length === 0,
  );

  if (deviationsWithoutPracticeGroups.length === 0) {
    return groupedDeviationsWithPracticeGroups.filter(
      group => group.deviations != null && group.deviations.length > 0,
    );
  } else {
    const groupedDeviationsWithoutPracticeGroups = itemIds.map(itemId =>
      getGroupedDeviationBulkEditRow(
        undefined,
        itemId,
        deviationsWithoutPracticeGroups.filter(deviation => deviation.itemId === itemId),
      ),
    );

    return groupedDeviationsWithPracticeGroups
      .concat(groupedDeviationsWithoutPracticeGroups)
      .filter(group => group.deviations != null && group.deviations.length > 0);
  }
};

export const getFormModelDeviations = (
  subRowDeviations: Array<DeviationTableRow>,
  formValues: EditDeviationBulkEditFormModel,
): Array<DeviationBulkEditLineFormModel> => {
  // Formik may not be fully initialised yet
  if (isEmpty(formValues.deviations)) {
    return [];
  }
  return subRowDeviations.map(
    deviation => formValues.deviations[deviation.siteId][deviation.itemId],
  );
};

export const deviationExpandedColumn = <T extends DeviationTableRow>(): CustomColumn<
  GenericGroupedDeviationRow<T>,
  DeviationColumnTypes
> => ({
  id: 'expanded',
  fitContent: true,
  Header: ({
    getToggleAllRowsExpandedProps,
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  }: HeaderProps<GenericGroupedDeviationRow<T>>) => (
    <span
      {...getToggleAllRowsExpandedProps()}
      // There seems to be a bug with the way that getToggleAllRowsExpandedProps works,
      // so for now we have to manually add the onClick event
      onClick={() => toggleAllRowsExpanded(!isAllRowsExpanded)}
    >
      <FontAwesomeIcon icon={isAllRowsExpanded ? faChevronUp : faChevronDown} />
    </span>
  ),
  Cell: ({ row }: CellProps<GenericGroupedDeviationRow<T>>) =>
    row.canExpand ? (
      <span
        data-testid={getGroupedDeviationRowExpandToggleTestId(
          row.original.practiceGroupId,
          row.original.itemId,
        )}
        {...row.getToggleRowExpandedProps()}
      >
        <FontAwesomeIcon icon={row.isExpanded ? faChevronUp : faChevronDown} />
      </span>
    ) : null,
});

export const groupedPracticeGroupOrSiteNameColumn = <T extends DeviationTableRow>(
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<Id, Id, Id> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): CustomColumn<GenericGroupedDeviationRow<T>, DeviationColumnTypes> => ({
  id: 'siteName',
  Header: t<string>('columnHeaders.practiceGroupOrSiteName'),
  accessor: (row: DeviationTableRow) => row.siteName,
  Cell: ({ cell, row }: CellProps<GenericGroupedDeviationRow<T>>) =>
    row.canExpand ? (
      <span>
        {row.original.practiceGroupId
          ? practiceGroupsById[row.original.practiceGroupId]?.name ?? ''
          : t('noPracticeGroup')}
      </span>
    ) : (
      <span>
        {cell.value}
        {scheduledBulkEditIdBySiteIdThenItemId &&
          scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId) && (
            <MarginLeftIcon icon={faClock} />
          )}
      </span>
    ),
});

const getAllHiddenInFormModel = <TFormModel extends EditDeviationBulkEditFormModel>(
  formModel: TFormModel,
): boolean | undefined => {
  if (!formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getDeviationsFromFormModel(formModel),
    deviation => deviation.isHidden,
    undefined,
  );
};

const getAllDispenseFeeCodeSpecificInFormModel = <
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formModel: TFormModel,
): boolean | undefined => {
  if (!formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getDeviationsFromFormModel(formModel),
    deviation => deviation.itemDeviationDispenseFeeOverride,
    undefined,
  );
};

const getAllPriceCodeSpecificInFormModel = <TFormModel extends EditDeviationBulkEditFormModel>(
  formModel: TFormModel,
): boolean | undefined => {
  if (!formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getDeviationsFromFormModel(formModel),
    deviation => deviation.itemDeviationPriceOverride,
    undefined,
  );
};

const getAllHiddenInGroupedSubRows = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  subRowDeviations: Array<TRow>,
  formModel: TFormModel,
): boolean | undefined => {
  if (!subRowDeviations || !formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getFormModelDeviations(subRowDeviations, formModel),
    deviation => deviation.isHidden,
    undefined,
  );
};

const getAllDispenseFeeSpecificInGroupedSubRows = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  subRowDeviations: Array<TRow>,
  formModel: TFormModel,
): boolean | undefined => {
  if (!subRowDeviations || !formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getFormModelDeviations(subRowDeviations, formModel),
    deviation => deviation.itemDeviationDispenseFeeOverride,
    undefined,
  );
};

const getAllPriceCodeSpecificInGroupedSubRows = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  subRowDeviations: Array<TRow>,
  formModel: TFormModel,
): boolean | undefined => {
  if (!subRowDeviations || !formModel) {
    return undefined;
  }

  return getUniqueValueOrDefault(
    getFormModelDeviations(subRowDeviations, formModel),
    deviation => deviation.itemDeviationPriceOverride,
    undefined,
  );
};

const getIsHiddenHasChangedForRow = <TRow extends DeviationTableRow>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
) => {
  const initialIsHidden = getIsHiddenForRow(
    row,
    formikProps?.initialValues as EditDeviationBulkEditFormModel,
  );
  const currentIsHidden = getIsHiddenForRow(
    row,
    formikProps?.values as EditDeviationBulkEditFormModel,
  );
  return !initialIsHidden && !currentIsHidden ? false : initialIsHidden !== currentIsHidden;
};

const getDispenseFeeCodeSpecificHasChangedForRow = <TRow extends DeviationTableRow>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
) => {
  const initialIsHidden = getDispenseFeeSpecificForRow(
    row,
    formikProps?.initialValues as EditDeviationBulkEditFormModel,
  );
  const currentIsHidden = getDispenseFeeSpecificForRow(
    row,
    formikProps?.values as EditDeviationBulkEditFormModel,
  );
  return !initialIsHidden && !currentIsHidden ? false : initialIsHidden !== currentIsHidden;
};

const getPriceCodeSpecificHasChangedForRow = <TRow extends DeviationTableRow>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
) => {
  const initialIsHidden = getPriceSpecificForRow(
    row,
    formikProps?.initialValues as EditDeviationBulkEditFormModel,
  );
  const currentIsHidden = getPriceSpecificForRow(
    row,
    formikProps?.values as EditDeviationBulkEditFormModel,
  );
  return !initialIsHidden && !currentIsHidden ? false : initialIsHidden !== currentIsHidden;
};

const getDispenseFeeSpecificForRow = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  row: Row<GenericGroupedDeviationRow<TRow>>,
  formModel: TFormModel,
): boolean | undefined => {
  return !formModel || isEmpty(formModel.deviations)
    ? undefined
    : formModel.deviations[row.original.siteId][row.original.itemId].itemDeviationPriceOverride;
};
const getPriceSpecificForRow = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  row: Row<GenericGroupedDeviationRow<TRow>>,
  formModel: TFormModel,
): boolean | undefined => {
  return !formModel || isEmpty(formModel.deviations)
    ? undefined
    : formModel.deviations[row.original.siteId][row.original.itemId].itemDeviationPriceOverride;
};

const getIsHiddenForRow = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  row: Row<GenericGroupedDeviationRow<TRow>>,
  formModel: TFormModel,
): boolean | undefined => {
  return !formModel || isEmpty(formModel.deviations)
    ? undefined
    : formModel.deviations[row.original.siteId][row.original.itemId].isHidden;
};

const IsHiddenHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    margin-right: ${props => props.theme.spacing.tiny}px;
  }
`;

export const formModelIsHiddenCheckboxTestId = 'form-model-is-hidden-checkbox';
export const formModelPriceSpecificCheckboxTestId = 'form-model-priceSpecific--checkbox';
export const formModelDispenseFeeSpecificCheckboxTestId = 'form-model-dispenseSpecific--checkbox';

export const groupedRowIsHiddenCheckboxTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-row-${practiceGroupId}-${itemId}-is-hidden-checkbox`;

export const groupedRowDispenseFeeSpecificCheckboxTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-row-${practiceGroupId}-${itemId}-dispenseSpecific-checkbox`;

export const groupedRowPriceSpecificCheckboxTestId = (
  practiceGroupId: Id | undefined,
  itemId: Id | undefined,
) => `grouped-row-${practiceGroupId}-${itemId}-priceSpecific-checkbox`;

export const siteItemIsHiddenCheckboxTestId = (siteId: number, itemId: number) =>
  `site-${siteId}-item-${itemId}-is-hidden-checkbox`;

export const siteItemPriceSpecificCheckboxTestId = (siteId: number, itemId: number) =>
  `site-${siteId}-item-${itemId}-priceSpecific-checkbox`;

export const siteItemDispenseFeeSpecificCheckboxTestId = (siteId: number, itemId: number) =>
  `site-${siteId}-item-${itemId}-dispenseSpecific-checkbox`;

export const groupedIsHiddenColumn = <TRow extends DeviationTableRow>(
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<Id, Id, Id> | null,
  user: LoginUserResponse,
): CustomColumn<GenericGroupedDeviationRow<TRow>, DeviationColumnTypes> => ({
  id: 'isHidden',
  Header: ({ data, formikProps }: HeaderProps<GenericGroupedDeviationRow<TRow>>) => {
    const initialAllHidden = getAllHiddenInFormModel(
      formikProps?.initialValues as EditDeviationBulkEditFormModel,
    );

    const currentAllHidden = getAllHiddenInFormModel(
      formikProps?.values as EditDeviationBulkEditFormModel,
    );

    const someItemsHavePreventHideInFormModel = data
      .flatMap(groupedRow => groupedRow.deviations)
      .some(deviation => deviation?.itemPreventHide ?? false);

    return (
      <IsHiddenHeaderContainer>
        <Checkbox
          data-testid={formModelIsHiddenCheckboxTestId}
          checked={currentAllHidden === true}
          indeterminate={currentAllHidden === undefined}
          onChange={isHidden => {
            if (formikProps) {
              data.forEach(groupedRow => {
                groupedRow.deviations?.forEach(deviationResponse => {
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  formikProps.setFieldValue(`${fieldPrefix}.isHidden`, isHidden);
                });
              });
            }
          }}
          disabled={
            !userHasOneOfPermissions(user, editCreateDeviationBulkEditPermissions.hideItems) ||
            someItemsHavePreventHideInFormModel
          }
          warning={initialAllHidden !== currentAllHidden}
        />
        {t('columnHeaders.isHidden')}
      </IsHiddenHeaderContainer>
    );
  },
  Cell: ({ row, formikProps }: CellProps<GenericGroupedDeviationRow<TRow>>) => {
    if (row.canExpand) {
      if (row.original.deviations && formikProps?.values) {
        const initialAllHidden = getAllHiddenInGroupedSubRows(
          row.original.deviations,
          formikProps?.initialValues as EditDeviationBulkEditFormModel,
        );

        const currentAllHidden = getAllHiddenInGroupedSubRows(
          row.original.deviations,
          formikProps?.values as EditDeviationBulkEditFormModel,
        );

        const someItemsHavePreventHideInGroupedSubRows = row.original.deviations.some(
          deviation => deviation?.itemPreventHide ?? false,
        );

        return (
          <Checkbox
            data-testid={groupedRowIsHiddenCheckboxTestId(
              row.original.practiceGroupId,
              row.original.itemId,
            )}
            checked={currentAllHidden === true}
            indeterminate={currentAllHidden === undefined}
            onChange={isHidden => {
              if (row.original.deviations) {
                row.original.deviations.forEach(deviationResponse => {
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  formikProps.setFieldValue(`${fieldPrefix}.isHidden`, isHidden);
                });
              }
            }}
            disabled={
              !userHasOneOfPermissions(user, editCreateDeviationBulkEditPermissions.hideItems) ||
              someItemsHavePreventHideInGroupedSubRows
            }
            warning={initialAllHidden !== currentAllHidden}
          />
        );
      } else {
        return null;
      }
    } else {
      if (
        scheduledBulkEditIdBySiteIdThenItemId &&
        scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
      ) {
        const initialFormValues = formikProps?.initialValues as EditDeviationBulkEditFormModel;
        return <span>{!!getIsHiddenForRow(row, initialFormValues) ? t('yes') : t('no')}</span>;
      } else {
        return (
          <CheckboxField
            testId={siteItemIsHiddenCheckboxTestId(row.original.siteId, row.original.itemId)}
            name={`${getDeviationFieldPrefix(row)}.isHidden`}
            useDebouncing={true}
            disabled={
              !userHasOneOfPermissions(user, editCreateDeviationBulkEditPermissions.hideItems) ||
              row.original.itemPreventHide
            }
            warning={getIsHiddenHasChangedForRow(formikProps, row)}
          />
        );
      }
    }
  },
});

/**
 * Jira pp-933 If this is a stock item do not display code specific
 */
export const groupedPriceCodeSpecificColumn = <TRow extends DeviationTableRow>(
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<Id, Id, Id> | null,
  user: LoginUserResponse,
  discountCategoryId: number,
): CustomColumn<GenericGroupedDeviationRow<TRow>, DeviationColumnTypes> => ({
  id: 'itemDeviationPriceOverride',
  Header: ({ data, formikProps }: HeaderProps<GroupedDeviationResponseRow>) => {
    const initialAllPriceOverride = getAllPriceCodeSpecificInFormModel(
      formikProps?.initialValues as EditDeviationBulkEditFormModel,
    );

    const currentAllPriceOverride = getAllPriceCodeSpecificInFormModel(
      formikProps?.values as EditDeviationBulkEditFormModel,
    );

    const someItemsHaveOverwiteInGroupedSubRows = data
      .flatMap(groupedRow => groupedRow.deviations)
      .some(deviation => deviation?.overwrite ?? false);

    const someItemsHaveDiscountGroupedSubRows = data
      .flatMap(groupedRow => groupedRow.deviations)
      .some(deviation => deviation?.category1Id == discountCategoryId ?? false);

    return (
      <IsHiddenHeaderContainer>
        <Checkbox
          data-testid={formModelPriceSpecificCheckboxTestId}
          checked={currentAllPriceOverride === true}
          indeterminate={currentAllPriceOverride === undefined}
          onChange={priceOverride => {
            if (formikProps) {
              data.forEach(groupedRow => {
                groupedRow.deviations?.forEach(deviationResponse => {
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  setRowPriceFields(
                    formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                    deviationResponse,
                    user,
                    priceOverride,
                  );
                  formikProps.setFieldValue(
                    `${fieldPrefix}.itemDeviationPriceOverride`,
                    priceOverride,
                  );
                });
              });
            }
          }}
          disabled={
            !userHasOneOfPermissions(user, editCreateDeviationBulkEditPermissions.deviatePrices) ||
            someItemsHaveOverwiteInGroupedSubRows ||
            someItemsHaveDiscountGroupedSubRows
          }
          warning={initialAllPriceOverride !== currentAllPriceOverride}
        />
        {t('columnHeaders.itemDeviationPriceOverride')}
      </IsHiddenHeaderContainer>
    );
  },
  Cell: ({ row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      if (row.original.deviations && formikProps?.values) {
        const someItemsHaveOverwiteInGroupedSubRows = row.original?.deviations.some(
          deviation => deviation?.overwrite ?? false,
        );

        const someItemsHaveDiscountGroupedSubRows = row.original?.deviations.some(
          deviation => deviation?.category1Id == discountCategoryId ?? false,
        );

        const initialAllPriceOverride = getAllPriceCodeSpecificInGroupedSubRows(
          row.original.deviations,
          formikProps?.initialValues as EditDeviationBulkEditFormModel,
        );

        const currentAllPriceOverride = getAllPriceCodeSpecificInGroupedSubRows(
          row.original.deviations,
          formikProps?.values as EditDeviationBulkEditFormModel,
        );

        const stockItem = row.original.deviations[0].stockItem;

        return (
          <Checkbox
            data-testid={groupedRowPriceSpecificCheckboxTestId(
              row.original.practiceGroupId,
              row.original.itemId,
            )}
            checked={currentAllPriceOverride === true}
            indeterminate={currentAllPriceOverride === undefined}
            onChange={priceOverride => {
              if (row.original.deviations) {
                row.original.deviations.forEach(deviationResponse => {
                  setRowPriceFields(
                    formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                    deviationResponse,
                    user,
                    priceOverride,
                  );
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  formikProps.setFieldValue(
                    `${fieldPrefix}.itemDeviationPriceOverride`,
                    priceOverride,
                  );
                });
              }
            }}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              someItemsHaveOverwiteInGroupedSubRows ||
              someItemsHaveDiscountGroupedSubRows ||
              !stockItem
            }
            warning={initialAllPriceOverride !== currentAllPriceOverride}
          />
        );
      } else {
        return null;
      }
    } else {
      if (
        scheduledBulkEditIdBySiteIdThenItemId &&
        scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
      ) {
        const initialFormValues = formikProps?.initialValues as EditDeviationBulkEditFormModel;
        return <span>{!!getIsHiddenForRow(row, initialFormValues) ? t('yes') : t('no')}</span>;
      } else {
        return (
          <CheckboxField
            testId={siteItemPriceSpecificCheckboxTestId(row.original.siteId, row.original.itemId)}
            name={`${getDeviationFieldPrefix(row)}.itemDeviationPriceOverride`}
            useDebouncing={true}
            onChange={priceOverride => {
              setRowPriceFields(
                formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                row.original,
                user,
                priceOverride,
              );
            }}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              row.original.overwrite ||
              row.original.category1Id == discountCategoryId ||
              !row.original.stockItem
            }
            warning={getPriceCodeSpecificHasChangedForRow(formikProps, row)}
          />
        );
      }
    }
  },
});

/**
 * Jira pp-933 If this is a stock item do not display code specific
 */
export const groupedDispenseFeeCodeSpecificColumn = <TRow extends DeviationTableRow>(
  t: TFunction,
  scheduledBulkEditIdBySiteIdThenItemId: TupleKeyDictionary<Id, Id, Id> | null,
  user: LoginUserResponse,
  discountCategoryId: number,
): CustomColumn<GenericGroupedDeviationRow<TRow>, DeviationColumnTypes> => ({
  id: 'itemDeviationDispenseFeeOverride',
  Header: ({ data, formikProps }: HeaderProps<GroupedDeviationResponseRow>) => {
    const initialAllDispenseOverride = getAllDispenseFeeCodeSpecificInFormModel(
      formikProps?.initialValues as EditDeviationBulkEditFormModel,
    );

    const currentAllOverride = getAllDispenseFeeCodeSpecificInFormModel(
      formikProps?.values as EditDeviationBulkEditFormModel,
    );

    const someItemsHaveOverwiteInGroupedSubRows = data
      .flatMap(groupedRow => groupedRow.deviations)
      .some(deviation => deviation?.overwrite ?? false);

    const someItemsHaveDiscountGroupedSubRows = data
      .flatMap(groupedRow => groupedRow.deviations)
      .some(deviation => deviation?.category1Id == discountCategoryId ?? false);

    return (
      <IsHiddenHeaderContainer>
        <Checkbox
          data-testid={formModelDispenseFeeSpecificCheckboxTestId}
          checked={currentAllOverride === true}
          indeterminate={currentAllOverride === undefined}
          onChange={dispenseOverride => {
            if (formikProps) {
              data.forEach(groupedRow => {
                groupedRow.deviations?.forEach(deviationResponse => {
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  setRowDispenseFeeFields(
                    formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                    deviationResponse,
                    user,
                    dispenseOverride,
                  );
                  formikProps.setFieldValue(
                    `${fieldPrefix}.itemDeviationDispenseFeeOverride`,
                    dispenseOverride,
                  );
                });
              });
            }
          }}
          disabled={
            !userHasOneOfPermissions(user, editCreateDeviationBulkEditPermissions.deviatePrices) ||
            someItemsHaveOverwiteInGroupedSubRows ||
            someItemsHaveDiscountGroupedSubRows
          }
          warning={initialAllDispenseOverride !== currentAllOverride}
        />
        {t('columnHeaders.itemDeviationDispenseFeeOverride')}
      </IsHiddenHeaderContainer>
    );
  },
  Cell: ({ row, formikProps }: CellProps<GroupedDeviationResponseRow>) => {
    if (row.canExpand) {
      if (row.original.deviations && formikProps?.values) {
        const initialAllDispenseOverride = getAllDispenseFeeSpecificInGroupedSubRows(
          row.original.deviations,
          formikProps?.initialValues as EditDeviationBulkEditFormModel,
        );

        const currentAllDispenseOverride = getAllDispenseFeeSpecificInGroupedSubRows(
          row.original.deviations,
          formikProps?.values as EditDeviationBulkEditFormModel,
        );

        const someItemsHaveOverwiteInGroupedSubRows = row.original?.deviations.some(
          deviation => deviation?.overwrite ?? false,
        );

        const someItemsHaveDiscountGroupedSubRows = row.original?.deviations.some(
          deviation => deviation?.category1Id == discountCategoryId ?? false,
        );

        const stockItem = row.original.deviations[0].stockItem;

        return (
          <Checkbox
            data-testid={groupedRowDispenseFeeSpecificCheckboxTestId(
              row.original.practiceGroupId,
              row.original.itemId,
            )}
            checked={currentAllDispenseOverride === true}
            indeterminate={currentAllDispenseOverride === undefined}
            onChange={dispenseFeeOverride => {
              if (row.original.deviations) {
                row.original.deviations.forEach(deviationResponse => {
                  setRowDispenseFeeFields(
                    formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                    deviationResponse,
                    user,
                    dispenseFeeOverride,
                  );
                  const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
                    deviationResponse.siteId,
                    deviationResponse.itemId,
                  );
                  formikProps.setFieldValue(
                    `${fieldPrefix}.itemDeviationDispenseFeeOverride`,
                    dispenseFeeOverride,
                  );
                });
              }
            }}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              someItemsHaveOverwiteInGroupedSubRows ||
              someItemsHaveDiscountGroupedSubRows ||
              !stockItem
            }
            warning={initialAllDispenseOverride !== currentAllDispenseOverride}
          />
        );
      } else {
        return null;
      }
    } else {
      if (
        scheduledBulkEditIdBySiteIdThenItemId &&
        scheduledBulkEditIdBySiteIdThenItemId.get(row.original.siteId, row.original.itemId)
      ) {
        const initialFormValues = formikProps?.initialValues as EditDeviationBulkEditFormModel;
        return <span>{!!getIsHiddenForRow(row, initialFormValues) ? t('yes') : t('no')}</span>;
      } else {
        return (
          <CheckboxField
            testId={siteItemDispenseFeeSpecificCheckboxTestId(
              row.original.siteId,
              row.original.itemId,
            )}
            name={`${getDeviationFieldPrefix(row)}.itemDeviationDispenseFeeOverride`}
            useDebouncing={true}
            onChange={dispenseFeeOverride => {
              setRowDispenseFeeFields(
                formikProps as FormikProps<EditDeviationBulkEditFormModel>,
                row.original,
                user,
                dispenseFeeOverride,
              );
            }}
            disabled={
              !userHasOneOfPermissions(
                user,
                editCreateDeviationBulkEditPermissions.deviatePrices,
              ) ||
              row.original.overwrite ||
              row.original.category1Id == discountCategoryId ||
              !row.original.stockItem
            }
            warning={getDispenseFeeCodeSpecificHasChangedForRow(formikProps, row)}
          />
        );
      }
    }
  },
});

export const groupedCentralItemPriceColumn = <T extends DeviationTableRow>(
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): CustomColumn<T, DeviationColumnTypes> => ({
  ...centralItemPriceColumn(t, user, countries),
  Cell: ({ cell, row }: CellProps<T>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedDeviationTableRowCellValue(
        row,
        user.useGrossPrices ? 'centralItemPriceGross' : 'centralItemPriceNet',
        undefined,
      );

      return groupedCellValue == null ? null : (
        <NonEditableValue>
          <AlignedSpan floatRight={true}>
            {formatCurrency(
              groupedCellValue,
              user.locale,
              getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
            )}
          </AlignedSpan>
        </NonEditableValue>
      );
    } else {
      return (
        <NonEditableValue>
          <AlignedSpan floatRight={true}>
            {formatCurrency(
              user.useGrossPrices
                ? cell.row.original.centralItemPriceGross
                : cell.row.original.centralItemPriceNet,
              user.locale,
              getCurrencyForCountry(row.original.siteCountry, user, countries),
            )}
          </AlignedSpan>
        </NonEditableValue>
      );
    }
  },
});

export const groupedCentralItemDispenseFeeColumn = <T extends DeviationTableRow>(
  t: TFunction,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): CustomColumn<T, DeviationColumnTypes> => ({
  ...centralItemDispenseFeeColumn(t, user, countries),
  Cell: ({ cell, row }: CellProps<T>) => {
    if (row.canExpand) {
      const groupedCellValue = getGroupedDeviationTableRowCellValue(
        row,
        user.useGrossPrices ? 'centralItemDispenseFeeGross' : 'centralItemDispenseFeeNet',
        undefined,
      );

      return groupedCellValue == null ? null : (
        <NonEditableValue>
          <AlignedSpan floatRight={true}>
            {formatCurrency(
              groupedCellValue,
              user.locale,
              getCurrencyForCountry(row.subRows[0].original.siteCountry, user, countries),
            )}
          </AlignedSpan>
        </NonEditableValue>
      );
    } else {
      return (
        <NonEditableValue>
          <AlignedSpan floatRight={true}>
            {formatCurrency(
              user.useGrossPrices
                ? cell.row.original.centralItemDispenseFeeGross
                : cell.row.original.centralItemDispenseFeeNet,
              user.locale,
              getCurrencyForCountry(row.original.siteCountry, user, countries),
            )}
          </AlignedSpan>
        </NonEditableValue>
      );
    }
  },
});

export const groupedActionsColumn = <T extends DeviationTableRow>(
  t: TFunction,
): CustomColumn<T, DeviationColumnTypes> => ({
  id: 'actions',
  fitContent: true,
  Cell({ row, formikProps }: CellProps<T>) {
    if (row.canExpand) {
      return (
        <IconButton
          faIcon={faUndo}
          type="button"
          title={t('undoButton')}
          onClick={() => setDeviationBulkEditSubRowsInGroupedRowToInitialValues(formikProps, row)}
        />
      );
    } else {
      return !rowHasOverwritePrice(row) ? (
        <IconButton
          faIcon={faUndo}
          type="button"
          title={t('undoButton')}
          onClick={() => setDeviationBulkEditRowToInitialValues(formikProps, row)}
        />
      ) : null;
    }
  },
});

export const groupedWarningsColumn = <T extends DeviationTableRow>(
  t: TFunction,
): CustomColumn<T, DeviationColumnTypes> => ({
  id: 'deviationWarnings',
  fitContent: true,
  Cell: ({ formikProps, row }: CellProps<GroupedDeviationResponseRow>) => {
    const formikValues = getFormikValuesForDeviationBulkEdit(formikProps);
    if (row.canExpand) {
      const deviationWarnings = getPracticeGroupWarningsForEditableScreens(
        formikValues,
        row as Row<GenericGroupedDeviationRow<DeviationResponse>>,
      );
      return (
        <WarningIconRowContainer>
          <IconRow
            testId="deviationWarnings"
            ariaLabel="warning icons"
            iconsFromNamesMapping={deviationWarningIconsMapping}
            iconNames={deviationWarnings}
            t={t}
          />
        </WarningIconRowContainer>
      );
    } else {
      const deviationWarnings = getWarningsForDeviationRowForEditableScreens(formikValues, row);
      return (
        <WarningIconRowContainer>
          <IconRow
            testId={`deviationWarnings-${row.original.siteId}-${row.original.itemId}`}
            ariaLabel="warning icons"
            iconsFromNamesMapping={deviationWarningIconsMapping}
            iconNames={deviationWarnings}
            t={t}
          />
        </WarningIconRowContainer>
      );
    }
  },
});

const WarningIconRowContainer = styled.div`
  min-width: 100px;
`;

export const setDeviationBulkEditRowToInitialValues = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
) => {
  if (
    !formikProps?.initialValues ||
    isEmpty((formikProps?.initialValues as TFormModel)?.deviations)
  ) {
    return;
  }

  const rowInitialValues = (formikProps?.initialValues as TFormModel).deviations[
    row.original.siteId
  ][row.original.itemId];

  formikProps?.setFieldValue(getDeviationFieldPrefix(row), rowInitialValues);
};

export const setDeviationBulkEditSubRowsInGroupedRowToInitialValues = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
) => {
  if (
    !formikProps?.initialValues ||
    !(formikProps?.initialValues as TFormModel)?.deviations ||
    !row.original.deviations
  ) {
    return;
  }

  const initialValues = (formikProps?.initialValues as EditDeviationBulkEditFormModel).deviations;

  row.original.deviations.forEach(deviation => {
    formikProps?.setFieldValue(
      getDeviationFieldPrefixBySiteAndItem(deviation.siteId, deviation.itemId),
      initialValues[deviation.siteId][deviation.itemId],
    );
  });
};

export const deviationBulkEditValueHasChanged = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
  field: keyof DeviationBulkEditLineFormModel,
): boolean => {
  if (!formikProps?.initialValues || !formikProps?.values) {
    return false;
  }

  const initialValue = getDeviationBulkEditFieldValue(
    formikProps?.initialValues as TFormModel,
    row,
    field,
  );

  const currentValue = getDeviationBulkEditFieldValue(
    formikProps?.values as TFormModel,
    row,
    field,
  );

  return !initialValue && !currentValue ? false : initialValue !== currentValue;
};

const getDeviationBulkEditFieldValue = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formModel: TFormModel,
  row: Row<GenericGroupedDeviationRow<TRow>>,
  field: keyof DeviationBulkEditLineFormModel,
): string | undefined => {
  if (!formModel || isEmpty(formModel.deviations)) {
    return undefined;
  }

  return formModel.deviations[row.original.siteId][row.original.itemId][field] as string;
};

export const deviationHeaderValueHasChanged = <TRow extends DeviationTableRow>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
  field: keyof DeviationBulkEditLineFormModel,
  currentValue: string | undefined,
) => {
  const initialValue = getDeviationHeaderInitialValue(formikProps, row, field);
  return !initialValue && !currentValue ? false : initialValue !== currentValue;
};

export const getDeviationHeaderInitialValue = <
  TRow extends DeviationTableRow,
  TFormModel extends EditDeviationBulkEditFormModel
>(
  formikProps: FormikProps<unknown> | undefined,
  row: Row<GenericGroupedDeviationRow<TRow>>,
  field: keyof DeviationBulkEditLineFormModel,
): string | undefined => {
  if (row.original.deviations && formikProps?.initialValues) {
    const formModelDeviations = getFormModelDeviations(
      row.original.deviations,
      formikProps?.initialValues as TFormModel,
    );
    return practiceGroupHasSubRowWithOverwritePrice(row)
      ? undefined
      : getUniqueValueOrDefault(
          formModelDeviations,
          deviation => deviation[field] as string,
          undefined,
        ) ?? undefined;
  } else {
    return undefined;
  }
};

export const getDeviationHeaderFieldProps = <TRow extends DeviationTableRow>(
  row: Row<GenericGroupedDeviationRow<TRow>>,
  formikProps: FormikProps<unknown> | undefined,
  user: LoginUserResponse,
  field: keyof DeviationBulkEditLineFormModel,
  onFieldChange: (
    siteId: number,
    itemId: number,
    recommendedPrice: number,
    user: LoginUserResponse,
    itemType: string,
  ) => (value: string, form: FormikProps<unknown>) => void,
  getRecommendedPrice: (deviationResponse: GenericGroupedDeviationRow<TRow>) => number,
): {
  fieldValue: string | undefined;
  updateSubRowFields: (event: ChangeEvent<HTMLInputElement>) => void;
} => {
  if (row.original.deviations && formikProps?.values) {
    const formModelDeviations = getFormModelDeviations(
      row.original.deviations,
      formikProps?.values as CreateDeviationBulkEditFormModel,
    );

    const fieldValue = practiceGroupHasSubRowWithOverwritePrice(row)
      ? undefined
      : getUniqueValueOrDefault(
          formModelDeviations,
          deviation => deviation[field] as string,
          undefined,
        ) ?? undefined;

    const updateSubRowFields = (event: ChangeEvent<HTMLInputElement>) => {
      row.original.deviations?.forEach(deviationResponse => {
        const newValue = event.target.value;
        const fieldPrefix = getDeviationFieldPrefixBySiteAndItem(
          deviationResponse.siteId,
          deviationResponse.itemId,
        );
        formikProps.setFieldValue(`${fieldPrefix}.${field}`, newValue);
        onFieldChange(
          deviationResponse.siteId,
          deviationResponse.itemId,
          getRecommendedPrice(deviationResponse),
          user,
          deviationResponse.itemType,
        )(newValue, formikProps);
      });
    };
    return { fieldValue, updateSubRowFields };
  } else {
    return { fieldValue: undefined, updateSubRowFields: () => null };
  }
};
