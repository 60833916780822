import { TFunction } from 'i18next';
import * as React from 'react';
import { Table } from '../../../shared/tables/Table';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { styled } from '../../../styling/theme';
import { BarcodeDetails } from '../item';

const Container = styled.div`
  margin-bottom: ${styleProps => styleProps.theme.spacing.small}px;
`;

type ItemBarcodesProps = {
  barcodes: Array<BarcodeDetails> | null | undefined;
  t: TFunction;
};

export const ItemBarcodes = (props: ItemBarcodesProps) => {
  const columns: Array<CustomColumn<BarcodeDetails>> = [
    {
      Header: props.t<string>('itemList.tableHeadings.manufacturer'),
      accessor: (row: BarcodeDetails) => row.manufacturer,
      id: 'manufacturer',
    },
    {
      Header: props.t<string>('itemList.tableHeadings.barcode'),
      accessor: (row: BarcodeDetails) => row.barcode,
      id: 'barcode',
    },
  ];

  return (
    <Container>
      <h4>{props.t('view.sectionTitle.barcodeProducts')}</h4>
      {props.barcodes && (
        <Table
          columns={columns}
          data={props.barcodes.filter(x => x.barcode !== '')}
          emptyTableMessage={props.t('itemList.emptyBarcodeTableMessage')}
        />
      )}
    </Container>
  );
};
