import * as React from 'react';
import { css, styled } from '../../styling/theme';
import { ApiErrorBox } from '../errors/ApiErrorBox';
import { SortingIndicator } from './sorting/SortingComponents';

export const TableContainer = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  width: fit-content;
`;
export const LoadingOverlay = styled.div<
  React.HTMLAttributes<HTMLDivElement> & { showLoadingOverlay: boolean }
>`
  background: ${props => props.theme.colours.loadingOverlay};
  display: ${props => (props.showLoadingOverlay ? 'initial' : 'none')};
  opacity: 0.5;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
export const StyledTable = styled.table`
  background-color: ${props => props.theme.colours.componentBackground};
  border-collapse: collapse;
  border: 1px solid ${props => props.theme.colours.border};
`;
export const StyledHeader = styled.thead<
  React.TableHTMLAttributes<HTMLTableCellElement> & { disableStickyHeader?: boolean }
>`
    ${props =>
      !props.disableStickyHeader &&
      css`
        position: sticky;
        top: 0;
        z-index: 1;
      `}

    border: 1px solid ${props => props.theme.colours.border};
    font-weight: bold;
`;
export const StyledTh = styled.th<
  React.TableHTMLAttributes<HTMLTableCellElement> & {
    isRightAligned?: boolean;
    fitContent?: boolean;
  }
>`
  ${props =>
    props.fitContent &&
    css`
      width: 0;
    `}

  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props => props.theme.colours.componentBackground};
`;
export const GroupedHeaderTh = styled(StyledTh)<{ colSpan?: number }>`
  text-align: center;
  border: 1px solid ${props => props.theme.colours.border};
  border-left: 1px solid ${props => props.theme.colours.border};
`;
export const StyledSortIndicator = styled(SortingIndicator)`
  padding-left: ${props => props.theme.spacing.extraSmall}px;
`;
export const StyledRow = styled.tr`
  border: 1px solid ${props => props.theme.colours.border};
`;
export const StyledTd = styled.td<
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    canExpand?: boolean;
    isRightAligned?: boolean;
    hasChildWithError?: boolean;
  }
>`
  padding: 0.5rem;
  text-align: ${props => (props.isRightAligned != null && props.isRightAligned ? 'right' : 'left')};
  background-color: ${props =>
    props.canExpand
      ? props.theme.colours.expandableTableRow
      : props.theme.colours.componentBackground};
  background-color: ${props =>
    props.hasChildWithError
      ? props.theme.colours.alertBackground
      : props.theme.colours.componentBackground};
`;
export const StyledErrorBox = styled(ApiErrorBox)`
  margin-top: ${props => props.theme.spacing.small}px;
`;
