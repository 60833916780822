import React, { useCallback, useContext, useMemo, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { RequiresOneOfPermissions } from '../../../../shared/Permissions';
import { CustomColumn } from '../../../../shared/tables/Table.types';
import {
  createEditTreatmentTypeDeviationsPermissions,
  CreateTreatmentTypeDeviationBulkEditCommand,
  CreateTreatmentTypeDeviationBulkEditFormModel,
  CreateTreatmentTypeDeviationBulkEditValidator,
  getTreatmentTypeDeviationFieldPrefix,
  TreatmentTypeDeviationBulkEditCommandLine,
  TreatmentTypeDeviationBulkEditLineValidator,
} from './treatmentTypeDeviationBulkEdit';
import {
  createTreatmentTypeDeviationBulkEdit,
  getTreatmentTypeDeviationFilterDropdownOptions,
} from '../../deviationsApi';
import { DataLoader, DataLoaderWithParams } from '../../../../shared/DataLoader';
import { getItemFieldsFromPms } from '../../../items/itemsApi';
import { getAllPracticeGroups } from '../../../sites/practiceGroups/practiceGroupsApi';
import { UserContext } from '../../../authentication/loginData/userContext';
import {
  getGroupedTreatmentTypeDeviationSubRows,
  mapTreatmentTypeDeviationsResponseToGroupedTableData,
  TreatmentTypeDeviationsBaseProps,
} from '../TreatmentTypeDeviationsBase';
import { useTranslation } from 'react-i18next';
import { TupleKeyDictionary } from '../../../../utils/TupleKeyDictionary';
import { Id } from '../../../../models/id';
import { useNavigateAwayWarning } from '../../../../shared/hooks/useNavigateAwayWarning';
import { useTreatmentTypeDeviationTableState } from '../useTreatmentTypeDeviationTableState';
import {
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationColumnTypes,
  treatmentTypeDeviationsPageSize,
  TreatmentTypeDeviationsResponse,
  validTreatmentTypeDeviationColumnTypeOrUndefined,
} from '../treatmentTypeDeviations';
import { TFunction } from 'i18next';
import { PracticeGroupResponse } from '../../../sites/practiceGroups/practiceGroup';
import {
  getGroupedTreatmentTypeDeviationRowDispenseFeePercentageTestId,
  getGroupedTreatmentTypeDeviationRowPricePercentageTestId,
  getTreatmentTypeDeviationHeaderFieldProps,
  groupedActionsColumn,
  treatmentTypeDeviationBulkEditValueHasChanged,
  treatmentTypeDeviationExpandedColumn,
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn,
  treatmentTypeDeviationHeaderValueHasChanged,
  treatmentTypeDeviationTreatmentTypeNameColumn,
  treatmentTypeDispenseFeeDeviationPercentColumn,
  treatmentTypePriceDeviationPercentColumn,
  groupedWarningsColumnForEditablePages,
  getTreatmentTypeDeviationRowPricePercentageTestId,
  getTreatmentTypeDeviationRowDispenseFeePercentageTestId,
} from '../SharedTreatmentTypeDeviationBulkEditColumns';
import { CellProps } from 'react-table';
import { flatMap, forEach, keyBy, map, mapValues, round, some } from 'lodash';
import { PercentInput } from '../../../../shared/forms/PercentInput';
import { LoginUserResponse, userHasOneOfPermissions } from '../../../authentication/loginData/user';
import { ControlledInput } from '../../../../shared/forms/ControlledInput';
import { PercentField } from '../../../../shared/forms/PercentField';
import {
  getPaginationDetailsFromPagedResponse,
  PaginationDetails,
} from '../../../../shared/tables/pagination/pagination';
import { getLocalNow } from '../../../../models/dates/now';
import { formatNumber, formatNumberForFields, parseNumber } from '../../../../utils/numberUtils';
import { assertIsDefined } from '../../../../utils/assertIsDefined';
import { Title } from 'react-head';
import { DeviationsScheduledForUpdateInfoBar } from '../../DeviationsBase';
import { InfoBox } from '../../../../shared/info/InfoBox';
import { MarginRightIcon } from '../../../../shared/StyledIcons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { styled } from '../../../../styling/theme';
import { dashboardUrl, treatmentTypeDeviationsUrl } from '../../../../urls';
import { HollowLinkButton } from '../../../../shared/buttons/LinkButton';
import { FormikTable } from '../../../../shared/tables/FormikTable';
import { navigate } from '../../../../utils/routing';
import { InputField } from '../../../../shared/forms/InputField';
import { CheckboxField } from '../../../../shared/forms/CheckboxField';
import { DateField } from '../../../../shared/forms/DateField';
import { AlignedSpan } from '../../DeviationColumns';

type OwnProps = RouteComponentProps & {};
type Props = OwnProps;

export const CreateTreatmentTypeDeviationBulkEdit = (props: Props) => {
  const { user } = useContext(UserContext);
  return (
    <RequiresOneOfPermissions permissions={createEditTreatmentTypeDeviationsPermissions}>
      <DataLoader apiRequest={getTreatmentTypeDeviationFilterDropdownOptions}>
        {filterDropdownOptions => (
          <DataLoader apiRequest={getItemFieldsFromPms}>
            {itemFields => (
              <DataLoaderWithParams
                apiRequest={getAllPracticeGroups}
                getParams={() => user.organisationGroupId}
              >
                {practiceGroupsRequest => (
                  <CreateTreatmentTypeDeviationBulkEditComponent
                    filterDropdownOptions={filterDropdownOptions.response}
                    itemFieldsFromPms={itemFields.response}
                    practiceGroups={practiceGroupsRequest.response.practiceGroups}
                  />
                )}
              </DataLoaderWithParams>
            )}
          </DataLoader>
        )}
      </DataLoader>
    </RequiresOneOfPermissions>
  );
};

const ReturnToListButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

export const treatmentTypeDeviationsMultiplePagesInfoBoxTestId =
  'treatment-type-deviations-multiple-pages-info-box-test-id';

type CreateTreatmentTypeDeviationBulkEditProps = TreatmentTypeDeviationsBaseProps;

const CreateTreatmentTypeDeviationBulkEditComponent = (
  props: CreateTreatmentTypeDeviationBulkEditProps,
) => {
  const { t } = useTranslation(['treatmentTypeDeviations', 'navigateAwayMessages']);
  const { user } = useContext(UserContext);
  const [
    scheduledBulkEditIdBySiteIdThenTreatmentTypeId,
    setScheduledBulkEditIdBySiteIdThenTreatmentTypeId,
  ] = useState<TupleKeyDictionary<Id, Id, Id> | null>(null);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetails | null>(null);

  useNavigateAwayWarning({
    shouldConfirmNavigation: true,
    message: t('navigateAwayMessages:bulkEditForm'),
  });

  const { getFilteredData, onFilteredResponse } = useTreatmentTypeDeviationTableState(props);

  const practiceGroupsById = useMemo(
    () => keyBy(props.practiceGroups, practiceGroup => practiceGroup.practiceGroupId),
    [props.practiceGroups],
  );

  const columns: Array<CustomColumn<
    GroupedTreatmentTypeDeviationResponseRow,
    TreatmentTypeDeviationColumnTypes
  >> = useMemo(
    () => getColumns(t, user, scheduledBulkEditIdBySiteIdThenTreatmentTypeId, practiceGroupsById),
    [t, scheduledBulkEditIdBySiteIdThenTreatmentTypeId, practiceGroupsById],
  );

  const onDataFetchSuccess = (response: TreatmentTypeDeviationsResponse) => {
    onFilteredResponse(response);

    const { pageNumber, pageSize, totalItems } = response;
    setPaginationDetails(
      getPaginationDetailsFromPagedResponse({ pageNumber, pageSize, totalItems }),
    );

    const scheduledBulkEditIds = new TupleKeyDictionary<Id, Id, Id>();

    forEach(response.deviationResponsesAndScheduledBulkEdits.scheduledBulkEdits, schedule =>
      scheduledBulkEditIds.add(
        schedule.siteId,
        schedule.treatmentTypeId,
        schedule.scheduledDeviationBulkEditId,
      ),
    );

    setScheduledBulkEditIdBySiteIdThenTreatmentTypeId(scheduledBulkEditIds);
  };

  const apiDataToFormModel = useCallback(
    (
      response: TreatmentTypeDeviationsResponse | null,
    ): CreateTreatmentTypeDeviationBulkEditFormModel => {
      if (response === null) {
        return {
          name: null,
          runImmediately: false,
          scheduledDate: getLocalNow(user.locale).toISODate(),
          deviations: {},
        };
      }

      const formModel: CreateTreatmentTypeDeviationBulkEditFormModel = {
        name: null,
        runImmediately: false,
        scheduledDate: getLocalNow(user.locale).toISODate(),
        deviations: {},
      };

      forEach(response.deviationResponsesAndScheduledBulkEdits.deviationResponses, deviation => {
        if (
          scheduledBulkEditIdBySiteIdThenTreatmentTypeId &&
          scheduledBulkEditIdBySiteIdThenTreatmentTypeId.get(
            deviation.siteId,
            deviation.treatmentTypeId,
          )
        ) {
          return;
        }

        if (formModel.deviations[deviation.siteId] === undefined) {
          formModel.deviations[deviation.siteId] = {};
        }

        formModel.deviations[deviation.siteId][deviation.treatmentTypeId] = {
          deviationId: deviation.deviationId,
          percentagePriceDeviation: formatNumberForFields(
            deviation.priceDeviationPercentValue,
            user.locale,
          ),
          percentageDispenseFeeDeviation: formatNumberForFields(
            deviation.dispenseFeeDeviationPercentValue,
            user.locale,
          ),
        };
      });

      return formModel;
    },
    [user],
  );

  const mapTreatmentTypeDeviationFormModelToBulkEditRequest = (
    values: CreateTreatmentTypeDeviationBulkEditFormModel,
  ): CreateTreatmentTypeDeviationBulkEditCommand => {
    return {
      name: assertIsDefined(values.name),
      runImmediately: values.runImmediately,
      scheduledDate: values.scheduledDate,
      deviations: flatMap(values.deviations, (siteTreatmentTypes, siteId) =>
        map(
          siteTreatmentTypes,
          (value, treatmentTypeId): TreatmentTypeDeviationBulkEditCommandLine => ({
            deviationId: value.deviationId,
            siteId: Number(siteId),
            treatmentTypeId: Number(treatmentTypeId),
            percentagePriceDeviation: parseNumber(value.percentagePriceDeviation, user.locale),
            percentageDispenseFeeDeviation: parseNumber(
              value.percentageDispenseFeeDeviation,
              user.locale,
            ),
          }),
        ),
      ),
    };
  };

  const validateBulkEditTable = (values: CreateTreatmentTypeDeviationBulkEditFormModel) => {
    const validator = new CreateTreatmentTypeDeviationBulkEditValidator(t, user.locale);

    const topLevelErrors = validator.validate(values);
    const perDeviationValidator = new TreatmentTypeDeviationBulkEditLineValidator(t, user.locale);
    const deviationsErrors = mapValues(values.deviations, items =>
      mapValues(items, value => perDeviationValidator.validate(value)),
    );

    if (
      some(deviationsErrors, errorsBySite => some(errorsBySite, rowErrors => some(rowErrors))) ||
      some(topLevelErrors)
    ) {
      return {
        ...topLevelErrors,
        deviations: deviationsErrors,
      };
    }

    return {};
  };

  return (
    <>
      <Title>{t('createBulkEdit.title')}</Title>
      <h1>{t('createBulkEdit.heading')}</h1>
      {scheduledBulkEditIdBySiteIdThenTreatmentTypeId &&
        some(scheduledBulkEditIdBySiteIdThenTreatmentTypeId.dictionary) && (
          <DeviationsScheduledForUpdateInfoBar message={t('createBulkEdit.scheduledBulkEdit')} />
        )}
      {!!paginationDetails && paginationDetails.totalPages > 1 && (
        <InfoBox
          testId={treatmentTypeDeviationsMultiplePagesInfoBoxTestId}
          message={
            <>
              <MarginRightIcon icon={faInfoCircle} />
              {t('createBulkEdit.multiplePages', {
                page: formatNumber(paginationDetails.pageNumber, user.locale),
                totalPages: formatNumber(paginationDetails.totalPages, user.locale),
              })}
            </>
          }
        />
      )}
      <ReturnToListButtonContainer>
        <HollowLinkButton to={treatmentTypeDeviationsUrl(window.location.search)}>
          {t('createBulkEdit.returnToList')}
        </HollowLinkButton>
      </ReturnToListButtonContainer>
      <FormikTable
        validate={validateBulkEditTable}
        enableReinitialize={true}
        allowPagination={false}
        disableFormOnSuccess={true}
        apiDataToFormModel={apiDataToFormModel}
        submitRequest={createTreatmentTypeDeviationBulkEdit}
        mapValuesToRequestParameters={mapTreatmentTypeDeviationFormModelToBulkEditRequest}
        columns={columns}
        pageSize={treatmentTypeDeviationsPageSize}
        getApiData={getFilteredData}
        mapResponseToTableData={mapTreatmentTypeDeviationsResponseToGroupedTableData}
        getSubRows={getGroupedTreatmentTypeDeviationSubRows}
        emptyTableMessage={t('createBulkEdit.emptyTableMessage')}
        validColumnTypeOrUndefined={validTreatmentTypeDeviationColumnTypeOrUndefined}
        onDataFetchSuccess={onDataFetchSuccess}
        onSubmitComplete={() => {
          navigate(dashboardUrl(), {
            suppressUserConfirmation: true,
            state: { successfulTreatmentTypeDeviationBulkEdit: true },
          });
        }}
        additionalFormFields={formikBag => (
          <>
            <CheckboxField name="runImmediately" label={t('createBulkEdit.runImmediately')} />
            <InputField name="name" label={t('createBulkEdit.bulkEditName')} useDebouncing={true} />
            {!formikBag.values?.runImmediately && (
              <DateField
                name="scheduledDate"
                label={t('createBulkEdit.scheduledDate.label')}
                infoText={t('createBulkEdit.scheduledDate.infoText')}
                useDebouncing={true}
              />
            )}
          </>
        )}
      />
    </>
  );
};

const getColumns = (
  t: TFunction,
  user: LoginUserResponse,
  scheduledBulkEditIdBySiteIdThenTreatmentTypeId: TupleKeyDictionary<Id, Id, Id> | null,
  practiceGroupsById: { [practiceGroupId: number]: PracticeGroupResponse },
): Array<CustomColumn<
  GroupedTreatmentTypeDeviationResponseRow,
  TreatmentTypeDeviationColumnTypes
>> => [
  treatmentTypeDeviationExpandedColumn(),
  treatmentTypeDeviationGroupedPracticeGroupOrSiteNameColumn(
    t,
    scheduledBulkEditIdBySiteIdThenTreatmentTypeId,
    practiceGroupsById,
  ),
  treatmentTypeDeviationTreatmentTypeNameColumn(t),
  {
    ...treatmentTypePriceDeviationPercentColumn(t),
    isRightAligned: false,
    Cell: ({ cell, row, formikProps }: CellProps<GroupedTreatmentTypeDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getTreatmentTypeDeviationHeaderFieldProps(
          row,
          formikProps,
          'percentagePriceDeviation',
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedTreatmentTypeDeviationRowPricePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.treatmentTypeId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)
                }
                warning={treatmentTypeDeviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentagePriceDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return scheduledBulkEditIdBySiteIdThenTreatmentTypeId &&
          scheduledBulkEditIdBySiteIdThenTreatmentTypeId.get(
            row.original.siteId,
            row.original.treatmentTypeId,
          ) ? (
          cell.value === null ? (
            <span />
          ) : (
            <AlignedSpan floatRight={true}>{round(cell.value, 4)}%</AlignedSpan>
          )
        ) : (
          <PercentField
            testId={getTreatmentTypeDeviationRowPricePercentageTestId(
              row.original.treatmentTypeId,
              row.original.siteId,
            )}
            bulkVariant={true}
            hideLabel={true}
            name={`${getTreatmentTypeDeviationFieldPrefix(row)}.percentagePriceDeviation`}
            label={t('columnHeaders.priceDeviationPercentValue')}
            useDebouncing={true}
            isRightAligned={true}
            disabled={!userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)}
            warning={treatmentTypeDeviationBulkEditValueHasChanged(
              formikProps,
              row,
              'percentagePriceDeviation',
            )}
          />
        );
      }
    },
  },
  {
    ...treatmentTypeDispenseFeeDeviationPercentColumn(t),
    isRightAligned: false,
    Cell: ({ cell, row, formikProps }: CellProps<GroupedTreatmentTypeDeviationResponseRow>) => {
      if (row.canExpand) {
        const { fieldValue, updateSubRowFields } = getTreatmentTypeDeviationHeaderFieldProps(
          row,
          formikProps,
          'percentageDispenseFeeDeviation',
        );
        return (
          <ControlledInput initialValue={fieldValue}>
            {({ value, onChange }) => (
              <PercentInput
                data-testid={getGroupedTreatmentTypeDeviationRowDispenseFeePercentageTestId(
                  row.original.practiceGroupId,
                  row.original.treatmentTypeId,
                )}
                value={value}
                onChange={onChange}
                bulkVariant={true}
                onBlurIfChange={updateSubRowFields}
                isRightAligned={true}
                disabled={
                  !userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)
                }
                warning={treatmentTypeDeviationHeaderValueHasChanged(
                  formikProps,
                  row,
                  'percentageDispenseFeeDeviation',
                  value,
                )}
              />
            )}
          </ControlledInput>
        );
      } else {
        return scheduledBulkEditIdBySiteIdThenTreatmentTypeId &&
          scheduledBulkEditIdBySiteIdThenTreatmentTypeId.get(
            row.original.siteId,
            row.original.treatmentTypeId,
          ) ? (
          cell.value === null ? (
            <span />
          ) : (
            <AlignedSpan floatRight={true}>{round(cell.value, 4)}%</AlignedSpan>
          )
        ) : (
          <PercentField
            testId={getTreatmentTypeDeviationRowDispenseFeePercentageTestId(
              row.original.treatmentTypeId,
              row.original.siteId,
            )}
            bulkVariant={true}
            hideLabel={true}
            name={`${getTreatmentTypeDeviationFieldPrefix(row)}.percentageDispenseFeeDeviation`}
            label={t('columnHeaders.dispenseFeeDeviationPercentValue')}
            useDebouncing={true}
            isRightAligned={true}
            disabled={!userHasOneOfPermissions(user, createEditTreatmentTypeDeviationsPermissions)}
            warning={treatmentTypeDeviationBulkEditValueHasChanged(
              formikProps,
              row,
              'percentageDispenseFeeDeviation',
            )}
          />
        );
      }
    },
  },
  groupedActionsColumn(t),
  groupedWarningsColumnForEditablePages(t),
];
