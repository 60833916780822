import { useCallback, useEffect } from 'react';
import { NumberParam, StringParam, useQueryParam } from 'use-query-params';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { ApiError } from '../../models/error';
import { useApiRequest } from '../hooks/useApiRequest';
import { SortDirection, validSortDirectionOrUndefined } from './sorting/sorting';
import { TableDataFetchRequest, TableDataFetchResponse } from './Table.types';

export type UseManualTableHandlingConfig<TApiData, TColumnNames extends string> = {
  pageSize: number;
  dynamicPageSize: boolean;
  getApiData: AuthenticatedApiRequest<TApiData, TableDataFetchRequest<TColumnNames>>;
  onDataFetchSuccess?: (response: TApiData) => void;
  validColumnTypeOrUndefined?: ((input: string) => TColumnNames | undefined) | null;
  shouldRestrictFetching?: boolean;
};

export type UseManualTableHandlingResult<TApiData, TColumnNames extends string> = {
  pagedApiData: TApiData | null;
  loading: boolean;
  apiError: ApiError | null;
  apiPageSize: number;
  pageCount: number;
  totalItems: number;
  apiPageNumber: number;
  urlPageNumber?: number;
  fetchNewData: (fetchRequest: TableDataFetchRequest<TColumnNames>) => void;
  goToPage: (pageNumber: number) => void;
  canNextPage: boolean;
  canPrevPage: boolean;
  urlSortByColumn?: TColumnNames;
  urlSortDirection?: SortDirection;
  toggleSortBy: (columnId: string | number) => () => void;
};

export const clampPageNumber = (pageNumber: number, pageCount?: number) => {
  if (pageCount) {
    if (pageNumber > pageCount) {
      return pageCount;
    } else if (pageNumber < 1) {
      return 1;
    } else {
      return pageNumber;
    }
  }
  return pageNumber;
};

export const useManualTableHandling = <
  TApiData extends TableDataFetchResponse<TColumnNames>,
  TColumnNames extends string = string
>(
  config: UseManualTableHandlingConfig<TApiData, TColumnNames>,
): UseManualTableHandlingResult<TApiData, TColumnNames> => {
  const [urlStatePageNumber, setUrlStatePageNumber] = useQueryParam('pageNumber', NumberParam);
  const [urlStateSortBy, setUrlStateSortBy] = useQueryParam('sortBy', StringParam);
  const [urlStateSortDirection, setUrlStateSortDirection] = useQueryParam(
    'sortDirection',
    StringParam,
  );

  if (urlStatePageNumber === undefined) {
    setUrlStatePageNumber(1, 'replaceIn');
  }

  const { makeRequest, inProgress, apiError, response } = useApiRequest(config.getApiData);

  const getPagedDataCallback = useCallback(
    (dataRequest: TableDataFetchRequest<TColumnNames>) => {
      makeRequest(dataRequest).then((result: TApiData | null) => {
        if (result) {
          setUrlStatePageNumber(result.pageNumber, 'replaceIn');
          setUrlStateSortBy(result.sortBy, 'replaceIn');
          setUrlStateSortDirection(result.direction, 'replaceIn');

          if (config.onDataFetchSuccess) {
            config.onDataFetchSuccess(result);
          }
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [urlStatePageNumber, config.getApiData],
  );

  const apiPageSize = config.dynamicPageSize
    ? config.pageSize
    : response
    ? response.pageSize
    : config.pageSize;

  const pageCount = response ? Math.ceil(response.totalItems / response.pageSize) : undefined;

  const clampedPageNumber = urlStatePageNumber ? clampPageNumber(urlStatePageNumber, pageCount) : 1;
  const pageNumber = response ? response.pageNumber : clampedPageNumber;

  const urlSortBy =
    urlStateSortBy &&
    config.validColumnTypeOrUndefined &&
    config.validColumnTypeOrUndefined(urlStateSortBy);
  const urlSortDirection =
    urlStateSortDirection && validSortDirectionOrUndefined(urlStateSortDirection);

  useEffect(() => {
    if (!config.shouldRestrictFetching && getPagedDataCallback && urlStatePageNumber) {
      getPagedDataCallback({
        pageNumber: urlStatePageNumber,
        pageSize: apiPageSize,
        direction: urlSortBy ? urlSortDirection || undefined : undefined,
        sortBy: urlSortBy || undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPagedDataCallback, urlStatePageNumber, urlSortBy, urlSortDirection]);

  const goToPage = (page: number) => setUrlStatePageNumber(page, 'pushIn');
  const canNextPage = !!pageCount && pageNumber + 1 <= pageCount;
  const canPrevPage = pageNumber - 1 > 0;

  const toggleSortBy = (columnId: string | number) => () => {
    if (urlStateSortBy === columnId.toString()) {
      if (urlStateSortDirection === 'desc') {
        setUrlStateSortBy(undefined, 'pushIn');
        setUrlStateSortDirection(undefined, 'pushIn');
      } else {
        setUrlStateSortDirection('desc', 'pushIn');
      }
    } else {
      setUrlStateSortBy(columnId.toString(), 'pushIn');
      setUrlStateSortDirection('asc', 'pushIn');
      setUrlStatePageNumber(1, 'pushIn');
    }
  };

  return {
    pagedApiData: response,
    loading: inProgress,
    apiError,
    apiPageSize,
    pageCount: pageCount || 1,
    apiPageNumber: response ? response.pageNumber : 1,
    totalItems: response ? response.totalItems : 10,
    fetchNewData: getPagedDataCallback,
    urlSortByColumn: urlSortBy || undefined,
    urlSortDirection: urlSortDirection || undefined,
    urlPageNumber: urlStatePageNumber,
    goToPage,
    canNextPage,
    canPrevPage,
    toggleSortBy,
  };
};
