import { omit } from 'lodash';
import { client, secureGet, securePost, securePostFile } from '../../api/apiHttpClient';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { FileResponse } from '../../models/fileResponse';
import { Id } from '../../models/id';
import { PagedRequest } from '../../shared/tables/pagination/pagination';
import { TableDataFetchRequest } from '../../shared/tables/Table.types';
import {
  CreateItemCommand,
  CreateOrEditItemCommandWrapper,
  EditItemCommand,
  GetItemsCommand,
  GetItemsDownloadCommand,
  IsEditCommand,
  ItemColumnTypes,
  ItemFilterOptions,
  ItemResponse,
  ItemsResponse,
  UploadItemBulkEditResponse,
  UploadItemsResponse,
} from './item';
import {
  ItemBulkEditResponse,
  ItemBulkEditsResponse,
  RecentItemBulkEditsResponse,
} from './itemBulkEdit';
import { ItemFieldsFromPmsResponse } from './PmsFields/pmsFields';
import { DashboardFilterSelection } from '../dashboard/models/DashboardFilterSelection';
import { ResetCreationRequestResponse } from './ResetFailedItems/ResetFailedItems.types';

export const getItem: AuthenticatedApiRequest<ItemResponse, Id> = itemId =>
  secureGet(`items/${itemId}`);

export const getItems = (
  filterOptions?: ItemFilterOptions,
): AuthenticatedApiRequest<ItemsResponse, TableDataFetchRequest<ItemColumnTypes>> => fetchRequest =>
  securePost<ItemsResponse, GetItemsCommand>('items', { ...fetchRequest, filterOptions });

export const createItem: AuthenticatedApiRequest<ItemResponse, CreateItemCommand> = item =>
  securePost(`items/create`, item);

export const editItem: AuthenticatedApiRequest<ItemResponse, EditItemCommand> = item =>
  securePost(`items/edit`, item);

export const createOrEditItem: AuthenticatedApiRequest<
  ItemResponse,
  CreateOrEditItemCommandWrapper
> = itemCommand => {
  const createOrEditCommand = itemCommand.command;
  if (IsEditCommand(createOrEditCommand, itemCommand.createOrEditTag)) {
    const editCommand = omit(createOrEditCommand, [
      'itemCode',
      'type',
      'category1Id',
      'isHiddenForAllPractices',
    ]) as EditItemCommand;
    return editItem(editCommand);
  } else {
    const createCommand = omit(createOrEditCommand, ['itemId', 'isDisabled']) as CreateItemCommand;
    return createItem(createCommand);
  }
};

export const getItemFieldsFromPms: AuthenticatedApiRequest<ItemFieldsFromPmsResponse, void> = () =>
  secureGet('items/pmsFields');

export const getItemBulkEdit = (
  itemBulkEditId: Id,
): AuthenticatedApiRequest<ItemBulkEditResponse, PagedRequest> => pagedCommand =>
  securePost(`items/bulk-edit`, { ...pagedCommand, itemBulkEditId });

export const cancelItemBulkEdit: AuthenticatedApiRequest<
  ItemBulkEditResponse,
  Id
> = itemBulkEditId => securePost(`items/bulk-edit/${itemBulkEditId}/cancel`, {});

export const uploadItems: AuthenticatedApiRequest<UploadItemsResponse, FormData> = (
  data: FormData,
) => securePostFile(`items/upload`, data);

export const uploadItemBulkEdit: AuthenticatedApiRequest<UploadItemBulkEditResponse, FormData> = (
  data: FormData,
) => securePostFile(`items/bulk-edit/upload`, data);

export const getItemsDownload: AuthenticatedApiRequest<FileResponse, GetItemsDownloadCommand> = (
  command: GetItemsDownloadCommand,
) => securePost('items/download', command);

export const getItemBulkEdits: AuthenticatedApiRequest<
  ItemBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`items/upcoming-bulk-edits`, filter);
};

export const getRecentItemBulkEdits: AuthenticatedApiRequest<
  RecentItemBulkEditsResponse,
  DashboardFilterSelection | undefined
> = (dashboardFilterSelection: DashboardFilterSelection | undefined) => {
  const filter: DashboardFilterSelection = {
    filename: dashboardFilterSelection?.filename ?? null,
    scheduledDate: dashboardFilterSelection?.scheduledDate ?? null,
    sites: dashboardFilterSelection?.sites ?? null,
    users: dashboardFilterSelection?.users ?? null,
  };
  return securePost(`items/bulk-edit/recent`, filter);
};

export const getResetCreationRequest = async (
  codes: Array<string>,
  token: string,
  abortSignal?: AbortSignal,
): Promise<ResetCreationRequestResponse> => {
  const { data } = await client.get<ResetCreationRequestResponse>('items/reset-creation-request', {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      codes,
    },
    // required to convert the array to codes=code1&codes=code2 etc.
    paramsSerializer: {
      indexes: null,
    },
    ...(abortSignal ? { signal: abortSignal } : {}),
  });

  return data;
};

export const patchResetCreationRequest = async (
  itemIds: Array<number>,
  token: string,
  abortSignal?: AbortSignal,
) => {
  const { data } = await client.patch('items/reset-creation-request', itemIds, {
    headers: { Authorization: `Bearer ${token}` },
    ...(abortSignal ? { signal: abortSignal } : {}),
  });

  return data;
};
