import { TFunction } from 'i18next';
import * as React from 'react';
import { Table } from '../../../shared/tables/Table';
import { CustomColumn } from '../../../shared/tables/Table.types';
import { styled } from '../../../styling/theme';
import { LabelDispensingNote } from '../item';

const Container = styled.div`
  margin-bottom: ${props => props.theme.spacing.small}px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: ${props => props.theme.spacing.extraTiny}px;
`;

type ItemDispensingLabelsProps = {
  dispensingLabels: Array<LabelDispensingNote> | null | undefined;
  t: TFunction;
};

export const ItemDispensingLabels = (props: ItemDispensingLabelsProps) => {
  const columns: Array<CustomColumn<LabelDispensingNote>> = [
    {
      Header: props.t<string>('itemList.tableHeadings.dispensingLabelDescription'),
      accessor: (row: LabelDispensingNote) => row.optionDescription,
      id: 'description',
    },
    {
      Header: props.t<string>('itemList.tableHeadings.dispensingLabelViewOrder'),
      accessor: (row: LabelDispensingNote) => row.viewOrder,
      id: 'viewOrder',
    },
  ];

  return (
    <Container>
      <Title>{props.t('itemList.tableHeadings.dispensingLabelTableTitle')}</Title>
      {props.dispensingLabels && (
        <Table
          columns={columns}
          data={props.dispensingLabels
            .filter(x => x.viewOrder !== 0)
            .sort((x, y) => (x.viewOrder ?? 0) - (y.viewOrder ?? 0))}
          emptyTableMessage={props.t('itemList.emptyDispensingLabelTableMessage')}
        />
      )}
    </Container>
  );
};
