import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ControllerStateAndHelpers } from 'downshift';
import { FormikProps } from 'formik';
import { Dictionary, filter, keyBy, round } from 'lodash';
import { default as React, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';
import { AuthenticatedApiRequest } from '../../models/apiRequest';
import { Id } from '../../models/id';
import { ButtonLabel, HollowButton } from '../../shared/buttons/Button';
import { MultiSelectDropdownProps } from '../../shared/dropdowns/MultiSelectDropdown';
import {
  useMultiSelectDropdown,
  UseMultiSelectDropdownResult,
} from '../../shared/dropdowns/useMultiSelectDropdown';
import { DropdownOption, DropdownOptions } from '../../shared/forms/DropdownField';
import { InfoBox } from '../../shared/info/InfoBox';
import { MarginRightIcon } from '../../shared/StyledIcons';
import { useFiltering, UseFilteringResult } from '../../shared/tables/filtering/useFiltering';
import { TableDataFetchRequest } from '../../shared/tables/Table.types';
import { styled } from '../../styling/theme';
import { parseNumber } from '../../utils/numberUtils';
import { LoginUserResponse } from '../authentication/loginData/user';
import { UserContext } from '../authentication/loginData/userContext';
import { ItemFieldsFromPmsResponse } from '../items/PmsFields/pmsFields';
import { PracticeGroupResponse } from '../sites/practiceGroups/practiceGroup';
import { CreateDeviationBulkEditFormModel } from './bulkEdit/deviationBulkEdit';
import { GroupedDeviationResponseRow } from './bulkEdit/SharedDeviationBulkEditColumns';
import { getDeviatedValue } from './bulkEdit/ViewEditDeviationBulkEditShared';
import {
  DevationSource,
  DeviationColumnTypes,
  DeviationFilterDropdownOptionsResponse,
  DeviationFilterOptions,
  DeviationResponse,
  DeviationsResponse,
  DeviationTableRow,
  NameAndId,
} from './deviation';
import { getDeviations } from './deviationsApi';
import { CountryCode, CountryResponse } from '../authentication/loginData/metadata';

export const deviationsPageSize = 50;
export const deviationPageSizeStorage = 'deviation-pagesize';

export type DeviationsBaseProps = {
  deviationFilterDropdownOptions: DeviationFilterDropdownOptionsResponse;
  itemFieldsFromPms: ItemFieldsFromPmsResponse;
  practiceGroups: Array<PracticeGroupResponse>;
};

export const initialDeviationFilterOptions: DeviationFilterOptions = {
  itemIds: null,
  siteIds: null,
  practiceGroupIds: null,
  isHidden: null,
  category1Ids: null,
  category2Ids: null,
  category3Ids: null,
  clientCategoryIds: null,
  centralPrice: null,
  dispenseFee: null,
  supplierProductCodeSearchString: null,
  supplierIds: null,
  priceDevaitionSourceId: DevationSource.All,
  dispenseFeeDevaitionSourceId: DevationSource.All,
  itemName: null,
  overwrite: null,
  itemTypeId: null,
  isRecentlyAdded: null,
};

export const NameAndIdToDropdown = (namesAndIds: Array<NameAndId>): Array<DropdownOption<Id>> => {
  return namesAndIds.map(i => {
    return { displayText: i.name, value: i.id };
  });
};

export const urlToFilter = (itemFieldsFromPms: ItemFieldsFromPmsResponse) => (
  urlState?: Partial<DeviationFilterOptions>,
): DeviationFilterOptions => {
  if (urlState === undefined) {
    return initialDeviationFilterOptions;
  }
  return {
    siteIds: urlState.siteIds ?? null,
    itemIds: urlState.itemIds ?? null,
    practiceGroupIds: urlState.practiceGroupIds ?? null,
    isHidden: urlState.isHidden ?? null,
    category1Ids: urlState.category1Ids
      ? filter(urlState.category1Ids, id => itemFieldsFromPms.category1Options[id] != null)
      : null,
    category2Ids: urlState.category2Ids
      ? filter(urlState.category2Ids, id => itemFieldsFromPms.category2Options[id] != null)
      : null,
    category3Ids: urlState.category3Ids
      ? filter(urlState.category3Ids, id => itemFieldsFromPms.category3Options[id] != null)
      : null,
    clientCategoryIds: urlState.clientCategoryIds
      ? filter(
          urlState.clientCategoryIds,
          id => itemFieldsFromPms.clientCategoryOptions[id] != null,
        )
      : null,
    centralPrice: urlState.centralPrice ?? null,
    dispenseFee: urlState.dispenseFee ?? null,
    supplierProductCodeSearchString: urlState.supplierProductCodeSearchString ?? null,
    supplierIds: urlState.supplierIds ?? null,
    priceDevaitionSourceId: urlState.priceDevaitionSourceId ?? DevationSource.All,
    dispenseFeeDevaitionSourceId: urlState.dispenseFeeDevaitionSourceId ?? DevationSource.All,
    itemName: urlState.itemName ?? null,
    overwrite: urlState.overwrite ?? null,
    itemTypeId: urlState.itemTypeId ?? null,
    isRecentlyAdded: urlState.isRecentlyAdded ?? null,
  };
};

export const filterToUrl = (
  filterOptions?: DeviationFilterOptions,
): Partial<DeviationFilterOptions> => {
  if (filterOptions === undefined) {
    return {};
  }
  return {
    itemIds: filterOptions.itemIds ?? undefined,
    siteIds: filterOptions.siteIds ?? undefined,
    practiceGroupIds: filterOptions.practiceGroupIds ?? undefined,
    isHidden: filterOptions.isHidden ?? undefined,
    category1Ids: filterOptions.category1Ids ?? undefined,
    category2Ids: filterOptions.category2Ids ?? undefined,
    category3Ids: filterOptions.category3Ids ?? undefined,
    clientCategoryIds: filterOptions.clientCategoryIds ?? undefined,
    centralPrice: filterOptions.centralPrice ?? undefined,
    dispenseFee: filterOptions.dispenseFee ?? undefined,
    supplierProductCodeSearchString: filterOptions.supplierProductCodeSearchString ?? undefined,
    supplierIds: filterOptions.supplierIds ?? undefined,
    priceDevaitionSourceId: filterOptions.priceDevaitionSourceId ?? null,
    dispenseFeeDevaitionSourceId: filterOptions.dispenseFeeDevaitionSourceId ?? null,
    itemName: filterOptions.itemName ?? null,
    overwrite: filterOptions.overwrite ?? null,
    itemTypeId: filterOptions.itemTypeId ?? null,
    isRecentlyAdded: filterOptions.isRecentlyAdded ?? null,
  };
};
export type DeviationColumnDropdownType = DropdownOption<DeviationColumnTypes>;
export type UseDeviationTableBaseStateResult = {
  columnDropdownOptions: DropdownOptions<DeviationColumnTypes>;
} & UseFilteringResult<
  DeviationFilterOptions,
  AuthenticatedApiRequest<DeviationsResponse, TableDataFetchRequest<DeviationColumnTypes>>
> &
  UseMultiSelectDropdownResult<DeviationColumnDropdownType, DeviationColumnTypes>;

export const useDeviationTableBaseState = (
  props: DeviationsBaseProps,
): UseDeviationTableBaseStateResult => {
  const { t } = useTranslation(['deviations', 'libraries']);
  const { user } = useContext(UserContext);

  const columnDropdownOptions: DropdownOptions<DeviationColumnTypes> = useMemo(
    () => [
      { displayText: t('columnHeaders.itemCode'), value: 'itemCode' },
      {
        displayText: t('columnHeaders.supplierProductCode'),
        value: 'supplierProductCode',
      },
      { displayText: t('columnHeaders.supplierName'), value: 'supplierName' },
    ],
    [user],
  );

  const filteringResult = useFiltering({
    getData: getDeviations,
    initialUrlState: filterToUrl(initialDeviationFilterOptions),
    urlStateToFilterOptions: urlToFilter(props.itemFieldsFromPms),
    filterOptionsToUrlState: filterToUrl,
    pageName: 'deviations',
  });

  const multiSelectDropdownResult = useMultiSelectDropdown<
    DeviationColumnDropdownType,
    DeviationColumnTypes
  >({
    validOptions: columnDropdownOptions,
    initiallySelected: new Array<DeviationColumnDropdownType>(),
    storeInUrl: true,
  });

  return { ...filteringResult, ...multiSelectDropdownResult, columnDropdownOptions };
};

export const getSubRows = (
  groupedDeviationRow: GroupedDeviationResponseRow,
): Array<GroupedDeviationResponseRow> => groupedDeviationRow.deviations ?? [];

export const renderDeviationToggleButton = (
  toggleProps: MultiSelectDropdownProps<DeviationColumnDropdownType>,
  downshift: ControllerStateAndHelpers<DeviationColumnDropdownType>,
) => (
  <HollowButton {...downshift.getToggleButtonProps()}>
    <ButtonLabel {...downshift.getLabelProps()}>{toggleProps.label}</ButtonLabel>
  </HollowButton>
);

export const isItemSpecificPriceDeviation = (deviation: DeviationResponse): boolean =>
  deviation.priceDeviationPercentValue !== deviation.defaultPriceDeviationPercentValue;

export const isItemSpecificDispenseFeeDeviation = (deviation: DeviationResponse): boolean =>
  deviation.dispenseFeeDeviationPercentValue !== deviation.defaultDispenseFeeDeviationPercentValue;

/**
 * Jira pp-941
 * Price or dispense fee update will auto check code specific
 * @param siteId
 * @param itemId
 * @param centralItemPrice
 * @param user
 * @param itemType
 * @returns
 */
export const onPercentagePriceDeviationChange = (
  siteId: number,
  itemId: number,
  centralItemPrice: number,
  user: LoginUserResponse,
  itemType: string,
) => (value: string, form: FormikProps<unknown>) => {
  const isStock = itemType === 'Stock';
  form.setFieldValue(`deviations.${siteId}.${itemId}.absolutePriceDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.deviatedPrice`,
    getDeviatedValue(centralItemPrice, parseNumber(value, user.locale), null, true),
  );
  form.setFieldValue(`deviations.${siteId}.${itemId}.itemDeviationPriceOverride`, isStock);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.percentagePriceDeviation`, true);
};

export const onAbsolutePriceDeviationChange = (
  siteId: number,
  itemId: number,
  centralItemPrice: number,
  user: LoginUserResponse,
) => (value: string, form: FormikProps<unknown>) => {
  form.setFieldValue(`deviations.${siteId}.${itemId}.percentagePriceDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.deviatedPrice`,
    getDeviatedValue(centralItemPrice, null, parseNumber(value, user.locale), false),
  );
};

/**
 * Jira pp-941
 * Price or dispense fee update will auto check code specific
 *
 * @param siteId
 * @param itemId
 * @param centralItemPrice
 * @param user
 * @param itemType
 * @returns
 */
export const onDeviatedPriceChange = (
  siteId: number,
  itemId: number,
  centralItemPrice: number,
  user: LoginUserResponse,
  itemType: string,
) => (value: string, form: FormikProps<unknown>) => {
  const isStock = itemType === 'Stock';

  form.setFieldValue(`deviations.${siteId}.${itemId}.percentagePriceDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.absolutePriceDeviation`,
    calculateAbsoluteValueFromDeviatedValueChange(
      parseNumber(value, user.locale),
      centralItemPrice,
    ),
  );

  form.setFieldValue(`deviations.${siteId}.${itemId}.itemDeviationPriceOverride`, isStock);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.absolutePriceDeviation`);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.percentagePriceDeviation`, false);
};

/**
 * Jira pp-941
 * Price or dispense fee update will auto check code specific
 *
 * @param siteId
 * @param itemId
 * @param centralItemDispenseFee
 * @param user
 * @returns
 */
export const onPercentageDispenseFeeDeviationChange = (
  siteId: number,
  itemId: number,
  centralItemDispenseFee: number,
  user: LoginUserResponse,
) => (value: string, form: FormikProps<unknown>) => {
  form.setFieldValue(`deviations.${siteId}.${itemId}.absoluteDispenseFeeDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.deviatedDispenseFee`,
    getDeviatedValue(centralItemDispenseFee, parseNumber(value, user.locale), null, true),
  );
  form.setFieldValue(`deviations.${siteId}.${itemId}.itemDeviationDispenseFeeOverride`, true);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.percentageDispenseFeeDeviation`, true);
};

export const onAbsoluteDispenseFeeDeviationChange = (
  siteId: number,
  itemId: number,
  centralItemDispenseFee: number,
  user: LoginUserResponse,
) => (value: string, form: FormikProps<unknown>) => {
  form.setFieldValue(`deviations.${siteId}.${itemId}.percentageDispenseFeeDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.deviatedDispenseFee`,
    getDeviatedValue(centralItemDispenseFee, null, parseNumber(value, user.locale), false),
  );
};

/**
 * Jira pp-941
 * Price or dispense fee update will auto check code specific
 *
 * @param siteId
 * @param itemId
 * @param centralItemDispenseFee
 * @param user
 * @returns
 */
export const onDeviatedDispenseFeeChange = (
  siteId: number,
  itemId: number,
  centralItemDispenseFee: number,
  user: LoginUserResponse,
) => (value: string, form: FormikProps<unknown>) => {
  form.setFieldValue(`deviations.${siteId}.${itemId}.percentageDispenseFeeDeviation`, null);
  form.setFieldValue(
    `deviations.${siteId}.${itemId}.absoluteDispenseFeeDeviation`,
    calculateAbsoluteValueFromDeviatedValueChange(
      parseNumber(value, user.locale),
      centralItemDispenseFee,
    ),
  );

  form.setFieldValue(`deviations.${siteId}.${itemId}.itemDeviationDispenseFeeOverride`, true);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.absoluteDispenseFeeDeviation`);
  form.setFieldTouched(`deviations.${siteId}.${itemId}.percentageDispenseFeeDeviation`, false);
};

const calculateAbsoluteValueFromDeviatedValueChange = (
  deviatedValue: number,
  recommendedValue: number,
) => round(deviatedValue - recommendedValue, 4);

type DeviationsScheduledForUpdateBarProps = {
  message: string;
};
export const DeviationsScheduledForUpdateInfoBar = ({
  message,
}: DeviationsScheduledForUpdateBarProps) => (
  <InfoBox
    message={
      <>
        <MarginRightIcon icon={faClock} />
        {message}
      </>
    }
  />
);

const IconWithRightMargin = styled(FontAwesomeIcon)`
  margin-right: ${styleProps => styleProps.theme.spacing.extraSmall}px;
`;

const StyledInfoText = styled.div`
  color: ${styleProps => styleProps.theme.colours.infoText};
  font-style: italic;
  font-size: ${styleProps => styleProps.theme.typography.subtext.fontSize}px;
  margin-bottom: ${styleProps => styleProps.theme.spacing.small}px;
`;

type HelpTextProps = { text: string };
export const HelpText = ({ text }: HelpTextProps) => (
  <StyledInfoText>
    <span>
      <IconWithRightMargin icon={faInfoCircle} />
      {text}
    </span>
  </StyledInfoText>
);

export type DeviationValues = {
  absolutePriceDeviation: string | null;
  percentagePriceDeviation: string | null;
  absoluteDispenseFeeDeviation: string | null;
  percentageDispenseFeeDeviation: string | null;
};

export const getFormikValuesForDeviationBulkEdit = (
  formikProps?: FormikProps<unknown>,
): CreateDeviationBulkEditFormModel | null => {
  return formikProps && formikProps.values
    ? (formikProps.values as CreateDeviationBulkEditFormModel)
    : null;
};

export const getFilteredPracticeGroupsById = (
  filterOptions: DeviationFilterOptions | undefined,
  practiceGroups: Array<PracticeGroupResponse>,
): Dictionary<PracticeGroupResponse> => {
  const filteredPracticeGroups = !!filterOptions?.practiceGroupIds?.length
    ? practiceGroups.filter(pg => filterOptions?.practiceGroupIds?.includes(pg.practiceGroupId))
    : practiceGroups;

  return keyBy(filteredPracticeGroups, practiceGroup => practiceGroup.practiceGroupId);
};

export const rowHasOverwritePrice = <T extends DeviationTableRow>(row: Row<T>): boolean => {
  return row.original.overwrite;
};

export const rowHasZeroPricePrice = <T extends DeviationTableRow>(row: Row<T>): boolean => {
  return row.original.centralItemPriceGross === 0 || row.original.centralItemPriceNet === 0;
};
export const rowHasZeroPriceDispenseFee = <T extends DeviationTableRow>(row: Row<T>): boolean => {
  return (
    row.original.centralItemDispenseFeeNet === 0 || row.original.centralItemDispenseFeeGross === 0
  );
};

export const rowIsDiscount = <T extends DeviationTableRow>(
  row: Row<T>,
  discountCategoryId: number,
): boolean => {
  return discountCategoryId == row.original.category1Id;
};

export const practiceGroupHasSubRowWithOverwritePrice = <T extends DeviationTableRow>(
  headerRow: Row<T>,
): boolean => {
  return headerRow.subRows.some(subRow => rowHasOverwritePrice(subRow));
};

export const practiceGroupHasSubRowWithZeroPrice = <T extends DeviationTableRow>(
  headerRow: Row<T>,
): boolean => {
  return headerRow.subRows.some(subRow => rowHasZeroPricePrice(subRow));
};

export const practiceGroupHasSubRowWithZeroDispenseFee = <T extends DeviationTableRow>(
  headerRow: Row<T>,
): boolean => {
  return headerRow.subRows.some(subRow => rowHasZeroPriceDispenseFee(subRow));
};

export const practiceGroupHasSubRowWithDiscount = <T extends DeviationTableRow>(
  headerRow: Row<T>,
  discountCategoryId: number,
): boolean => {
  return headerRow.subRows.some(subRow => rowIsDiscount(subRow, discountCategoryId));
};

export const deviationItemIsServiceType = <T extends DeviationTableRow>(deviation: T): boolean =>
  deviation.itemType === 'Service';

export const rowItemIsServiceType = <T extends DeviationTableRow>(row: Row<T>): boolean =>
  deviationItemIsServiceType(row.original);

export const practiceGroupHasServiceTypeSubRow = <T extends DeviationTableRow>(
  headerRow: Row<T>,
): boolean => headerRow.subRows.some(subRow => rowItemIsServiceType(subRow));

export const getCurrencyForCountry = (
  countryCode: CountryCode | null,
  user: LoginUserResponse,
  countries: Array<CountryResponse>,
): string => countries.find(c => c.code === countryCode)?.currency ?? user.currency;
